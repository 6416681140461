import styles from './PartnerDetailInfo.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import heart from '../../assets/partnerList/heart.png';
import heartblack from '../../assets/partnerList/heartblack.png';
import share from '../../assets/partnerList/share.png';
import copy from '../../assets/partnerDetail/copy.png';
import { useEffect, useState } from 'react';
import { ICompanyDetailType } from '../../pages/PartnerDetail/PartnerDetail';
import { loginConfirm } from '../../common/loginConfirm';
import axiosClient from '../../libs/axiosClient';
import { useNavigate, useParams } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import CopyAlertModal from '../CopyAlertModal/CopyAlertModal';

function PartnerDetailInfo(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const [userType, setUserType] = useState('');
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [companyDetail, setCompanyDetail] = useState<ICompanyDetailType>();

  useEffect(() => {
    setCompanyDetail(props.companyDetail);
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  const needLogin = () => {
    return loginConfirm();
  };

  const getCompany = async () => {
    await axiosClient
      .get(`/member-manufacture/${params.partnerId}`, {
        headers: {
          'Access-Control-Allow-Origin': 'http://43.201.253.201/api',
        },
      })
      .then((res) => {
        setCompanyDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCopy = () => {
    setCopyModalOpen((prev) => !prev);
    setTimeout(() => {
      setCopyModalOpen((prev) => !prev);
    }, 1000);
  };

  const handleWish = () => {
    if (!companyDetail) return;
    if (!needLogin()) return;
    let bookmark = 0;
    if (companyDetail.is_bookmark === 0) {
      bookmark = 1;
      setCompanyDetail((prev) => ({ ...prev!, is_bookmark: 1 }));
    } else {
      bookmark = 0;
      setCompanyDetail((prev) => ({ ...prev!, is_bookmark: 0 }));
    }

    let formData = new FormData();
    formData.append('member_manufacture_id', companyDetail.id);
    formData.append('bookmark', bookmark.toString());
    axiosClient
      .post(`/bookmark`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        getCompany();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChat = (list: any) => {
    if (!needLogin()) return;
    axiosClient
      .post(
        '/chat/room/create',
        {
          login_id: list.login_id,
          channel_id: '',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        const chat = res.data.channel;
        navigate(`/chat?chat-group=${chat.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={`${styles.rightWrap} ${isDesktop ? '' : styles.isMobile}`}
      >
        <div className={styles.wishWrap}>
          <img src={heartblack} alt="heart" />
          <span>{companyDetail?.total_bookmark.toLocaleString()}</span>
        </div>
        <div className={styles.titleWrap}>
          <p>{companyDetail?.name}</p>
          <div className={styles.imgBtns}>
            <CopyToClipboard text={window.location.href} onCopy={handleCopy}>
              <img src={share} alt="share" />
            </CopyToClipboard>
            <div
              className={`${styles.partnerWishWrap} ${
                companyDetail?.is_bookmark === 1
                  ? styles.heart
                  : styles.blackheart
              }`}
            >
              <img
                src={companyDetail?.is_bookmark === 1 ? heart : heartblack}
                alt="heart"
                onClick={handleWish}
              />
            </div>
          </div>
        </div>
        <div className={styles.companyInfoWrap}>
          <div>
            <div className={styles.infoTitle}>
              <span>대표자</span>
            </div>
            <span className={styles.infoContent}>
              {companyDetail?.ceo_name}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>설립연도</span>
            </div>
            <span className={styles.infoContent}>
              {companyDetail?.since_date &&
                companyDetail?.since_date.slice(0, 4) +
                  '년 ' +
                  companyDetail?.since_date.slice(5, 7) +
                  '월'}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>직원인원</span>
            </div>
            <span className={styles.infoContent}>
              {companyDetail?.member_quantity
                ? companyDetail?.member_quantity + '명'
                : ''}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>관련업종</span>
            </div>
            <span className={styles.infoContent}>
              {companyDetail?.category_name}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>주요거래처</span>
            </div>
            <span className={styles.infoContent}>
              {companyDetail?.business_partner}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>회사주소</span>
            </div>
            <span className={styles.infoContent}>{companyDetail?.addr}</span>
            {companyDetail?.addr && (
              <CopyToClipboard
                text={companyDetail?.addr || ''}
                onCopy={handleCopy}
              >
                <span>
                  <img src={copy} alt="copy" />
                  <span className={styles.copyText}></span>
                </span>
              </CopyToClipboard>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>홈페이지</span>
            </div>
            <span className={styles.infoContent}>
              {companyDetail?.homepage}
            </span>
            {companyDetail?.homepage && (
              <CopyToClipboard
                text={companyDetail?.homepage || ''}
                onCopy={handleCopy}
              >
                <span>
                  <img src={copy} alt="copy" />
                  <span className={styles.copyText}></span>
                </span>
              </CopyToClipboard>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>대표전화</span>
            </div>
            <span className={styles.infoContent}>{companyDetail?.hp}</span>
            {companyDetail?.hp && (
              <CopyToClipboard
                text={companyDetail?.hp || ''}
                onCopy={handleCopy}
              >
                <span>
                  <img src={copy} alt="copy" />
                  <span className={styles.copyText}></span>
                </span>
              </CopyToClipboard>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>팩스</span>
            </div>
            <span className={styles.infoContent}>{companyDetail?.fax}</span>
            {companyDetail?.fax && (
              <CopyToClipboard
                text={companyDetail?.fax || ''}
                onCopy={handleCopy}
              >
                <span>
                  <img src={copy} alt="copy" />
                  <span className={styles.copyText}></span>
                </span>
              </CopyToClipboard>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>담당자</span>
            </div>
            <div>
              <p className={styles.infoContent}>
                {companyDetail?.manager_name}
              </p>
              <span className={styles.infoContent}>
                {companyDetail?.manager_hp}
              </span>
              {companyDetail?.manager_hp && (
                <CopyToClipboard
                  text={companyDetail?.manager_hp || ''}
                  onCopy={handleCopy}
                >
                  <span>
                    <img src={copy} alt="copy" />
                    <span className={styles.copyText}></span>
                  </span>
                </CopyToClipboard>
              )}
            </div>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>담당자 이메일</span>
            </div>
            <span className={styles.infoContent}>{companyDetail?.email}</span>
            {companyDetail?.email && (
              <CopyToClipboard
                text={companyDetail?.email || ''}
                onCopy={handleCopy}
              >
                <span>
                  <img src={copy} alt="copy" />
                  <span className={styles.copyText}></span>
                </span>
              </CopyToClipboard>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>메모</span>
            </div>
            <span className={styles.infoContent}>{companyDetail?.memo}</span>
          </div>
        </div>
        {userType !== 'member_manufacture' && companyDetail?.login_id && (
          <div className={styles.companyBtns}>
            <div
              className={styles.requestBtn}
              onClick={() => {
                handleChat(companyDetail);
              }}
            >
              <span>1:1 문의하기</span>
            </div>
          </div>
        )}
      </div>
      {copyModalOpen && <CopyAlertModal />}
    </>
  );
}

export default PartnerDetailInfo;
