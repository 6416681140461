import styles from './Alert.module.scss';
import cancel from '../../assets/notice/cancel.png';
import company from '../../assets/partnerDetail/company.png';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import { QueryClient, useInfiniteQuery } from 'react-query';
import axiosClient from '../../libs/axiosClient';
import profile from '../../assets/partnerList/profile.png';
import { isDesktop, isMobile } from 'react-device-detect';
import { loginConfirm } from '../../common/loginConfirm';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  alertRef: any;
}

interface IQuoteTypes {
  cateogry: string;
  chat_id: string;
  created_at: string;
  estimate_request_id: string;
  id: number;
  img: string;
  is_read: number;
  member_client_id: string;
  message: string;
  name: string;
  profile_img: string;
  title: string;
}

function Notice({ closer, alertRef }: IPropsType) {
  let lastDateGroup: string | null = null;
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const navigate = useNavigate();
  const [tabMenu, setTabMenu] = useState(0);
  const [userType, setUserType] = useState('');
  const [quoteAlertList, setQuoteAlertList] = useState<IQuoteTypes[]>([]);
  const [alertCount, setAlertCount] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!isDesktop) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (!window.visualViewport) return;
    setWidth(window.visualViewport?.width);
  }, []);

  useEffect(() => {
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  useEffect(() => {
    if (userType === 'member_manufacture') {
      axiosClient
        .get('/member-manufacture-notifications', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          const count = res.data.filter(
            (item: { is_read: number }) => item.is_read === 0,
          ).length;
          setAlertCount(count);
          setQuoteAlertList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (userType === 'member_client') {
      axiosClient
        .get('/member-client-notifications', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          setQuoteAlertList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userType]);

  const formatTime = () => {};

  // `/data/referrer?offset=${(pagination - 1) * 5}&limit=5`
  // const getNoticeList = async (page: number) => {
  //   const API_URL = `https://api.github.com/search/repositories?q=topic:reactjs&per_page=10&page=${page}`;
  //   return await axios.get(API_URL).then((res) => res.data);
  // };

  // hasNextPage: 다음페이지 여부
  // fetchNextPage : 다음 페이지 요청
  // isFetchingNextPage : 다음 페이지를 불러오는 중인지 판별하는 boolean 값
  // const {
  //   data,
  //   isLoading,
  //   isError,
  //   hasNextPage,
  //   fetchNextPage,
  //   isFetchingNextPage,
  // } = useInfiniteQuery(
  //   ['noticelist'],
  //   async ({ pageParam = 1 }) => {
  //     return await getNoticeList(pageParam);
  //   },
  //   {
  //     // noticeList : fetch callback return 값 전달
  //     // newNoticeList : 배열안에 지금까지 불러온 데이터 계속 축정하는 형태
  //     // [[data], [data1], .......]
  //     getNextPageParam: (lastPage, allPages) => {
  //       // 한 번에 n개씩 보여주기
  //       const maxPage = lastPage.total_count / 10;
  //       const nextPage = allPages.length + 1;
  //       // 다음 데이터가 있는지 없는지
  //       return nextPage <= maxPage ? nextPage : undefined;
  //     },
  //   },
  // );

  // ref: target을 지정할 element에 지정
  // inView type은 boolean으로 root(뷰포트)에 target(ref를 지정한 element)이 들어오면 true로 변환됨
  // const { ref, inView } = useInView({
  //   // threshold : 0~1, viewport에 target element가 얼마나 보였을때 노출되었다고 판단할 것인지 (1에 가까울수록 100% 노출되었다고 판단)
  //   threshold: 0,
  // });

  // useEffect(() => {
  //   if (inView && hasNextPage) fetchNextPage();
  // }, [inView]);

  const needLogin = () => {
    return loginConfirm();
  };

  const handleChat = (list: any) => {
    if (!needLogin()) return;
    axiosClient
      .post(
        '/chat/room/create',
        {
          login_id: list.login_id,
          channel_id: '',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        // console.log(res.data.channel);
        const chat = res.data.channel;
        // navigate(`/chat?chat-group=${chat.id}&chat-number=${list.id}`);
        navigate(`/chat?chat-group=${chat.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      ref={alertRef}
      style={{
        minWidth: isDesktop ? '1280px' : width + 'px',
        maxWidth: isDesktop ? '1280px' : width + 'px',
      }}
      className={`${styles.container} ${isDesktop ? '' : styles.isMobile}`}
    >
      <div className={styles.noticeContainer}>
        <div
          className={`${styles.noticeWrap} ${
            tabMenu === 1 && userType === 'member_manufacture'
              ? styles.companyTab1Wrap
              : tabMenu === 0 && userType === 'member_manufacture'
              ? styles.companyTab2Wrap
              : ''
          }`}
        >
          {userType === 'member_manufacture' && isMobile && (
            <div className={styles.cancelWrap}>
              <span>알림</span>
              <img onClick={() => closer(false)} src={cancel} alt="cancel" />
            </div>
          )}
          {userType === 'member_manufacture' && (
            <div className={styles.tabMenuWrap}>
              <div
                className={`${styles.tabFrame} ${
                  tabMenu === 0 ? styles.hit : ''
                }`}
                onClick={() => setTabMenu(0)}
              >
                <span>견적 알림</span>
                {alertCount !== 0 && <div>{alertCount}</div>}
              </div>
              <div
                className={`${styles.tabFrame} ${
                  tabMenu === 1 ? styles.hit : ''
                }`}
                onClick={() => setTabMenu(1)}
              >
                <span>채팅 알림</span>
              </div>
            </div>
          )}
          {userType === 'member_client' ? (
            // <div
            //   className={`${styles.noticeListWrap} ${
            //     userType === 'member_client' ? styles.noNoticeListWrap : ''
            //   }`}
            // >
            //   준비 중입니다.
            // </div>
            <>
              <div className={styles.topWrap}>
                <div className={styles.topLeft}>
                  <div className={styles.noticePrescription}>
                    <span className={styles.alertText}>알림</span>
                    <span>
                      <span>최대 </span>
                      <span>30일까지만 보관</span>
                      <span> 이후 자동삭제됩니다.</span>
                    </span>
                    <img
                      onClick={() => closer(false)}
                      src={cancel}
                      alt="cancel"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${styles.noticeListClientWrap} ${
                  tabMenu === 0 && quoteAlertList.length > 0
                    ? ''
                    : styles.noChatWrap
                }`}
              >
                <>
                  {quoteAlertList &&
                    quoteAlertList.map((item, idx) => {
                      const noticeDate = new Date(item.created_at);
                      let hours = noticeDate.getHours();
                      const minutes = noticeDate.getMinutes();
                      const formattedTime =
                        hours < 12
                          ? `오전 ${hours === 0 ? 12 : hours}:${minutes}`
                          : `오후 ${
                              hours === 12 ? hours : hours - 12
                            }:${minutes}`;
                      let dateLabel = '';
                      if (
                        noticeDate.getFullYear() === today.getFullYear() &&
                        noticeDate.getMonth() === today.getMonth() &&
                        noticeDate.getDate() === today.getDate()
                      ) {
                        const dateGroup = `오늘`;
                        if (dateGroup !== lastDateGroup) {
                          dateLabel = `오늘`;
                          lastDateGroup = dateGroup;
                        }
                      } else if (
                        noticeDate.getFullYear() === yesterday.getFullYear() &&
                        noticeDate.getMonth() === yesterday.getMonth() &&
                        noticeDate.getDate() === yesterday.getDate()
                      ) {
                        const dateGroup = `어제`;
                        if (dateGroup !== lastDateGroup) {
                          dateLabel = `어제`;
                          lastDateGroup = dateGroup;
                        }
                      } else {
                        const dateGroup = `${noticeDate.getFullYear()}-${(
                          noticeDate.getMonth() + 1
                        )
                          .toString()
                          .padStart(2, '0')}-${noticeDate
                          .getDate()
                          .toString()
                          .padStart(2, '0')}`;
                        if (dateGroup !== lastDateGroup) {
                          dateLabel = `${
                            noticeDate.getMonth() + 1
                          }월 ${noticeDate.getDate()}일`;
                          lastDateGroup = dateGroup;
                        }
                      }
                      return (
                        <div
                          style={{ marginTop: dateLabel ? '' : '-24px' }}
                          key={idx}
                          className={styles.noticeList}
                        >
                          <div className={styles.dateWrap}>
                            <p>{dateLabel}</p>
                          </div>
                          <div
                            onClick={() => {
                              closer(false);
                              axiosClient
                                .post(
                                  `/member-client-notification/read`,
                                  {
                                    member_client_notification_id: item.id,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${localStorage.getItem(
                                        'token',
                                      )}`,
                                    },
                                  },
                                )
                                .then((res) => {})
                                .catch((err) => {
                                  console.log(err);
                                });
                              handleChat(item);
                            }}
                            className={styles.noticeFrame}
                          >
                            {item.is_read === 0 && (
                              <div className={styles.isView}></div>
                            )}
                            <img
                              src={
                                item.profile_img ? item.profile_img : profile
                              }
                              alt="profile"
                            />
                            <div className={styles.contentWrap}>
                              <div>
                                {/* <span>{item.name} </span>
                                <span>님에게 견적 요청을 받았습니다.</span> */}
                                <span>{item.message.split('님')[0]}</span>
                                <span>
                                  {'님' + item.message.split('님')[1]} 지금 바로
                                  채팅창을 확인해 주세요.
                                </span>
                              </div>
                              <span>{formattedTime}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {!quoteAlertList.length && (
                    <div className={styles.noListWrap}>
                      알림 내역이 없습니다.
                    </div>
                  )}
                </>
              </div>
            </>
          ) : (
            <>
              {tabMenu === 0 && quoteAlertList.length !== 0 && (
                <div className={styles.topWrap}>
                  <div className={styles.topLeft}>
                    <div className={styles.noticePrescription}>
                      <span>최대 </span> <span> 30일까지만 보관 </span>
                      <span> 이후 자동삭제됩니다.</span>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`${styles.noticeListWrap} ${
                  tabMenu === 0 && quoteAlertList.length > 0
                    ? ''
                    : styles.noChatWrap
                }`}
              >
                {tabMenu === 0 ? (
                  <>
                    {quoteAlertList &&
                      quoteAlertList.map((item, idx) => {
                        const noticeDate = new Date(item.created_at);
                        let hours = noticeDate.getHours();
                        const minutes = noticeDate.getMinutes();
                        const formattedTime =
                          hours < 12
                            ? `오전 ${hours === 0 ? 12 : hours}:${minutes}`
                            : `오후 ${
                                hours === 12 ? hours : hours - 12
                              }:${minutes}`;
                        let dateLabel = '';
                        if (
                          noticeDate.getFullYear() === today.getFullYear() &&
                          noticeDate.getMonth() === today.getMonth() &&
                          noticeDate.getDate() === today.getDate()
                        ) {
                          const dateGroup = `오늘`;
                          if (dateGroup !== lastDateGroup) {
                            dateLabel = `오늘`;
                            lastDateGroup = dateGroup;
                          }
                        } else if (
                          noticeDate.getFullYear() ===
                            yesterday.getFullYear() &&
                          noticeDate.getMonth() === yesterday.getMonth() &&
                          noticeDate.getDate() === yesterday.getDate()
                        ) {
                          const dateGroup = `어제`;
                          if (dateGroup !== lastDateGroup) {
                            dateLabel = `어제`;
                            lastDateGroup = dateGroup;
                          }
                        } else {
                          const dateGroup = `${noticeDate.getFullYear()}-${(
                            noticeDate.getMonth() + 1
                          )
                            .toString()
                            .padStart(2, '0')}-${noticeDate
                            .getDate()
                            .toString()
                            .padStart(2, '0')}`;
                          if (dateGroup !== lastDateGroup) {
                            dateLabel = `${
                              noticeDate.getMonth() + 1
                            }월 ${noticeDate.getDate()}일`;
                            lastDateGroup = dateGroup;
                          }
                        }
                        return (
                          <div
                            style={{ marginTop: dateLabel ? '' : '-24px' }}
                            key={idx}
                            className={styles.noticeList}
                          >
                            <div className={styles.dateWrap}>
                              <p>{dateLabel}</p>
                            </div>
                            <div
                              onClick={() => {
                                closer(false);
                                navigate(`/quote/${item.estimate_request_id}`);
                                axiosClient
                                  .post(
                                    `/member-manufacture-notification/read`,
                                    {
                                      member_manufacture_notification_id:
                                        item.id,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${localStorage.getItem(
                                          'token',
                                        )}`,
                                      },
                                    },
                                  )
                                  .then((res) => {})
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                              className={styles.noticeFrame}
                            >
                              {item.is_read === 0 && (
                                <div className={styles.isView}></div>
                              )}
                              <img
                                src={
                                  item.profile_img ? item.profile_img : profile
                                }
                                alt="profile"
                              />
                              <div className={styles.contentWrap}>
                                <div>
                                  <span>{item.name} </span>
                                  <span>
                                    {/* {(item.send === '견적' || item.send === '샘플') &&
                                `님이 ${item.send}을 발송했습니다. 지금 바로 채팅창을 확인해주세요.`}
                              {item.send === '결제' &&
                                '님이 결제를 요청했습니다.'} */}
                                    님에게 견적 요청을 받았습니다.
                                  </span>
                                </div>
                                <span>{formattedTime}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {!quoteAlertList.length && (
                      <div className={styles.noListWrap}>
                        알림 내역이 없습니다.
                      </div>
                    )}
                    {/* {data?.pages.map((data, idx) => (
                <div key={idx}>
                  {data.items.map((proejct: any) => (
                    <div
                      key={proejct.id}
                      style={{ marginTop: '24px' }}
                      className={styles.noticeList}
                    >
                      <div className={styles.dateWrap}>
                        <p>{proejct.name}</p>
                      </div>
                      <div
                        onClick={() => navigate('/chat')}
                        className={styles.noticeFrame}
                      >
                        <div className={styles.isView}></div>
                        <img src={company} alt="profile" />
                        <div className={styles.contentWrap}>
                          <div>
                            <span>{proejct.name} </span>
                            <span>
                              님이 견적을 발송했습니다. 지금 바로 채팅창을
                              확인해주세요.
                            </span>
                          </div>
                          <span>formattedTime</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))} */}
                    {/* {!noticeList && (
                <div className={styles.noNoticeList}>알림이 없습니다.</div>
              )}
              {isLoading && <>로딩중</>}
              {isError && <>err</>}
              {isFetchingNextPage ? (
                <div className={styles.noticeListLoading}>. . .</div>
              ) : (
                <div ref={ref}></div>
              )} */}

                    {/* <div>
              <button
                ref={ref}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
              >
                {isFetchingNextPage ? 'loading' : '-'}
              </button>
            </div> */}
                  </>
                ) : (
                  <>채팅 기능이 준비 중입니다.</>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notice;
