import styles from './QuoteDetail.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import profile from '../../assets/partnerList/profile.png';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { useNavigate, useParams } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { loginConfirm } from '../../common/loginConfirm';
interface IQuoteDetailTypes {
  name: string;
  member_client_id: string;
  deadline: string;
  budget: string;
  request: string;
  created_at: string;
  manager_name: string;
  manager_hp: string;
  member_client_name: string;
  member_client_login_id: string;
}
function QuoteDetail() {
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const [quoteDetail, setQuoteDetail] = useState<IQuoteDetailTypes>();

  useEffect(() => {
    axiosClient
      .get(`/estimate/request/${quoteId}`)
      .then((res) => {
        console.log(res.data);
        setQuoteDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [quoteId]);

  const needLogin = () => {
    return loginConfirm();
  };

  const handleChat = (list: any) => {
    if (!needLogin()) return;
    axiosClient
      .post(
        '/chat/room/create',
        {
          login_id: list.member_client_login_id,
          channel_id: '',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        const chat = res.data.channel;
        navigate(`/chat?chat-group=${chat.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <p>견적정보</p>
        <div className={styles.quoteWrap}>
          <div className={styles.quoteFrame}>
            <p>프로젝트 정보</p>
            <div className={styles.quoteListWrap}>
              <div>
                <span>프로젝트 이름</span>
                <span>{quoteDetail?.name}</span>
              </div>
              <div>
                <span>희망생산일</span>
                <span>
                  {quoteDetail?.deadline &&
                    new Date(quoteDetail?.deadline).getFullYear() +
                      '년 ' +
                      (new Date(quoteDetail?.deadline).getMonth() + 1) +
                      '월'}
                </span>
              </div>
              <div>
                <span>예산</span>
                <span>{quoteDetail?.budget.toLocaleString()}원</span>
              </div>
              <div>
                <span>요청사항</span>
                <div>
                  {quoteDetail?.request &&
                    quoteDetail?.request.split('\n').map((line, idx) => (
                      <p key={idx}>
                        {line}
                        <br />
                      </p>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.quoteFrame}>
            <p>담당자 정보</p>
            <div className={styles.quoteProfileWrap}>
              <img src={profile} alt="profile" />
              <div className={styles.profileInfoWrap}>
                <div className={styles.companyName}>
                  <span>{quoteDetail?.member_client_name}</span>
                  {quoteDetail?.member_client_login_id && (
                    <div
                      onClick={() => {
                        handleChat(quoteDetail);
                      }}
                    >
                      1:1 문의하기
                    </div>
                  )}
                </div>
                <div className={styles.date}>
                  <span>견적일시</span>
                  <span>
                    {quoteDetail?.created_at.slice(2, 16).replaceAll('-', '.')}
                  </span>
                </div>
                <div className={styles.managerInfo}>
                  <span>담당자</span>
                  <span>
                    {quoteDetail?.manager_name} / {quoteDetail?.manager_hp}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default QuoteDetail;
