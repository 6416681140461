import styles from './Partner.module.scss';
import axiosClient from '../../libs/axiosClient';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import banner01 from '../../assets/banner/banner01.png';
import mbanner01 from '../../assets/banner/mbanner01.png';
import filter from '../../assets/partnerList/filter.png';
import pcSearch from '../../assets/search/search.png';
import mSearch from '../../assets/search/mSearch.png';
import cancel from '../../assets/search/cancel.png';
import nocompany from '../../assets/partnerList/nocompany.png';
import symbol from '../../assets/partnerList/symbol.png';
import share from '../../assets/partnerList/share.png';
import heart from '../../assets/partnerList/heart.png';
import heartblack from '../../assets/partnerList/heartblack.png';
import send from '../../assets/partnerList/send.png';
import unablePrev from '../../assets/partnerList/unablePrev.png';
import ableNext from '../../assets/partnerList/ableNext.png';
import dropdown from '../../assets/sidemenu/dropdown.png';
import { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { loginConfirm } from '../../common/loginConfirm';
import { isDesktop, isMobile } from 'react-device-detect';
import SideFilter from '../../components/SideFilter/SideFilter';
import CopyAlertModal from '../../components/CopyAlertModal/CopyAlertModal';
import MainBanner from '../../components/MainBanner/MainBanner';
import useDidMountEffect from '../../components/useDidMountEffect';

export interface PartnerListType {
  addr: string;
  business_partner: string;
  company_cert_file: string;
  category_name: string;
  ceo_name: string;
  email: string;
  fax: string;
  homepage: string;
  hp: string;
  id: string;
  login_id: string;
  img: {
    count: number;
    data: string;
  };
  is_bookmark: number;
  is_cert: number;
  manager_hp: string;
  manager_name: string;
  member_quantity: string;
  memo: string;
  name: string;
  product: string;
  since_date: string;
  total_member_quantity: string;
  count: number;
  // addr_category: string;
  // category_code: string;
  // cluster: string;
  // company_cateogry: string;
  // company_num: string;
  // complex_category: string;
  // complex_name: string;
  // council_name: string;
  // created_at: string;
  // factory_scale: string;
  // land_scale: string;
  // material_category: string;
  // material_category2: string;
  // material_name: string;
  // material_name2: string;
  // material_standard: string;
  // produce_category: string;
  // product_capacity: string;
  // product_category: string;
  // product_name: string;
  // product_name2: string;
  // product_standard: string;
  // store_category: string;
  // updated_at: string;
}

export interface CheckboxOption {
  id: string;
  name: string;
  type: string;
  option: string;
}

export const serviceOption: CheckboxOption[] = [
  { name: '농산물', id: 's1', type: 'check', option: 'service' },
  { name: '축산물', id: 's2', type: 'check', option: 'service' },
  { name: '수산물', id: 's3', type: 'check', option: 'service' },
  { name: '분말', id: 's4', type: 'check', option: 'service' },
  { name: '액상', id: 's5', type: 'check', option: 'service' },
  { name: '가공', id: 's6', type: 'check', option: 'service' },
  { name: '건강기능', id: 's7', type: 'check', option: 'service' },
  { name: '포장', id: 's8', type: 'check', option: 'service' },
  { name: '기타', id: 's9', type: 'check', option: 'service' },
];
export const areaOption: CheckboxOption[] = [
  { name: '서울특별시', id: 'a1', type: 'check', option: 'area' },
  { name: '부산광역시', id: 'a2', type: 'check', option: 'area' },
  { name: '대구광역시', id: 'a3', type: 'check', option: 'area' },
  { name: '인천광역시', id: 'a4', type: 'check', option: 'area' },
  { name: '광주광역시', id: 'a5', type: 'check', option: 'area' },
  { name: '대전광역시', id: 'a6', type: 'check', option: 'area' },
  { name: '울산광역시', id: 'a7', type: 'check', option: 'area' },
  { name: '세종특별자치시', id: 'a8', type: 'check', option: 'area' },
  { name: '경기도', id: 'a9', type: 'check', option: 'area' },
  { name: '강원도', id: 'a10', type: 'check', option: 'area' },
  { name: '충청북도', id: 'a11', type: 'check', option: 'area' },
  { name: '충청남도', id: 'a12', type: 'check', option: 'area' },
  { name: '전라북도', id: 'a13', type: 'check', option: 'area' },
  { name: '전라남도', id: 'a14', type: 'check', option: 'area' },
  { name: '경상북도', id: 'a15', type: 'check', option: 'area' },
  { name: '경상남도', id: 'a16', type: 'check', option: 'area' },
  { name: '제주특별자치도', id: 'a17', type: 'check', option: 'area' },
];

export const storageOption: CheckboxOption[] = [
  { name: '냉장보관', id: 'st1', type: 'check', option: 'storage' },
  { name: '냉동보관', id: 'st2', type: 'check', option: 'storage' },
  { name: '실온보관', id: 'st3', type: 'check', option: 'storage' },
];

function Home() {
  let locationQuery: any;
  let apiQueryParams: any[] = [];
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const query = new URLSearchParams(location.search);
  const partnerListRef = useRef<HTMLDivElement>(null);
  const searchParams = new URLSearchParams(window.location.search);
  const certParam = searchParams.get('cert');
  const pageParam = Number(searchParams.get('page'));
  const [userType, setUserType] = useState('');
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isCert, setIsCert] = useState<any>('');
  const [certOpen, setCertOpen] = useState(isDesktop ? true : false);
  const [serviceOpen, setServiceOpen] = useState(isDesktop ? true : false);
  const [areaOpen, setAreaOpen] = useState(isDesktop ? true : false);
  const [storageOpen, setStorageOpen] = useState(isDesktop ? true : false);
  const [searchInput, setSearchInput] = useState('');
  const [mSearchUrl, setMSearchUrl] = useState('');
  const [searchKeyword, setSearchKeyword] = useState<any[]>([]);
  const [selectServiceList, setSelectServiceList] = useState<CheckboxOption[]>(
    [],
  );
  const [selectAreaList, setSelectAreaList] = useState<CheckboxOption[]>([]);
  const [selectStorageList, setSelectStorageList] = useState<CheckboxOption[]>(
    [],
  );
  // 파트너리스트 목록
  const [allPartnerList, setAllPartnerList] = useState<PartnerListType[]>([]);
  const [allPartnerCount, setAllPartnerCount] = useState(0);
  // 파트너리스트 페이징
  const [partnerPageList, setPartnerPageList] = useState([1]);
  const [partnerPage, setPartnerPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  // api 보낼 query 형식
  const [apiQuery, setApiQuery] = useState<string[]>([]);
  const [searchFix, setSearchFix] = useState(false);

  useEffect(() => {
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (!partnerListRef.current) return;
        if (window.scrollY > partnerListRef.current.offsetTop && isDesktop)
          setSearchFix(true);
        else setSearchFix(false);
      }, 500),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  useEffect(() => {
    if (!location.search) {
      getApi();
      setIsCert(undefined);
      setSearchKeyword([]);
      setSearchInput('');
      return;
    }

    let noOptionCnt = 0;
    const keywords: CheckboxOption[] = [];
    query.forEach((value, key) => {
      const option = decodeURIComponent(key);

      // ['s1,s2,s3'] 을 , 기준으로 ['s1','s2','s3']로 바꾸기
      const arr = value.split(',');
      arr.forEach((id) => {
        // option: service, area, storage, input
        let optionItem;
        if (option === 'service') {
          optionItem = serviceOption.find((item) => item.id === id);
        } else if (option === 'area') {
          optionItem = areaOption.find((item) => item.id === id);
        } else if (option === 'storage') {
          optionItem = storageOption.find((item) => item.id === id);
        } else {
          //input
          optionItem = { name: id };
        }
        // if (option === 'page') setPartnerPage(Number(id));
        // if (option === 'cert') setIsCert(id);
        if (optionItem) {
          keywords.push({
            name: optionItem.name.toString(),
            id: id.toString(),
            type:
              option === 'word'
                ? 'input'
                : option === 'page' || option === 'cert'
                ? option
                : 'check',
            option: option,
          });
        } else {
          noOptionCnt++;
          // service, area, storage, input 에 해당하지않는 값을 검색어에 입력한 경우
          alert('잘못된 경로 입니다.');
          setSearchKeyword([]);
          navigate(`/partner`);
          window.location.reload();
        }
      });
    });
    if (noOptionCnt > 0) keywords.length = 0;
    setSearchKeyword(keywords);
    setApiQuery(generateSearchParams(keywords));
    getApi();
  }, [location.search]);

  const generateSearchParams = (keywords: any) => {
    const apisearchParams: string[] = [];
    keywords.forEach((item: any) => {
      if (item.option === 'service') {
        apisearchParams.push(`search_produce[]=${item.id.replace(/\D/g, '')}`);
      } else if (item.option === 'area') {
        apisearchParams.push(`search_addr[]=${item.id.replace(/\D/g, '')}`);
      } else if (item.option === 'storage') {
        apisearchParams.push(`search_store[]=${item.id.replace(/\D/g, '')}`);
      }
    });
    apiQueryParams = apisearchParams;
    return apisearchParams;
  };

  const handeSearchKeyword = () => {
    const serviceList = searchKeyword.filter(
      (item) => item.option === 'service',
    );
    const areaList = searchKeyword.filter((item) => item.option === 'area');
    const storageList = searchKeyword.filter(
      (item) => item.option === 'storage',
    );
    const page = searchKeyword.filter((item) => item.option === 'page');
    const cert = searchKeyword.filter((item) => item.option === 'cert');
    // location.search때 바꾸면 두번 일어나서 getApi가
    if (page[0]) setPartnerPage(page[0].id);
    if (cert[0]) setIsCert(cert[0].id);
    setSelectServiceList(serviceList);
    setSelectAreaList(areaList);
    setSelectStorageList(storageList);

    const params: { [key: string]: string[] } = {};
    let encodeQuery = '';
    searchKeyword.forEach((keyword: { option: string | number; id: any }) => {
      if (!params[keyword.option]) {
        params[keyword.option] = [keyword.id];
      } else {
        params[keyword.option].push(keyword.id);
      }
      const queryString = handleQuery(params);
      encodeQuery = encodeURIComponent(queryString)
        .replaceAll('%20', '+')
        .replaceAll('%3D', '=')
        .replaceAll('%26', '&');
    });
    navigate(`/partner?${encodeQuery}`);
  };

  useDidMountEffect(() => {
    handeSearchKeyword();
  }, [searchKeyword]);

  useDidMountEffect(() => {
    setSearchKeyword((prev) => {
      const updated = prev.map((item) =>
        item.option === 'page'
          ? {
              ...item,
              name: partnerPage.toString(),
              id: partnerPage.toString(),
            }
          : item,
      );

      const isExist = prev.some((item) => item.option === 'page');
      if (isExist) {
        return updated;
      } else {
        return [
          ...prev,
          {
            name: partnerPage.toString(),
            id: partnerPage.toString(),
            type: 'page',
            option: 'page',
          },
        ];
      }
    });
  }, [partnerPage]);

  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt((partnerPage / 5).toString()) * 5 + 1;
    if (!(partnerPage % 5)) startPage -= 5;
    let endPage =
      startPage + 4 <
      allPartnerCount / 15 + (allPartnerCount % 15 !== 0 ? 1 : 0)
        ? startPage + 4
        : allPartnerCount / 15 + (allPartnerCount % 15 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    let lastPage = partnerPage === Math.ceil(allPartnerCount / 15);
    setIsLastPage(lastPage);
    setPartnerPageList([...list]);
  }, [allPartnerCount, partnerPage]);

  const getApi = async () => {
    locationQuery = new URLSearchParams(search);
    let word = decodeURIComponent(locationQuery.get('word'));
    if (word === null || word === 'null' || word === undefined) word = '';
    await axiosClient
      .get(
        `/member-manufactures?page=${
          partnerPage === pageParam
            ? partnerPage
            : pageParam !== 0
            ? pageParam
            : 1
        }&is_cert=${
          isCert ? isCert : certParam ? certParam : 0
        }&per_page=15&keyword=${word}&${apiQueryParams.join('&')}`,
        {
          headers: {
            'ngrok-skip-browser-warning': true,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        setAllPartnerList(res.data.data);
        setAllPartnerCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCertChange = (cert: string) => {
    handlePageChange();
    if (cert === isCert || (cert === '1' && isCert === '0')) {
      setIsCert(undefined);
      setSearchKeyword((prev) => prev.filter((item) => item.option !== 'cert'));
    } else {
      setIsCert(cert);
      setSearchKeyword((prev) => {
        const updated = prev.map((item) =>
          item.option === 'cert' ? { ...item, name: cert, id: cert } : item,
        );

        const isExist = prev.some((item) => item.option === 'cert');
        if (isExist) {
          return updated;
        } else {
          return [
            ...prev,
            {
              name: cert,
              id: cert,
              type: 'check',
              option: 'cert',
            },
          ];
        }
      });
    }
  };

  const handlePageChange = () => {
    const updatedKeywords = searchKeyword.map((keyword) => {
      if (keyword.option === 'page') {
        return {
          ...keyword,
          name: '1',
          id: '1',
        };
      }
      return keyword;
    });

    setSearchKeyword(updatedKeywords);
  };

  const handleCheckboxChange = (option: any) => {
    handlePageChange();

    if (option === '제조서비스전체') {
      if (selectServiceList.length === serviceOption.length) {
        setSelectServiceList([]);
        setSearchKeyword(
          searchKeyword.filter((item) => item.option !== 'service'),
        );
      } else {
        setSelectServiceList(serviceOption.map((option) => option));
        setSearchKeyword((prev) => {
          const exit = prev.filter(
            (item) =>
              item.option === 'word' ||
              item.option === 'area' ||
              item.option === 'storage',
          );
          return [...exit, ...serviceOption];
        });
      }
    } else if (option === '지역전체') {
      if (selectAreaList.length === areaOption.length) {
        setSelectAreaList([]);
        setSearchKeyword(
          searchKeyword.filter((item) => item.option !== 'area'),
        );
      } else {
        setSelectAreaList(areaOption.map((option) => option));
        setSearchKeyword((prev) => {
          const exit = prev.filter(
            (item) =>
              item.option === 'word' ||
              item.option === 'service' ||
              item.option === 'storage',
          );
          return [...exit, ...areaOption];
        });
      }
    } else if (option === '보관방식전체') {
      if (selectStorageList.length === storageOption.length) {
        setSelectStorageList([]);
        setSearchKeyword(
          searchKeyword.filter((item) => item.option !== 'storage'),
        );
      } else {
        setSelectStorageList(storageOption.map((option) => option));
        setSearchKeyword((prev) => {
          const exit = prev.filter(
            (item) =>
              item.option === 'word' ||
              item.option === 'service' ||
              item.option === 'area',
          );
          return [...exit, ...storageOption];
        });
      }
    } else {
      if (option.option === 'area') {
        setSelectAreaList((prevKeyword) => {
          const exists = prevKeyword.some((prev) => prev.name === option.name);
          if (exists) {
            setSearchKeyword((prev) =>
              prev.filter((item) => item.name !== option.name),
            );
            return prevKeyword.filter((prev) => prev.name !== option.name);
          } else {
            setSearchKeyword((prev) => [...prev, option]);
            return [...prevKeyword, option];
          }
        });
      } else if (option.option === 'service') {
        setSelectServiceList((prevKeyword) => {
          const exists = prevKeyword.some((prev) => prev.name === option.name);
          if (exists) {
            setSearchKeyword((prev) =>
              prev.filter((item) => item.name !== option.name),
            );
            return prevKeyword.filter((prev) => prev.name !== option.name);
          } else {
            setSearchKeyword((prev) => [...prev, option]);
            return [...prevKeyword, option];
          }
        });
      } else if (option.option === 'storage') {
        setSelectStorageList((prevKeyword) => {
          const exists = prevKeyword.some((prev) => prev.name === option.name);
          if (exists) {
            setSearchKeyword((prev) =>
              prev.filter((item) => item.name !== option.name),
            );
            return prevKeyword.filter((prev) => prev.name !== option.name);
          } else {
            setSearchKeyword((prev) => [...prev, option]);
            return [...prevKeyword, option];
          }
        });
      }
    }
  };

  const onSearchBtn = () => {
    if (searchInput === '') return setSearchInput('');
    const newKeyword = searchInput.trim();
    if (!searchKeyword.find((keyword) => keyword.name === newKeyword)) {
      handlePageChange();
      setSearchKeyword((prevKeywords) => [
        ...prevKeywords,
        {
          name: newKeyword,
          id: newKeyword,
          type: 'input',
          option: 'word',
        },
      ]);
    }
    setSearchInput('');
  };

  const onChangeSearchInput = (e: any) => {
    // if (searchInput === '') return setSearchInput('');
    setSearchInput(e.target.value);
  };

  const onKeyDownSearchInput = (e: any) => {
    if (searchInput.trim() === '') return setSearchInput('');
    if (e.key === 'Enter' && searchInput.trim() !== '') {
      if (e.nativeEvent.isComposing) return;
      const newKeyword = searchInput.trim();
      if (!searchKeyword.find((keyword) => keyword.name === newKeyword)) {
        handlePageChange();
        setSearchKeyword((prevKeywords) => [
          ...prevKeywords,
          {
            name: newKeyword,
            id: newKeyword,
            type: 'input',
            option: 'word',
          },
        ]);
      }
      setSearchInput('');
    }
  };

  const handleRemoveSearchKeyword = (option: any) => {
    const existingOption = searchKeyword.findIndex(
      (opt) => opt.id === option.id,
    );
    if (existingOption !== -1) {
      const updated = [...searchKeyword];
      updated.splice(existingOption, 1);
      const pageUpdated = updated.map((keyword) => {
        if (keyword.option === 'page') {
          return {
            ...keyword,
            name: '1',
            id: '1',
          };
        }
        return keyword;
      });
      setSearchKeyword(pageUpdated);
      const serviceUpdated = updated.filter(
        (item) => item.option === 'service',
      );
      const areaUpdated = updated.filter((item) => item.option === 'area');
      const storageUpdated = updated.filter(
        (item) => item.option === 'storage',
      );
      setSelectServiceList(serviceUpdated);
      setSelectAreaList(areaUpdated);
      setSelectStorageList(storageUpdated);
    }
  };

  // const handleQuery = (params: any) =>
  const handleQuery = (params: { [key: string]: string[] }) => {
    let queryString = '';
    for (const key in params) {
      // hasOwnProperty: 특정 property를 가지고있는지 확인 Bool
      if (params.hasOwnProperty(key)) {
        // %2C로 항목 구분
        // query.append('key','value')
        queryString += `&${key}=${params[key].join(',')}`;
        // queryString += `&${key}=${params[key].join('%2C')}`;
      }
    }
    // 첫 번째 & 제거
    return queryString.slice(1);
  };

  const handlePaginationDown = () => {
    if (partnerPage == 1) return;
    setPartnerPage((prev) => Number(prev) - 1);
  };
  const handlePaginationUp = () => {
    if (partnerPage >= allPartnerCount / 15) return;
    setPartnerPage((prev) => Number(prev) + 1);
  };

  const needLogin = () => {
    return loginConfirm();
  };

  const handleFilter = (
    url: any,
    keyword: any,
    cert: any,
    service: any,
    area: any,
    storage: any,
  ) => {
    setMSearchUrl(url);
    setSearchKeyword(keyword);
    setIsCert(cert);
    setSelectServiceList(service);
    setSelectAreaList(area);
    setSelectStorageList(storage);
  };

  const handeMSearchUrl = () => {
    navigate(`/partner?${mSearchUrl}`);
  };

  // 첫 렌더링 막는법
  useDidMountEffect(() => {
    handeMSearchUrl();
  }, [mSearchUrl]);

  const handleWish = (companyId: string, isBookmark: number) => {
    if (!allPartnerList) return;
    if (!needLogin()) return;
    let formData = new FormData();

    if (isBookmark === 0) {
      isBookmark = 1;
      const updatedPartnerList = allPartnerList.map((partner) => {
        if (partner.id === companyId) {
          return { ...partner, is_bookmark: 1 };
        } else {
          return partner;
        }
      });
      setAllPartnerList(updatedPartnerList);
    } else {
      isBookmark = 0;
      const updatedPartnerList = allPartnerList.map((partner) => {
        if (partner.id === companyId) {
          return { ...partner, is_bookmark: 0 };
        } else {
          return partner;
        }
      });
      setAllPartnerList(updatedPartnerList);
    }

    formData.append('member_manufacture_id', companyId);
    formData.append('bookmark', isBookmark.toString());
    axiosClient
      .post(`/bookmark`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCopy = () => {
    setCopyModalOpen((prev) => !prev);
    setTimeout(() => {
      setCopyModalOpen((prev) => !prev);
    }, 1000);
  };

  const handleChat = (list: any) => {
    if (!needLogin()) return;
    axiosClient
      .post(
        '/chat/room/create',
        {
          login_id: list.login_id,
          channel_id: '',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        // console.log(res.data.channel);
        const chat = res.data.channel;
        // navigate(`/chat?chat-group=${chat.id}&chat-number=${list.id}`);
        navigate(`/chat?chat-group=${chat.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.contentWrap} ${isDesktop ? '' : styles.isMobile}`}
      >
        {/* <SideMenu /> */}
        {!isDesktop && <MainBanner />}
        {isDesktop && (
          <div className={styles.sideWrap}>
            <div className={styles.menuWrap}>
              <div
                className={styles.menuTitleWrap}
                onClick={() => {
                  setCertOpen((prev) => !prev);
                }}
              >
                <span>파트너 찾기</span>
                <img
                  src={dropdown}
                  alt="dropdown"
                  style={{
                    transform: certOpen ? 'unset' : 'rotate(180deg)',
                  }}
                />
              </div>
              <div
                className={`${
                  certOpen ? styles.certListWrap : styles.closeWrap
                }`}
              >
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id="파트너찾기전체"
                    checked={isCert === '0'}
                    onChange={() => handleCertChange('0')}
                  />
                  <label htmlFor="파트너찾기전체">
                    <span>모두선택</span>
                  </label>
                </div>
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id={`cert-1`}
                    checked={isCert === '0' || isCert === '1'}
                    onChange={() => handleCertChange('1')}
                  />
                  <label htmlFor="cert-1">
                    <span>빠른문의 파트너</span>
                  </label>
                </div>
              </div>
              <div className={styles.border}></div>
              <div
                className={styles.menuTitleWrap}
                onClick={() => {
                  setServiceOpen((prev) => !prev);
                }}
              >
                <span>제조서비스</span>
                <img
                  src={dropdown}
                  alt="dropdown"
                  style={{
                    transform: serviceOpen ? 'unset' : 'rotate(180deg)',
                  }}
                />
              </div>
              <div
                className={`${
                  serviceOpen ? styles.serviceListWrap : styles.closeWrap
                }`}
              >
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id="제조서비스전체"
                    checked={selectServiceList.length === serviceOption.length}
                    onChange={() => handleCheckboxChange('제조서비스전체')}
                  />
                  <label htmlFor="제조서비스전체">
                    <span>모두선택</span>
                  </label>
                </div>
                {serviceOption &&
                  serviceOption.map((option: any, idx: any) => (
                    <div key={option.name} className={styles.customCheckbox}>
                      <input
                        checked={selectServiceList.some(
                          (opt) => opt.id === option.id,
                        )}
                        onChange={() => handleCheckboxChange(option)}
                        type="checkbox"
                        id={`service-${idx}`}
                      />
                      <label htmlFor={`service-${idx}`}>
                        <span>{option.name}</span>
                      </label>
                    </div>
                  ))}
              </div>
              <div className={styles.border}></div>
              <div
                className={styles.menuTitleWrap}
                onClick={() => {
                  setAreaOpen((prev) => !prev);
                }}
              >
                <span>지역</span>
                <img
                  src={dropdown}
                  alt="dropdown"
                  style={{
                    transform: areaOpen ? 'unset' : 'rotate(180deg)',
                  }}
                />
              </div>
              <div
                className={`${
                  areaOpen ? styles.areaListWrap : styles.closeWrap
                }`}
              >
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id="지역전체"
                    checked={selectAreaList.length === areaOption.length}
                    onChange={() => handleCheckboxChange('지역전체')}
                  />
                  <label htmlFor="지역전체">
                    <span>모두선택</span>
                  </label>
                </div>
                {areaOption &&
                  areaOption.map((option: any, idx: any) => (
                    <div key={option.name} className={styles.customCheckbox}>
                      <input
                        checked={selectAreaList.some(
                          (opt) => opt.id === option.id,
                        )}
                        onChange={() => handleCheckboxChange(option)}
                        type="checkbox"
                        id={`area-${idx}`}
                      />
                      <label htmlFor={`area-${idx}`}>
                        <span>{option.name}</span>
                      </label>
                    </div>
                  ))}
              </div>
              <div className={styles.border}></div>
              <div
                className={styles.menuTitleWrap}
                onClick={() => {
                  setStorageOpen((prev) => !prev);
                }}
              >
                <span>보관방식</span>
                <img
                  src={dropdown}
                  alt="dropdown"
                  style={{
                    transform: storageOpen ? 'unset' : 'rotate(180deg)',
                  }}
                />
              </div>
              <div
                className={`${
                  storageOpen ? styles.storageListWrap : styles.closeWrap
                }`}
              >
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id="보관방식전체"
                    checked={selectStorageList.length === storageOption.length}
                    onChange={() => handleCheckboxChange('보관방식전체')}
                  />
                  <label htmlFor="보관방식전체">
                    <span>모두선택</span>
                  </label>
                </div>
                {storageOption &&
                  storageOption.map((option: any, idx: any) => (
                    <div key={option.name} className={styles.customCheckbox}>
                      <input
                        checked={selectStorageList.some(
                          (opt) => opt.id === option.id,
                        )}
                        // checked={selectAreaList.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                        type="checkbox"
                        id={`storage-${idx}`}
                      />
                      <label htmlFor={`storage-${idx}`}>
                        <span>{option.name}</span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        <div className={styles.mainContentWrap}>
          {isDesktop && <MainBanner />}
          <div
            className={`${styles.searchWrap} ${
              searchFix ? styles.searchFix : ''
            }`}
          >
            <div className={styles.searchFixWrap}>
              <div
                className={`${styles.searchInputWrap} ${
                  isDesktop ? '' : styles.isMobile
                }`}
              >
                <input
                  className={styles.search}
                  placeholder="검색어를 입력하세요."
                  value={searchInput}
                  onChange={onChangeSearchInput}
                  onKeyDown={onKeyDownSearchInput}
                />
                {isMobile && (
                  <img
                    className={styles.filter}
                    src={filter}
                    alt="filter"
                    onClick={() => setFilterOpen(true)}
                  />
                )}
                <img
                  src={isDesktop ? pcSearch : mSearch}
                  alt="search"
                  onClick={onSearchBtn}
                />
              </div>
              <div
                className={`${styles.searchKeywordWrap} ${
                  searchKeyword.filter(
                    (item) => item.option !== 'page' && item.option !== 'cert',
                  ).length === 0
                    ? styles.noKeywordWrap
                    : ''
                }`}
              >
                <div className={styles.searchKeyword}>
                  {searchKeyword &&
                    searchKeyword
                      .filter(
                        (item) =>
                          item.option !== 'page' && item.option !== 'cert',
                      )
                      .map((item: any, idx) => (
                        <div
                          key={idx}
                          className={`${styles.searchKeywordFrame} ${
                            item.type === 'input'
                              ? styles.inputSearchKeyword
                              : ''
                          }`}
                        >
                          <span>{item.name ? item.name : item}</span>
                          <img
                            id={item.id}
                            src={cancel}
                            alt="cancel"
                            onClick={() => handleRemoveSearchKeyword(item)}
                          />
                        </div>
                      ))}
                </div>
                {searchKeyword.filter(
                  (item) => item.option !== 'page' && item.option !== 'cert',
                ).length > 0 && (
                  <div
                    onClick={() => {
                      setPartnerPage(1);
                      setIsCert(undefined);
                      setSearchKeyword([]);
                      setSelectServiceList([]);
                      setSelectAreaList([]);
                      setSelectStorageList([]);
                      navigate('/partner');
                      // navigate(`/partner`);
                      // window.location.reload();
                    }}
                    className={styles.searchKeywordInit}
                  >
                    초기화
                  </div>
                )}
                {/* {searchKeyword.filter((item) => item.option !== 'page')
                  .length !== 0 && (
                  <div
                    onClick={() => {
                      setSearchKeyword([]);
                      setSelectServiceList([]);
                      setSelectAreaList([]);
                      setSelectStorageList([]);
                    }}
                    className={styles.searchKeywordInit}
                  >
                    초기화
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <div ref={partnerListRef} className={styles.allPartnerWrap}>
            <div className={styles.titleWrap}>
              <div className={styles.leftTitleWrap}>
                <p>전체 파트너</p>
                <p>{allPartnerCount}개의 파트너</p>
              </div>
            </div>
            <div className={styles.partnerListWrap}>
              {allPartnerList &&
                allPartnerList.map((list, idx) => (
                  <div key={idx} className={styles.partnerListFrame}>
                    {userType !== 'member_manufacture' &&
                      list.is_cert === 1 && (
                        <div className={styles.symbolWrap}>
                          <img src={symbol} alt="symbol" />
                          <span>빠른 문의</span>
                        </div>
                      )}
                    <div
                      className={styles.partnerListWrapSlide}
                      onClick={() => navigate(`/partner-detail/${list.id}`)}
                    >
                      <img
                        src={list.img.data[0] ? list.img.data[0] : nocompany}
                        alt="banner"
                      />
                      <div className={styles.partnerListContentWrap}>
                        <div className={styles.partnerListTitleWrap}>
                          <span>{list.name}</span>
                        </div>
                        <p className={styles.partnerContent}>
                          {list.category_name}
                        </p>
                        <p className={styles.partnerAddress}>{list.addr}</p>
                      </div>
                    </div>
                    <CopyToClipboard
                      text={`${window.location.origin}/partner-detail/${list.id}`}
                      onCopy={handleCopy}
                    >
                      <div className={styles.partnerShareWrap}>
                        <img src={share} alt="share" />
                      </div>
                    </CopyToClipboard>
                    {/* <div className={styles.partnerShareWrap}>
                      <img src={share} alt="share" />
                    </div> */}
                    <div
                      className={`${styles.partnerWishWrap} ${
                        list.is_bookmark === 1
                          ? styles.heart
                          : styles.blackheart
                      }`}
                    >
                      <img
                        onClick={() => handleWish(list.id, list.is_bookmark)}
                        src={list.is_bookmark === 1 ? heart : heartblack}
                        alt="heart"
                      />
                    </div>
                    {userType !== 'member_manufacture' &&
                      list.is_cert === 1 && (
                        <div
                          onClick={() => {
                            handleChat(list);
                          }}
                          className={styles.partnerSendBtnWrap}
                        >
                          <img src={send} alt="send" />
                          <span>1:1 문의하기</span>
                        </div>
                      )}
                  </div>
                ))}
            </div>
          </div>
          {allPartnerList.length > 0 && (
            <div className={styles.partnerPaginationWrap}>
              <img
                style={{ transform: partnerPage === 1 ? '' : 'rotate(180deg)' }}
                src={partnerPage === 1 ? unablePrev : ableNext}
                alt="prev"
                onClick={handlePaginationDown}
              />
              <ul>
                {partnerPageList &&
                  partnerPageList.map((page) => (
                    <li
                      key={page}
                      onClick={() => {
                        setPartnerPage(page);
                      }}
                      className={page == partnerPage ? styles.hit : ''}
                    >
                      <p>{page}</p>
                    </li>
                  ))}
              </ul>
              <img
                style={{ transform: isLastPage ? 'rotate(180deg)' : '' }}
                src={isLastPage ? unablePrev : ableNext}
                alt="next"
                onClick={handlePaginationUp}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
      {filterOpen && (
        <SideFilter
          closer={setFilterOpen}
          onFilter={handleFilter}
          searchList={searchKeyword}
          certList={isCert}
          serviceList={selectServiceList}
          areaList={selectAreaList}
          storageList={selectStorageList}
        />
      )}
      {copyModalOpen && <CopyAlertModal />}
    </>
  );
}
export default Home;
