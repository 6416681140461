import styles from './Guide.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { isDesktop } from 'react-device-detect';

function Guide() {
  return (
    <>
      <Header />
      <div
        className={`${styles.container} ${isDesktop ? '' : styles.isMobile}`}
      >
        <h3>이용안내</h3> <br />
      </div>
      <Footer />
    </>
  );
}
export default Guide;
