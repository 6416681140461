import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleRedirectUrlCheck } from '../common/redirectUrlCheck';
import axiosClient from '../libs/axiosClient';
import { setToken, STORAGE_ITEMS } from './auth';
const NAVER_REST_API_KEY = 'rGvK78BiWTy8CWfoKO2R';
const NAVER_REDIRECT_URI = `${window.location.origin}/auth/naver/callback`;
export const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_REST_API_KEY}&redirect_uri=${NAVER_REDIRECT_URI}&state=loginTest`;

function Naver() {
  const navigate = useNavigate();
  useEffect(() => {
    let params = new URL(document.URL).searchParams;
    let code = params.get('code');
    let state = params.get('state');

    axiosClient
      .get(`/login/naver?code=${code}&state=${state}`, {
        headers: {
          'Access-Control-Allow-Origin': 'https://admin.shorton.kr/api',
          'ngrok-skip-browser-warning': true,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        setToken(res.data);
        let rurl = localStorage.getItem('rurl');
        if (handleRedirectUrlCheck(rurl || '/')) navigate(rurl || '/');
        else navigate('/');
      })
      .catch((err) => {
        // if (err.response && err.response.data && err.response.data.detail)
        //   alert(err.response.data.detail);
        // navigate('/login');
        if (err.response.data.msg === '이미 연동된 계정입니다.') {
          alert(err.response.data.msg);
          navigate('/');
        } else navigate('/login');
      });
  }, []);
  return <div></div>;
}
export default Naver;
