import styles from './ManagementContract.module.scss';
import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MypageSideMenu from '../../components/MypageSideMenu/MypageSideMenu';
import profile from '../../assets/partnerList/profile.png';
import estimate from '../../assets/estimate/estimate.png';
import unablePrev from '../../assets/partnerList/unablePrev.png';
import ableNext from '../../assets/partnerList/ableNext.png';
import axiosClient from '../../libs/axiosClient';
import ContractImgModal from '../../components/ContractImgModal/ContractImgModal';
import { IEstimateType } from '../ManagementEstimate/ManagementEstimate';
import { useNavigate } from 'react-router-dom';
import { loginConfirm } from '../../common/loginConfirm';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { loginCheck } from '../../auth/auth';
import { isDesktop } from 'react-device-detect';

function ManagementContract() {
  const navigate = useNavigate();
  const [partnerPageList, setPartnerPageList] = useState([1]);
  const [partnerPage, setPartnerPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [allPartnerCount, setAllPartnerCount] = useState(0);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [selectImg, setSelectImg] = useState('');
  const [partnerList, setParterList] = useState<IEstimateType[]>([]);
  const [userType, setUserType] = useState('');

  useEffect(() => {
    if (!loginCheck()) return navigate('/login');
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  useEffect(() => {
    getPartnerList();
  }, [partnerPage]);

  const getPartnerList = () => {
    axiosClient
      .get(`/contracts?page=${partnerPage}&per_page=10`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setAllPartnerCount(res.data.count);
        setParterList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt((partnerPage / 5).toString()) * 5 + 1;
    if (!(partnerPage % 5)) startPage -= 5;
    let endPage =
      startPage + 4 <
      allPartnerCount / 10 + (allPartnerCount % 10 !== 0 ? 1 : 0)
        ? startPage + 4
        : allPartnerCount / 10 + (allPartnerCount % 10 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    let lastPage = partnerPage === Math.ceil(allPartnerCount / 10);
    if (allPartnerCount === 0) lastPage = true;
    setIsLastPage(lastPage);
    setPartnerPageList([...list]);
  }, [allPartnerCount, partnerPage]);

  const handlePaginationDown = () => {
    if (partnerPage == 1) return;
    setPartnerPage((prev) => prev - 1);
  };
  const handlePaginationUp = () => {
    if (partnerPage >= allPartnerCount / 10) return;
    setPartnerPage((prev) => prev + 1);
  };

  const needLogin = () => {
    return loginConfirm();
  };

  const handleChat = (list: any) => {
    if (!needLogin()) return;
    axiosClient
      .post(
        '/chat/room/create',
        {
          login_id: list.login_id,
          channel_id: '',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        const chat = res.data.channel;
        navigate(`/chat?chat-group=${chat.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {};

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <MypageSideMenu />
        <div className={styles.mainContentWrap}>
          <p>계약서 관리</p>
          <div className={styles.listWrap}>
            {partnerList &&
              partnerList.map((list, idx) => (
                <div key={idx} className={styles.listFrame}>
                  <div className={styles.left}>
                    <div className={styles.profile}>
                      <img
                        src={list.profile_img ? list.profile_img : profile}
                        alt="profile"
                      />
                    </div>
                    <div className={styles.partnerInfoWrap}>
                      <div className={styles.companyName}>
                        <span>{list.name}</span>
                        {list.login_id && (
                          <div
                            onClick={() => {
                              handleChat(list);
                            }}
                          >
                            1:1 문의하기
                          </div>
                        )}
                      </div>
                      <div className={styles.date}>
                        <span>견적일시</span>
                        <span>
                          {' '}
                          {list.regist_date.slice(2, 16).replaceAll('-', '.')}
                        </span>
                      </div>
                      <div className={styles.managerInfo}>
                        <span>담당자</span>
                        <span>
                          {userType === 'member_manufacture' &&
                            list.name &&
                            list.name}
                          {list.hp && ' / ' + list.hp}
                          {list.manager_name && list.manager_name}
                          {list.manager_hp && ' / ' + list.manager_hp}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.right}
                    onClick={() => {
                      setSelectImg(list.img);
                      setImgModalOpen(true);
                    }}
                  >
                    {list.img.slice(-3) === 'pdf' ? (
                      <Document
                        file={list.img}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <div
                          style={{
                            height: '104px',
                            overflow: 'hidden',
                            borderRadius: '6px',
                            border: '1px solid #d9d9d9',
                            boxSizing: 'border-box',
                          }}
                        >
                          <Page
                            width={73}
                            pageNumber={1}
                            renderTextLayer={false}
                          />
                        </div>
                      </Document>
                    ) : (
                      <img src={list.img} alt="estimate" />
                    )}
                  </div>
                </div>
              ))}
          </div>
          {partnerList.length > 0 && (
            <div className={styles.paginationWrap}>
              <img
                style={{ transform: partnerPage === 1 ? '' : 'rotate(180deg)' }}
                src={partnerPage === 1 ? unablePrev : ableNext}
                alt="prev"
                onClick={handlePaginationDown}
              />
              <ul>
                {partnerPageList &&
                  partnerPageList.map((page) => (
                    <li
                      key={page}
                      onClick={() => {
                        setPartnerPage(page);
                      }}
                      className={page == partnerPage ? styles.hit : ''}
                    >
                      <p>{page}</p>
                    </li>
                  ))}
              </ul>
              <img
                style={{ transform: isLastPage ? 'rotate(180deg)' : '' }}
                src={isLastPage ? unablePrev : ableNext}
                alt="next"
                onClick={handlePaginationUp}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
      {imgModalOpen && (
        <ContractImgModal closer={setImgModalOpen} estimateImg={selectImg} />
      )}
    </>
  );
}

export default ManagementContract;
