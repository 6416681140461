import styles from './RecentPartner.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MypageSideMenu, {
  IMypageType,
} from '../../components/MypageSideMenu/MypageSideMenu';
import send from '../../assets/partnerList/send.png';
import share from '../../assets/partnerList/share.png';
import heart from '../../assets/partnerList/heart.png';
import heartblack from '../../assets/partnerList/heartblack.png';
import nocompany from '../../assets/partnerList/nocompany.png';
import unablePrev from '../../assets/partnerList/unablePrev.png';
import ableNext from '../../assets/partnerList/ableNext.png';
import axiosClient from '../../libs/axiosClient';
import symbol from '../../assets/partnerList/symbol.png';
import { loginConfirm } from '../../common/loginConfirm';
import { isDesktop } from 'react-device-detect';
import { loginCheck } from '../../auth/auth';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyAlertModal from '../../components/CopyAlertModal/CopyAlertModal';

function RecentPartner() {
  const navigate = useNavigate();
  const partnerListRef = useRef<HTMLDivElement>(null);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [partnerList, setPartnerList] = useState<IMypageType[]>([]);
  const [partnerPageList, setPartnerPageList] = useState([1]);
  const [partnerPage, setPartnerPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [allPartnerCount, setAllPartnerCount] = useState(0);
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  useEffect(() => {
    getPartnerList();
  }, [partnerPage]);

  const getPartnerList = () => {
    axiosClient
      .get(`/recent?page=${partnerPage}&per_page=15`)
      .then((res) => {
        setPartnerList(res.data.data);
        setAllPartnerCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt((partnerPage / 5).toString()) * 5 + 1;
    if (!(partnerPage % 5)) startPage -= 5;
    let endPage =
      startPage + 4 <
      allPartnerCount / 15 + (allPartnerCount % 15 !== 0 ? 1 : 0)
        ? startPage + 4
        : allPartnerCount / 15 + (allPartnerCount % 15 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    let lastPage = partnerPage === Math.ceil(allPartnerCount / 15);
    if (allPartnerCount === 0) lastPage = true;
    setIsLastPage(lastPage);
    setPartnerPageList([...list]);
  }, [allPartnerCount, partnerPage]);

  const handlePaginationDown = () => {
    if (partnerPage == 1) return;
    setPartnerPage((prev) => prev - 1);
  };
  const handlePaginationUp = () => {
    if (partnerPage >= allPartnerCount / 15) return;
    setPartnerPage((prev) => prev + 1);
  };

  const needLogin = () => {
    return loginConfirm();
  };

  const handleCopy = () => {
    setCopyModalOpen((prev) => !prev);
    setTimeout(() => {
      setCopyModalOpen((prev) => !prev);
    }, 1000);
  };

  const handleWish = (companyId: string, isBookmark: number) => {
    if (!partnerList) return;
    if (!needLogin()) return;
    let formData = new FormData();

    if (isBookmark === 0) {
      isBookmark = 1;
      const updatedPartnerList = partnerList.map((partner) => {
        if (partner.id === companyId) {
          return { ...partner, is_bookmark: 1 };
        } else {
          return partner;
        }
      });
      setPartnerList(updatedPartnerList);
    } else {
      isBookmark = 0;
      const updatedPartnerList = partnerList.map((partner) => {
        if (partner.id === companyId) {
          return { ...partner, is_bookmark: 0 };
        } else {
          return partner;
        }
      });
      setPartnerList(updatedPartnerList);
    }

    formData.append('member_manufacture_id', companyId);
    formData.append('bookmark', isBookmark.toString());
    axiosClient
      .post(`/bookmark`, formData)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChat = (list: any) => {
    if (!needLogin()) return;
    axiosClient
      .post(
        '/chat/room/create',
        {
          login_id: list.login_id,
          channel_id: '',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        const chat = res.data.channel;
        navigate(`/chat?chat-group=${chat.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <MypageSideMenu />
        <div className={styles.mainContentWrap}>
          <p>최근에 본 파트너</p>
          <div ref={partnerListRef} className={styles.allPartnerWrap}>
            <div className={styles.partnerListWrap}>
              {partnerList &&
                partnerList.map((list, idx) => (
                  <div key={idx} className={styles.partnerListFrame}>
                    {userType !== 'member_manufacture' &&
                      list.login_id &&
                      list.is_cert === 1 && (
                        <div className={styles.symbolWrap}>
                          <img src={symbol} alt="symbol" />
                          <span>빠른 문의</span>
                        </div>
                      )}
                    <div
                      className={styles.partnerListWrapSlide}
                      onClick={() => navigate(`/partner-detail/${list.id}`)}
                    >
                      <img
                        src={list.img.data[0] ? list.img.data[0] : nocompany}
                        alt="banner"
                      />
                      <div className={styles.partnerListContentWrap}>
                        <div className={styles.partnerListTitleWrap}>
                          <span>{list.name}</span>
                        </div>
                        <p className={styles.partnerContent}>
                          {list.category_name}
                        </p>
                        <p className={styles.partnerAddress}>{list.addr}</p>
                      </div>
                    </div>
                    <CopyToClipboard
                      text={`${window.location.origin}/partner-detail/${list.id}`}
                      onCopy={handleCopy}
                    >
                      <div className={styles.partnerShareWrap}>
                        <img src={share} alt="share" />
                      </div>
                    </CopyToClipboard>
                    <div className={styles.partnerWishWrap}>
                      <img
                        onClick={() => handleWish(list.id, list.is_bookmark)}
                        src={list.is_bookmark === 1 ? heart : heartblack}
                        alt="heart"
                      />
                    </div>
                    {userType !== 'member_manufacture' &&
                      list.login_id &&
                      list.is_cert === 1 && (
                        <div
                          onClick={() => {
                            handleChat(list);
                          }}
                          className={styles.partnerSendBtnWrap}
                        >
                          <img src={send} alt="send" />
                          <span>1:1 문의하기</span>
                        </div>
                      )}
                  </div>
                ))}
            </div>
          </div>
          {/* {partnerList.length > 0 && (
            <div className={styles.paginationWrap}>
              <img
                style={{ transform: partnerPage === 1 ? '' : 'rotate(180deg)' }}
                src={partnerPage === 1 ? unablePrev : ableNext}
                alt="prev"
                onClick={handlePaginationDown}
              />
              <ul>
                {partnerPageList &&
                  partnerPageList.map((page) => (
                    <li
                      key={page}
                      onClick={() => {
                        setPartnerPage(page);
                      }}
                      className={page == partnerPage ? styles.hit : ''}
                    >
                      <p>{page}</p>
                    </li>
                  ))}
              </ul>
              <img
                style={{ transform: isLastPage ? 'rotate(180deg)' : '' }}
                src={isLastPage ? unablePrev : ableNext}
                alt="next"
                onClick={handlePaginationUp}
              />
            </div>
          )} */}
        </div>
      </div>
      <Footer />
      {copyModalOpen && <CopyAlertModal />}
    </>
  );
}

export default RecentPartner;
