import styles from './CopyAlertModal.module.scss';
import { isDesktop } from 'react-device-detect';

function CopyAlertModal() {
  return (
    <div
      className={`${styles.modalContainer} ${isDesktop ? '' : styles.isMobile}`}
    >
      <div className={styles.modalWrap}>복사되었습니다.</div>
    </div>
  );
}

export default CopyAlertModal;
