import styles from './CompanyInfo.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import MypageSideMenu, {
  IMypageType,
} from '../../components/MypageSideMenu/MypageSideMenu';
import plus from '../../assets/companyInfo/plus.png';
import minus from '../../assets/companyInfo/minus.png';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveFile } from '../../actions';
import { useSelector } from 'react-redux';
import { Certificate } from 'crypto';
import { isDesktop } from 'react-device-detect';
import { loginCheck } from '../../auth/auth';

function CompanyInfo() {
  let updateList: string[] = [];
  const file = useSelector((state: any) => state.file);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const [info, setInfo] = useState<IMypageType>();
  const [introduce1, setIntroduce1] = useState('');
  const [introduce2, setIntroduce2] = useState('');
  const [introduce3, setIntroduce3] = useState('');
  const [certFile, setCertFile] = useState<any>('');
  const [firstOpen, setFirstOpen] = useState(true);
  const [secondOpen, setSecondOpen] = useState(true);
  const [thirdOpen, setThirdOpen] = useState(true);
  const [fourOpen, setFourOpen] = useState(true);
  const [imageSrc, setImageSrc] = useState<string[]>([]);
  const [imageBinary, setImageBinary] = useState<any[]>([]);

  useEffect(() => {
    if (!loginCheck()) return navigate('/login');
    if (navigationType !== 'POP') sessionStorage.removeItem('saveInfo');
    getMypage();
  }, []);

  const getMypage = () => {
    axiosClient
      .get(`/auth/member-manufacture/mypage`)
      .then((res) => {
        setInfo(res.data);
        setImageBinary([]);
        setImageSrc([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (info === undefined) return;
    const saveInfo = JSON.parse(
      sessionStorage.getItem('saveInfo') || JSON.stringify(''),
    );

    if (saveInfo && navigationType === 'POP') {
      setIntroduce1(saveInfo.introduce1);
      setIntroduce2(saveInfo.introduce2);
      setIntroduce3(saveInfo.introduce3);
      if (saveInfo.certFile && saveInfo.certFile[0])
        setCertFile(saveInfo.certFile);
      else setCertFile(file);
      sessionStorage.removeItem('saveInfo');
    } else {
      setIntroduce1(info.introduce1);
      setIntroduce2(info.introduce2);
      setIntroduce3(info.introduce3);
      setCertFile(info.company_cert_file);
    }
  }, [info]);

  function encodeFileToBase64(file: any): any {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsDataURL(file);
    });
  }

  const saveInfo = () => {
    const infomation: any = {};
    infomation.introduce1 = introduce1;
    infomation.introduce2 = introduce2;
    infomation.introduce3 = introduce3;
    if (imageBinary.length) {
      infomation.file1 = imageBinary[0].name;
      infomation.file2 = imageBinary[0].size;
      infomation.file3 = imageBinary[0].type;
      dispatch(saveFile(imageBinary[0]));
    } else {
      infomation.certFile = certFile;
    }
    sessionStorage.setItem('saveInfo', JSON.stringify(infomation));
  };

  function isFile(obj: any): obj is File {
    return obj instanceof File;
  }

  const onPreview = async () => {
    if (!info) return;
    saveInfo();

    let certFileEncode: any = [];

    if (isFile(certFile)) {
      await encodeFileToBase64(certFile)
        .then((value: any) => {
          certFileEncode = [value];
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    const data = {
      page: '소개',
      introduce1: introduce1,
      introduce2: introduce2,
      introduce3: introduce3,
      company_cert_file: imageSrc.length
        ? imageSrc
        : certFileEncode
        ? certFileEncode
        : certFile,
      // company_cert_file: certFile,
    };
    navigate(`/preview/${info.id}`, { state: data });
  };

  const onCancel = () => {
    if (!info) return;
    setIntroduce1(info.introduce1);
    setIntroduce2(info.introduce2);
    setIntroduce3(info.introduce3);
    if (certFile || typeof imageBinary[0] === 'object') {
      setCertFile(info.company_cert_file);
      setImageBinary([]);
    }
  };

  const onSubmit = () => {
    if (introduce1 === null || !introduce1.length)
      return alert('회사소개글을 작성해 주세요.');

    let formData = new FormData();
    let imageList = [...imageBinary];

    if (certFile && navigationType === 'POP') imageList = [certFile];

    if (introduce1 !== info?.introduce1) updateList.push('introduce1');
    if (introduce2 !== info?.introduce2) updateList.push('introduce2');
    if (introduce3 !== info?.introduce3) updateList.push('introduce3');
    if (certFile !== info?.company_cert_file) updateList.push('certFile');
    if (
      typeof imageList[0] === 'object' &&
      imageList[0] !== info?.company_cert_file
    )
      updateList.push('certFile');
    if (updateList.length === 0) return alert('수정된 정보가 없습니다.');

    formData.append('introduce1', introduce1);
    if (introduce2 !== null) formData.append('introduce2', introduce2);
    if (introduce3 !== null) formData.append('introduce3', introduce3);
    if (typeof imageList[0] === 'object')
      formData.append('company_cert_file', imageList[0]);

    axiosClient
      .post(`/auth/member-manufacture/mypage/introduce`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        alert(res.data.msg);
        getMypage();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <MypageSideMenu />
        <div className={styles.mainContentWrap}>
          <p>회사 소개</p>
          <div className={styles.companyInfoFrame}>
            <div
              className={`${styles.infoTitle} ${firstOpen ? '' : styles.close}`}
            >
              <div className={styles.title}>
                <span>회사소개글</span>
                <span>*</span>
              </div>
              <img
                src={firstOpen ? minus : plus}
                alt="minus"
                onClick={() => setFirstOpen((prev) => !prev)}
              />
            </div>
            {firstOpen && (
              <textarea
                className={styles.infoInput}
                value={introduce1 || ''}
                placeholder="회사의 장점이 보이는 소개글을 작성해 주세요."
                onChange={(e) => {
                  setIntroduce1(e.currentTarget.value);
                }}
              ></textarea>
            )}
          </div>
          <div className={styles.companyInfoFrame}>
            <div
              className={`${styles.infoTitle} ${
                secondOpen ? '' : styles.close
              }`}
            >
              <div className={styles.title}>
                <span>경영이념(선택)</span>
                <span></span>
              </div>
              <img
                src={secondOpen ? minus : plus}
                alt="minus"
                onClick={() => setSecondOpen((prev) => !prev)}
              />
            </div>
            {secondOpen && (
              <textarea
                className={styles.infoInput}
                value={introduce2 || ''}
                placeholder="경영이념을 작성해 주세요."
                onChange={(e) => {
                  setIntroduce2(e.currentTarget.value);
                }}
              ></textarea>
            )}
          </div>
          <div className={styles.companyInfoFrame}>
            <div
              className={`${styles.infoTitle} ${thirdOpen ? '' : styles.close}`}
            >
              <div className={styles.title}>
                <span>생산설비(선택)</span>
                <span></span>
              </div>
              <img
                src={thirdOpen ? minus : plus}
                alt="minus"
                onClick={() => setThirdOpen((prev) => !prev)}
              />
            </div>
            {thirdOpen && (
              <textarea
                className={styles.infoInput}
                value={introduce3 || ''}
                placeholder="생산설비를 작성해 주세요."
                onChange={(e) => {
                  setIntroduce3(e.currentTarget.value);
                }}
              ></textarea>
            )}
          </div>
          <div className={styles.companyInfoFrame}>
            <div className={styles.certificationTitle}>
              <div className={styles.title}>
                <span>보유인증서(선택)</span>
                <span></span>
              </div>
              <img
                src={fourOpen ? minus : plus}
                alt="minus"
                onClick={() => setFourOpen((prev) => !prev)}
              />
            </div>
            {fourOpen && (
              <div>
                <div className={styles.certificationFileWrap}>
                  {imageBinary
                    .filter((src) => src !== 'deleted')
                    .map((image, idx) => (
                      <input
                        key={idx}
                        type="text"
                        disabled
                        value={image.name}
                      />
                    ))}
                  {certFile && !imageBinary.length && (
                    <a href={certFile}>
                      {certFile && typeof certFile === 'object'
                        ? certFile.name
                        : certFile
                        ? (certFile + '').split('/').pop()
                        : ''}
                      {/* {certFile.split('/').pop()} */}
                    </a>
                  )}
                  {!certFile && !imageBinary.length && (
                    <input
                      type="text"
                      disabled
                      placeholder="파일을 선택해 주세요."
                    />
                  )}
                  <div className={styles.fileBtnWrap}>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .pdf"
                      // multiple
                      onChange={(e: any) => {
                        let maxSize = 20 * 1024 * 1024;
                        let files: any = e.currentTarget.files;
                        if (!files!.length) return;
                        const file = files[0];
                        if (file.size > maxSize)
                          return alert(
                            '파일첨부 사이즈는 20MB 이내로 가능합니다.',
                          );
                        encodeFileToBase64(file)
                          .then((value: any) => {
                            setImageBinary([file]);
                            setImageSrc([value]);
                            setCertFile(file);
                          })
                          .catch((err: any) => {
                            console.log(err);
                          });
                      }}
                    />
                    <div className={styles.fileBtn}>
                      <span>파일선택</span>
                    </div>
                  </div>
                </div>
                {/* <p className={styles.fileNotice}>
                  *JPG,JPEG,PNG형식의 이미지만 등록할 수 있습니다.
                </p> */}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${styles.bottomBtnWrap} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <div className={styles.btns}>
          <div className={styles.previewBtn} onClick={onPreview}>
            미리보기
          </div>
          <div className={styles.saveBtn} onClick={onSubmit}>
            저장하기
          </div>
          <div className={styles.cancelBtn} onClick={onCancel}>
            취소
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CompanyInfo;
