import { useEffect, useRef, useState } from 'react';
import styles from './ProductOptionModal.module.scss';
import ProductEditModal from '../../components/ProductEditModal/ProductEditModal';
import axiosClient from '../../libs/axiosClient';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';

function ProductOptionModal(props: any) {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const [productEditOpen, setProductEditOpen] = useState(false);
  const [productEditData, setProductEditData] = useState<any>();

  const handleRemove = () => {
    axiosClient
      .post('/member-manufacture-product/delete', {
        member_manufacture_product_id: props.productEdit.id,
      })
      .then((res) => {
        alert('제품이 삭제되었습니다.');
        props.closer(false);
        window.location.reload();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleOutClick = (event: any) => {
    if (!modalRef.current || productEditOpen) return;
    if (modalRef && !modalRef.current.contains(event.target))
      props.closer(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutClick);

    return () => {
      document.removeEventListener('mousedown', handleOutClick);
    };
  }, [modalRef, productEditOpen]);

  return (
    <div
      className={`${styles.productOptionModal} ${
        isDesktop ? '' : styles.isMobile
      }`}
    >
      <div ref={modalRef} className={styles.productOptionContent}>
        <div
          onClick={() => {
            setProductEditOpen(true);
            setProductEditData(props.productEdit);
          }}
        >
          <p>게시글 수정</p>
        </div>
        <div onClick={handleRemove}>
          <p className={styles.remove}>삭제</p>
        </div>
        <div onClick={() => props.closer(false)}>
          <p>닫기</p>
        </div>
      </div>
      {productEditOpen && (
        <ProductEditModal
          closer={() => {
            props.closer(false);
            setProductEditOpen(false);
          }}
          productEdit={productEditData}
        />
      )}
    </div>
  );
}
export default ProductOptionModal;
