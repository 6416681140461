import styles from './JoinClient.module.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import drop from '../../assets/join/drop.png';
import cancel from '../../assets/mypage/cancel.png';
import axiosClient from '../../libs/axiosClient';
import { setToken } from '../../auth/auth';
import { useNavigate } from 'react-router-dom';
import JoinClientSuccess from '../../components/JoinClientSuccess/JoinClientSuccess';
import { isDesktop } from 'react-device-detect';
import JoinAgreement from '../../components/JoinAgreement/JoinAgreement';

function JoinClient() {
  const navigate = useNavigate();
  const idRegex = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{4,30}$/;
  var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,30}$/;
  const nameRegex = /^[가-힣a-zA-Z ]+$/;
  const emailRegex =
    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const [emailDirectly, setEmailDirectly] = useState(false);
  const [memo, setMemo] = useState('');
  const [joinSuccessOpen, setJoinSuccessOpen] = useState(false);
  const [selectText, setSelectText] = useState('선택해주세요.');

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) navigate(-1);
  }, []);

  const handleIdCheck = () => {
    if (!idRegex.test(watch().id)) {
      alert('아이디는 영문, 숫자를 포함한 4자 이상 30자 이하만 가능합니다.');
      return;
    }

    axiosClient
      .post(`/auth/check/login_id`, {
        login_id: watch().id,
      })
      .then((res) => {
        if (res.status === 200) {
          setValue('idCheck', true);
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) alert(err.response.data.login_id);
      });
  };

  const handleEmailCheck = () => {
    if (!emailRegex.test(watch().email1 + '@' + watch().email2)) {
      alert('올바르지 않은 이메일 형식입니다.');
      return;
    }

    axiosClient
      .post(`/auth/check/email`, {
        email: watch().email1 + '@' + watch().email2,
      })
      .then((res) => {
        if (res.status === 200) {
          setValue('emailCheck', true);
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) alert(err.response.data.email);
      });
  };

  const onSubmit = (data: any) => {
    let formData = new FormData();
    formData.append('login_id', data.id);
    formData.append('login_pw', data.pw);
    formData.append('login_pw_confirm', data.pwCheck);
    formData.append('name', data.name);
    formData.append('memo', memo);
    formData.append('hp', data.phone1 + '-' + data.phone2 + '-' + data.phone3);
    formData.append('email', data.email1 + '@' + data.email2);
    formData.append('agree_terms', '1');
    formData.append('agree_privacy', '1');

    axiosClient
      .post(`/auth/member-client/register`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setJoinSuccessOpen(true);
        setToken(res.data);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data.hp) alert('이미 등록된 연락처입니다.');
        }
      });
  };

  const onError = (error: any) => {
    let errorList: { message: string; ref: HTMLElement }[] =
      Object.values(error);
    alert(errorList[0].message);
    window.scrollTo(0, errorList[0].ref.offsetTop - 150);
    errorList[0].ref.focus();
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <p>회원가입</p>
        <form
          className={styles.joinFormWrap}
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>아이디</span>
              <span>*</span>
            </div>
            <div className={styles.idInputWrap}>
              <input
                type="text"
                placeholder="영문, 숫자 4자 이상 아이디를 입력해 주세요."
                {...register('id', {
                  value: '',
                  required: {
                    value: true,
                    message: '아이디를 입력해 주세요.',
                  },
                  pattern: {
                    value: idRegex,
                    message: '아이디 형식이 올바르지 않습니다',
                  },
                  onChange: () => {
                    setValue('idCheck', false);
                  },
                })}
              />
              <div
                className={styles.duplicateBtnWrap}
                onClick={handleIdCheck}
                {...register('idCheck', {
                  value: false,
                  validate: () =>
                    watch().idCheck !== true
                      ? '아이디 중복확인을 해주세요.'
                      : true,
                })}
              >
                중복확인
              </div>
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>비밀번호</span>
              <span>*</span>
            </div>
            <input
              type="password"
              placeholder="비밀번호를 입력해 주세요."
              autoComplete="off"
              {...register('pw', {
                value: '',
                required: '비밀번호를 입력해 주세요.',
                pattern: {
                  value: passwordRegex,
                  message:
                    '비밀번호는 영문, 숫자, 특수기호(필수x)를 사용하여 8자 이상 30자 이내만 가능합니다.',
                },
              })}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>비밀번호 확인</span>
              <span>*</span>
            </div>
            <input
              type="password"
              placeholder="비밀번호를 재입력해 주세요."
              autoComplete="off"
              {...register('pwCheck', {
                value: '',
                required: '비밀번호 확인을 입력해 주세요.',
                validate: () =>
                  watch().pw !== watch().pwCheck
                    ? '비밀번호가 일치하지 않습니다.'
                    : true,
              })}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>이름</span>
              <span>*</span>
            </div>
            <input
              type="text"
              placeholder="이름을 입력해 주세요."
              {...register('name', {
                value: '',
                required: '이름을 입력해 주세요.',
                pattern: {
                  value: nameRegex,
                  message: '이름 형식이 올바르지 않습니다.',
                },
              })}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>연락처</span>
              <span>*</span>
            </div>
            <div className={styles.phoneInputWrap}>
              <input
                type="text"
                maxLength={3}
                placeholder="연락처를 입력해 주세요."
                {...register('phone1', {
                  required: '연락처 첫 부분은 3자리여야 합니다.',
                  minLength: 3,
                  maxLength: 3,
                })}
              />
              <span>-</span>
              <input
                type="text"
                maxLength={4}
                {...register('phone2', {
                  required: '연락처 두번째 부분은 3-4자리여야 합니다.',
                  minLength: 3,
                  maxLength: 4,
                })}
              />
              <span>-</span>
              <input
                type="text"
                maxLength={4}
                {...register('phone3', {
                  required: '연락처 세번째 부분은 4자리여야 합니다.',
                  minLength: 4,
                  maxLength: 4,
                })}
              />
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>이메일</span>
              <span>*</span>
            </div>
            <div className={styles.emailInputWrap}>
              <div className={styles.left}>
                <input
                  type="text"
                  placeholder="이메일을 입력해 주세요."
                  {...register('email1', {
                    value: '',
                    required: '이메일을 입력해 주세요.',
                    onChange: () => {
                      setValue('emailCheck', false);
                    },
                  })}
                />
                <span>@</span>
                <div className={styles.emailSelectWrap}>
                  <div className={styles.customSelect}>
                    <input
                      type="text"
                      placeholder="직접입력"
                      {...register('email2', {
                        value: '',
                        onChange: (e) => {
                          setValue('emailCheck', false);
                        },
                      })}
                    />
                    <select
                      defaultValue="default"
                      {...register('emailSelect', {
                        onChange: (e) => {
                          setSelectText(e.currentTarget.value);
                          if (e.currentTarget.value == 'directly') {
                            setEmailDirectly(true);
                            setValue('email2', '');
                          } else setValue('email2', e.currentTarget.value);
                          setValue('emailCheck', false);
                        },
                      })}
                      style={{ display: emailDirectly ? 'none' : '' }}
                      className={`${styles.selectText} ${
                        selectText === '선택해주세요.' ? '' : styles.hit
                      }`}
                    >
                      <option disabled value="default">
                        선택해주세요.
                      </option>
                      <option value="naver.com">naver.com</option>
                      <option value="hanmail.net">hanmail.net</option>
                      <option value="daum.net">daum.net</option>
                      <option value="gmail.com">gmail.com</option>
                      <option value="nate.com">nate.com</option>
                      <option value="hotmail.com">hotmail.com</option>
                      <option value="outlook.com">outlook.com</option>
                      <option value="icloud.com">icloud.com</option>
                      <option value="directly">직접입력</option>
                    </select>
                    <img
                      src={drop}
                      alt="selectOpen"
                      style={{
                        display: emailDirectly ? 'none' : '',
                        pointerEvents: 'none',
                      }}
                    />
                    <img
                      src={cancel}
                      alt="cancel"
                      style={{ display: !emailDirectly ? 'none' : '' }}
                      onClick={() => {
                        setEmailDirectly(false);
                        setValue('emailCheck', false);
                        setValue('email2', '');
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={styles.duplicateBtnWrap}
                onClick={handleEmailCheck}
                {...register('emailCheck', {
                  value: false,
                  validate: () =>
                    watch().emailCheck !== true
                      ? '이메일 중복 확인을 해주세요.'
                      : true,
                })}
              >
                중복확인
              </div>
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>메모(선택)</span>
            </div>
            <input
              type="text"
              placeholder="메모를 입력해 주세요."
              value={memo}
              onChange={(e) => setMemo(e.currentTarget.value)}
            />
          </div>
          <div className={styles.agreementFrame}>
            <div className={styles.title}>
              <span>회원가입약관</span>
              <span>*</span>
            </div>
            <div className={styles.agreementWrap}>
              <JoinAgreement type={1} />
            </div>
            <div
              className={styles.customCheckbox}
              {...register('agreementTerms', {
                required: '회원가입약관을 동의해 주세요.',
              })}
            >
              <input
                type="checkbox"
                id="agree1"
                onClick={() => {
                  setValue('agreementTerms', !watch().agreementTerms);
                }}
              />
              <label htmlFor="agree1">
                <span>동의합니다.</span>
              </label>
            </div>
          </div>
          <div className={styles.agreementFrame}>
            <div className={styles.title}>
              <span>개인정보취급방침</span>
              <span>*</span>
            </div>
            <div className={styles.agreementWrap}>
              <JoinAgreement type={2} />
            </div>
            <div
              className={styles.customCheckbox}
              {...register('agreementPrivacy', {
                required: '개인정보취급방침을 동의해 주세요.',
              })}
            >
              <input
                type="checkbox"
                id="agree2"
                onClick={() => {
                  setValue('agreementPrivacy', !watch().agreementPrivacy);
                }}
              />
              <label htmlFor="agree2">
                <span>동의합니다.</span>
              </label>
            </div>
          </div>
          <button className={styles.joinBtnWrap}>회원가입</button>
        </form>
      </div>
      <Footer />
      {joinSuccessOpen && <JoinClientSuccess closer={setJoinSuccessOpen} />}
    </>
  );
}
export default JoinClient;
