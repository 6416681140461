// reducers/fileReducer.ts
const initialState = {
    file: null as File | null
  };
  
  const fileReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'SAVE_FILE':
        return {
          ...state,
          file: action.payload
        };
      default:
        return state;
    }
  };
  
  export default fileReducer;
  