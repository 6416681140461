import Header from '../../components/Header/Header';
import styles from './PartnerDetail.module.scss';
import company from '../../assets/partnerDetail/company.png';
import copy from '../../assets/partnerDetail/copy.png';
import prev from '../../assets/partnerDetail/prev.png';
import heart from '../../assets/partnerList/heart.png';
import filtercheck from '../../assets/partnerDetail/filtercheck.png';
import heartblack from '../../assets/partnerList/heartblack.png';
import share from '../../assets/partnerList/share.png';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Footer from '../../components/Footer/Footer';
import axiosClient from '../../libs/axiosClient';
import { useNavigate, useParams } from 'react-router-dom';
import unablePrev from '../../assets/partnerList/unablePrev.png';
import ableNext from '../../assets/partnerList/ableNext.png';
import nocompany from '../../assets/partnerList/nocompany.png';
import { loginConfirm } from '../../common/loginConfirm';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import ScrollUp from '../../components/ScrollUp/ScrollUp';
import { isDesktop, isMobile } from 'react-device-detect';
import PartnerDetailInfo from '../../components/PartnerDetailInfo/PartnerDetailInfo';

export interface ICompanyDetailType {
  id: string;
  login_id: string;
  name: string;
  addr: string;
  ceo_name: string;
  since_date: string;
  member_quantity: any;
  company_cert_file: string;
  category_name: string;
  business_partner: string;
  homepage: string;
  hp: string;
  fax: string;
  email: string;
  manager_name: string;
  manager_hp: string;
  memo: string;
  is_bookmark: number;
  is_cert: string;
  product_name: string;
  product_category: string;
  product: {
    count: string;
    data: {
      created_at: string;
      id: string;
      name: string;
      material: string;
      material_is_custom: string;
      product_code: string;
      standard_width: string;
      standard_length: string;
      standard_is_custom: string;
      tag: string;
      updated_at: string;
      volume: string;
      volume_unit: string;
    };
  };
  total_bookmark: string;
  total_memver_quantity: string;
  img: any;
  introduce1: string;
  introduce2: string;
  introduce3: string;
}

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PartnerDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const canvasRef = useRef(null);
  const [wishCount, setWishCount] = useState(6123);
  const [isWish, setIsWish] = useState(false);
  const [companyDate, setCompanyDate] = useState('2024-02-05T13:35:22');
  const [naviMenu, setNaviMenu] = useState('회사소개');
  const [productFilter, setProductFilter] = useState('최근등록순');
  const [currentCompanyImg, setCurrentCompanyImg] = useState();
  const [imgListCount, setImgListCount] = useState(isDesktop ? 4 : 2);
  const [companyImgList, setCompanyImgList] = useState([]);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [partnerPageList, setPartnerPageList] = useState([1]);
  const [partnerPage, setPartnerPage] = useState(1);
  const [allPartnerCount, setAllPartnerCount] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [filterMenu, setFilterMenu] = useState([
    '최근등록순',
    '용량높은순',
    '용량낮은순',
  ]);
  const [companyProduct, setCompanyProduct] = useState<ICompanyDetailType[]>(
    [],
  );
  const [companyDetail, setCompanyDetail] = useState<ICompanyDetailType>();
  const [isPdf, setIsPdf] = useState(false);
  useEffect(() => {
    getCompany();
    getProduct();
    postRecent();
    const canvas: any = canvasRef.current;
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas: any = canvasRef.current;
      canvas.getContext('2d', { willReadFrequently: true });
      // canvas.getContext('2d').willReadFrequently = true;
    }
  }, [canvasRef]);

  useEffect(() => {
    setCurrentCompanyImg(companyImgList[0]);
  }, [companyImgList]);

  const getCompany = async () => {
    await axiosClient
      .get(`/member-manufacture/${params.partnerId}`, {
        headers: {
          'Access-Control-Allow-Origin': 'http://43.201.253.201/api',
        },
      })
      .then((res) => {
        setCompanyDetail(res.data);
        setCompanyImgList(res.data.img.data);
        const cert = res.data.company_cert_file;
        if (cert === null || cert === '' || cert === undefined) return;
        if (cert.slice(-3) === 'pdf') setIsPdf(true);
      })
      .catch((err) => {
        if (
          err.response.data.message ===
          'Call to a member function toArray() on null'
        ) {
          alert('잘못된 경로 입니다.');
          return navigate('/partner');
        }
      });
  };

  // const pdfDounload = async () => {
  //   const response = await axiosClient
  //     .get(`/member-manufacture/${params.partnerId}`, {
  //       responseType: 'blob',
  //     })
  //     .then((res) => {
  //       return res.data.company_cert_file;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   const downloadUrl = window.URL.createObjectURL(new Blob([response]));
  //   console.log(downloadUrl);
  //   const link = document.createElement('a');
  //   link.href = downloadUrl;
  //   link.style.display = 'none';
  //   link.setAttribute('download', '성공.pdf');
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // };

  const getProduct = async () => {
    await axiosClient
      .get(`/member-manufacture-product/${params.partnerId}`)
      .then((res) => {
        return setCompanyProduct(
          res.data[0].data
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime(),
            ),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postRecent = async () => {
    await axiosClient
      .post(
        `/recent`,
        {
          member_manufacture_id: params.partnerId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (!window.visualViewport) return;
    setWidth(window.visualViewport?.width - 42);
  }, []);

  const certArray = [];
  for (let i = 1; i <= numPages; i++) {
    certArray.push(
      <Page
        key={i}
        width={isDesktop ? 800 : width}
        pageNumber={i}
        renderTextLayer={false}
        canvasRef={canvasRef}
      />,
    );
  }

  function handlePaginationDown() {
    if (partnerPage == 1) return;
    setPartnerPage((prev) => prev - 1);
  }

  function handlePaginationUp() {
    if (partnerPage >= allPartnerCount / 15) return;
    setPartnerPage((prev) => prev + 1);
  }

  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt((partnerPage / 5).toString()) * 5 + 1;
    if (!(partnerPage % 5)) startPage -= 5;
    let endPage =
      startPage + 4 <
      allPartnerCount / 15 + (allPartnerCount % 15 !== 0 ? 1 : 0)
        ? startPage + 4
        : allPartnerCount / 15 + (allPartnerCount % 15 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    let lastPage = partnerPage === Math.ceil(allPartnerCount / 15);
    if (allPartnerCount === 0) lastPage = true;
    setIsLastPage(lastPage);
    setPartnerPageList([...list]);
  }, [allPartnerCount, partnerPage]);

  const [swiper, setSwiper] = useState<SwiperCore>();
  const movingBarRef = useRef<HTMLDivElement>(null);
  const tabMenuRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!tabMenuRef.current || !movingBarRef.current) return;
    movingBarRef.current.style.left = tabMenuRef.current.offsetLeft + 'px';
    movingBarRef.current.style.width = tabMenuRef.current.offsetWidth + 'px';
  }, [tabMenuRef.current, movingBarRef.current]);

  function handleNavigationBarMove(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) {
    if (!movingBarRef.current) return;
    movingBarRef.current.style.left = e.currentTarget.offsetLeft + 'px';
    movingBarRef.current.style.width = e.currentTarget.offsetWidth + 'px';
  }

  const handleFilter = (filter: string) => {
    setProductFilter(filter);
    switch (filter) {
      case '용량높은순':
        return setCompanyProduct(
          companyProduct
            .slice()
            .sort((a: any, b: any) => parseInt(b.volume) - parseInt(a.volume)),
        );
      case '용량낮은순':
        return setCompanyProduct(
          companyProduct
            .slice()
            .sort((a: any, b: any) => parseInt(a.volume) - parseInt(b.volume)),
        );
      case '최근등록순':
        return setCompanyProduct(
          companyProduct
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime(),
            ),
        );
      default:
        return filterMenu;
    }
  };

  const formatNumber = (num: string) => {
    const number = Number(num);
    const formattedNumber =
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(1);
    return formattedNumber;
  };

  const needLogin = () => {
    return loginConfirm();
  };

  const handleWish = () => {
    if (!companyDetail) return;
    if (!needLogin()) return;
    let bookmark = 0;
    if (companyDetail.is_bookmark === 0) {
      bookmark = 1;
      setCompanyDetail((prev) => ({ ...prev!, is_bookmark: 1 }));
    } else {
      bookmark = 0;
      setCompanyDetail((prev) => ({ ...prev!, is_bookmark: 0 }));
    }

    let formData = new FormData();
    formData.append('member_manufacture_id', companyDetail.id);
    formData.append('bookmark', bookmark.toString());
    axiosClient
      .post(`/bookmark`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        getCompany();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.mainContentWrap} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <div className={styles.leftWrap}>
          <img
            className={`${styles.thumbnail} ${
              companyImgList.length > 2 ? styles.listMargin : ''
            }`}
            src={currentCompanyImg ? currentCompanyImg : nocompany}
            alt="company"
          />

          <div className={styles.companyImgListWrap}>
            <Swiper
              className={styles.companyImgSwiper}
              slidesPerView={isDesktop ? 4 : 2}
              spaceBetween={8}
              onSwiper={setSwiper}
            >
              {companyImgList && companyImgList.length > imgListCount && (
                <div className={styles.companyPagination}>
                  <div
                    className={styles.leftSwiper}
                    onClick={() => swiper?.slidePrev()}
                  >
                    <img src={prev} alt="prev" />
                  </div>
                  <div
                    className={styles.rightSwiper}
                    onClick={() => swiper?.slideNext()}
                  >
                    <img src={prev} alt="prev" />
                  </div>
                </div>
              )}
              {companyImgList &&
                companyImgList.map((img: any, idx: any) => (
                  <SwiperSlide className={styles.imgListSwiperWrap} key={idx}>
                    <img
                      className={`${styles.currentImgWrap} ${
                        currentCompanyImg === img ? styles.current : ''
                      }`}
                      onClick={() => setCurrentCompanyImg(img)}
                      src={img ? img : nocompany}
                      alt="company"
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          {isMobile && companyDetail && (
            <PartnerDetailInfo companyDetail={companyDetail} />
          )}
          <div className={styles.companyInfoNavigationWrap}>
            <ul>
              <li
                ref={tabMenuRef}
                className={naviMenu === '회사소개' ? styles.hit : styles.noHit}
                onClick={(e) => {
                  handleNavigationBarMove(e);
                  setNaviMenu('회사소개');
                }}
              >
                <span>회사소개</span>
              </li>
              <li
                className={naviMenu === '생산제품' ? styles.hit : styles.noHit}
                onClick={(e) => {
                  handleNavigationBarMove(e);
                  setNaviMenu('생산제품');
                }}
              >
                <span>생산제품</span>
              </li>
              <div ref={movingBarRef} className={styles.movingBar}></div>
            </ul>
          </div>
          {/* {naviMenu === '회사소개' && (
            <div className={styles.companyIntroWrap}>
              {companyIntro &&
                companyIntro.map((intro, idx) => (
                  <div className={styles.companyIntroFrame} key={idx}>
                    <p className={styles.companyIntroTitle}>{intro.title}</p>
                    <p>{intro.content}</p>
                  </div>
                ))}
            </div>
          )} */}
          {/* {naviMenu === '회사소개' &&
            companyIntro &&
            companyIntro.map((intro, idx) => (
              <div key={idx} className={styles.companyIntroWrap}>
                <p className={styles.companyIntroTitle}>{intro.title}</p>
                {intro.content.split('.').map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            ))} */}

          {naviMenu === '회사소개' && companyDetail && (
            <>
              <div className={styles.companyIntroWrap}>
                <p className={styles.companyIntroTitle}>회사소개글</p>
                {companyDetail.introduce1 &&
                  companyDetail.introduce1.split('\n').map((line, idx) => (
                    <p key={idx}>
                      {line}
                      <br />
                    </p>
                  ))}
              </div>
              {companyDetail.introduce2 && (
                <div className={styles.companyIntroWrap}>
                  <p className={styles.companyIntroTitle}>경영이념</p>
                  {companyDetail.introduce2.split('\n').map((line, idx) => (
                    <p key={idx}>
                      {line}
                      <br />
                    </p>
                  ))}
                </div>
              )}
              {companyDetail.introduce3 && (
                <div className={styles.companyIntroWrap}>
                  <p className={styles.companyIntroTitle}>생산설비</p>
                  {companyDetail.introduce3.split('\n').map((line, idx) => (
                    <p key={idx}>
                      {line}
                      <br />
                    </p>
                  ))}
                </div>
              )}
              {companyDetail.company_cert_file && (
                <div className={styles.companyIntroWrap}>
                  <p className={styles.companyIntroTitle}>보유인증서</p>
                  {isPdf ? (
                    <Document
                      file={companyDetail?.company_cert_file}
                      onLoadSuccess={onDocumentLoadSuccess}
                      // onClick={() => {
                      //   window.open(companyDetail?.company_cert_file);
                      // }}
                    >
                      {certArray}
                    </Document>
                  ) : (
                    <img src={companyDetail.company_cert_file} alt="certfile" />
                  )}
                </div>
              )}
            </>
          )}
          {naviMenu === '생산제품' && (
            <div className={styles.companyProductWrap}>
              <div className={styles.companyProductTopWrap}>
                {(companyDetail?.product_name ||
                  companyDetail?.product_category) && (
                  <div className={styles.companyProductTop}>
                    {companyDetail?.product_category &&
                      companyDetail?.product_category}
                    {companyDetail?.product_name &&
                      companyDetail?.product_category &&
                      '>'}
                    {companyDetail?.product_name && companyDetail?.product_name}
                  </div>
                )}
              </div>
              <div className={styles.companyFilterWrap}>
                <div className={styles.left}>
                  <span>생산제품</span>
                  <span>{companyDetail?.product.count}개의 제품</span>
                </div>
                <div className={styles.right}>
                  {filterMenu &&
                    filterMenu.map((filter, idx) => (
                      <div
                        key={idx}
                        className={styles.filterMenuWrap}
                        onClick={() => handleFilter(filter)}
                      >
                        <div className={styles.imgWrap}>
                          {productFilter === filter && (
                            <img src={filtercheck} alt="filtercheck" />
                          )}
                        </div>
                        <p
                          className={`${styles.filterMenu} ${
                            productFilter === filter ? styles.hit : ''
                          }`}
                        >
                          {filter}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
              <div className={styles.companyProductList}>
                {companyProduct.map((product: any, idx) => (
                  <div key={idx} className={styles.companyProductFrame}>
                    <img
                      src={product.img ? product.img : company}
                      alt="product"
                    />
                    <div className={styles.productContentWrap}>
                      <p className={styles.title}>{product.name}</p>
                      <div className={styles.productInfo}>
                        <p>{product.product_code}</p>
                        <p>
                          {product.standard_is_custom === 0
                            ? `${formatNumber(
                                product.standard_width,
                              )}mm*${formatNumber(product.standard_height)}mm*
                        ${formatNumber(product.standard_length)}mm`
                            : '주문제작'}
                        </p>
                        <p>
                          {formatNumber(product.volume)}
                          {product.volume_unit}
                        </p>
                      </div>
                      {product.material !== '' ? (
                        <div className={styles.tagWrap}>
                          <span>{product.material}</span>
                        </div>
                      ) : (
                        <div className={styles.tagWrap}>
                          <span>주문제작</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {naviMenu === '생산제품' && companyProduct.length > 0 && (
            <div className={styles.paginationWrap}>
              <img
                style={{ transform: partnerPage === 1 ? '' : 'rotate(180deg)' }}
                src={partnerPage === 1 ? unablePrev : ableNext}
                alt="prev"
                onClick={handlePaginationDown}
              />
              <ul>
                {partnerPageList &&
                  partnerPageList.map((page) => (
                    <li
                      key={page}
                      onClick={() => {
                        setPartnerPage(page);
                      }}
                      className={page == partnerPage ? styles.hit : ''}
                    >
                      <p>{page}</p>
                    </li>
                  ))}
              </ul>
              <img
                style={{ transform: isLastPage ? 'rotate(180deg)' : '' }}
                src={isLastPage ? unablePrev : ableNext}
                alt="next"
                onClick={handlePaginationUp}
              />
            </div>
          )}
        </div>
        {isDesktop && companyDetail && (
          <PartnerDetailInfo companyDetail={companyDetail} />
        )}
        <ScrollUp />
      </div>
      <Footer />
    </>
  );
}

export default PartnerDetail;
