import styles from './Header.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/header/logo.png';
import chat from '../../assets/header/chat.png';
import mChat from '../../assets/header/mChat.png';
import alert from '../../assets/header/alert.png';
import mAlert from '../../assets/header/mAlert.png';
import { useEffect, useRef, useState } from 'react';
import Alert from '../Alert/Alert';
import { loginCheck, logout } from '../../auth/auth';
import { loginConfirmRurl } from '../../common/loginConfirm';
import { isDesktop } from 'react-device-detect';

function Header({ mainTop }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const alertRef = useRef<any>(null);
  const hAlertRef = useRef<any>(null);
  const [headerMenu, setHeaderMenu] = useState('');
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [userType, setUserType] = useState('');
  const [headerCondition, setHeaderCondition] = useState(false);

  useEffect(() => {
    if (mainTop) {
      if (location.pathname === '/') setHeaderCondition(true);
    } else if (
      location.pathname === '/login' ||
      location.pathname === '/find-account' ||
      location.pathname === '/join'
    )
      setHeaderCondition(true);
    else setHeaderCondition(false);
  }, [mainTop]);

  useEffect(() => {
    if (window.location.pathname === '/partner') setHeaderMenu('파트너 찾기');
    else if (window.location.pathname === '/request-quote')
      setHeaderMenu('견적요청하기');
    else if (window.location.pathname === '/notice') setHeaderMenu('공지사항');
  }, []);

  useEffect(() => {
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  const handleOutClick = (event: any) => {
    if (!alertRef.current || !hAlertRef.current) return;
    if (
      alertRef &&
      !alertRef.current.contains(event.target) &&
      !hAlertRef.current.contains(event.target)
    )
      setNoticeOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutClick);

    return () => {
      document.removeEventListener('mousedown', handleOutClick);
    };
  }, [alertRef]);

  return (
    <div className={styles.container}>
      {!headerCondition && (
        <div
          className={`${styles.heighter} ${isDesktop ? '' : styles.isMobile}`}
        ></div>
      )}
      {isDesktop ? (
        <div
          className={`${styles.headerWrap} ${
            headerCondition ? styles.mainHeaderWrap : ''
          }`}
        >
          <div className={styles.header}>
            <div className={styles.logoWrap}>
              {/* <img
              src={logo}
              alt="logo"
              className={styles.logo}
              onClick={() => navigate('/')}
            /> */}
              <p onClick={() => navigate('/')}>PARTNER</p>
            </div>
            <div className={styles.navigation}>
              <ul>
                <li
                  style={{
                    color:
                      headerMenu === '파트너 찾기' ? 'var(--theme-color)' : '',
                  }}
                  onClick={() => {
                    navigate(`/partner`);
                    window.location.reload();
                  }}
                >
                  <span>파트너 찾기</span>
                </li>
                {userType !== 'member_manufacture' && (
                  <li
                    style={{
                      color:
                        headerMenu === '견적요청하기'
                          ? 'var(--theme-color)'
                          : '',
                    }}
                    onClick={() => {
                      if (!loginConfirmRurl('/request-quote')) return;
                      navigate('/request-quote');
                    }}
                  >
                    <span>견적요청하기</span>
                  </li>
                )}
                <li
                  style={{
                    color:
                      headerMenu === '공지사항' ? 'var(--theme-color)' : '',
                  }}
                  onClick={() => navigate(`/notice`)}
                >
                  <span>공지사항</span>
                </li>
              </ul>
            </div>
            {!loginCheck() && (
              <ul className={styles.btns}>
                <li className={styles.join}>
                  <Link to="/join">회원가입</Link>
                </li>
                <li className={styles.login}>
                  {location.pathname !== '/login' ? (
                    <Link
                      to={`/login?rurl=${location.pathname + location.search}`}
                    >
                      로그인
                    </Link>
                  ) : (
                    <Link to={`${location.pathname + location.search}`}>
                      로그인
                    </Link>
                  )}
                </li>
              </ul>
            )}
            {loginCheck() && (
              <ul className={styles.isLoginBtns}>
                <li className={styles.chatnotice}>
                  <Link to="/chat">채팅</Link>
                </li>
                <li
                  ref={hAlertRef}
                  onClick={() => setNoticeOpen((prev) => !prev)}
                  className={styles.chatnotice}
                  // onMouseOver={() => setNoticeOpen(true)}
                  // onMouseOut={() => setNoticeOpen(false)}
                >
                  <Link
                    to={{
                      pathname: window.location.pathname,
                      search: window.location.search,
                    }}
                    onClick={(e) => e.preventDefault}
                  >
                    알림
                  </Link>
                </li>
                <li className={styles.mypage}>
                  <Link to="/mypage">마이페이지</Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`${styles.mHeaderWrap} ${
            headerCondition ? styles.mainHeaderWrap : ''
          }`}
        >
          <div className={styles.header}>
            <div className={styles.logoWrap}>
              <p onClick={() => navigate('/')}>PARTNER</p>
            </div>
            {loginCheck() && (
              <div className={styles.mMenuWrap}>
                <img
                  onClick={() => {
                    navigate('/chat');
                  }}
                  src={headerCondition ? mChat : chat}
                  alt="alert"
                />
                <img
                  onClick={() => {
                    setNoticeOpen((prev) => !prev);
                  }}
                  src={headerCondition ? mAlert : alert}
                  alt="alert"
                />
              </div>
            )}
          </div>
        </div>
      )}

      {noticeOpen && <Alert closer={setNoticeOpen} alertRef={alertRef} />}
    </div>
  );
}
export default Header;
