import styles from './Login.module.scss';
import kakao from '../../assets/login/kakao.png';
import naver from '../../assets/login/naver.png';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../../libs/axiosClient';
import { setToken } from '../../auth/auth';
import { KAKAO_AUTH_URL } from '../../auth/Kakao';
import { NAVER_AUTH_URL } from '../../auth/Naver';
import CompanyProfileAlert from '../../components/CompanyProfileAlert/CompanyProfileAlert';
import Header from '../../components/Header/Header';
import { isDesktop } from 'react-device-detect';
import { handleRedirectUrlCheck } from '../../common/redirectUrlCheck';

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookies] = useCookies();
  const [companyProfileOpen, setCompanyProfileOpen] = useState(false);
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [isCheck, setIsCheck] = useState(false);
  let redirectURL = new URLSearchParams(location.search).get('rurl');

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) navigate(-1);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // setCompanyProfileOpen(true);

    axiosClient
      .post('/auth/login', {
        login_id: id,
        login_pw: pw,
        login_auto: isCheck ? 1 : 0,
      })
      .then((res) => {
        setToken(res.data);
        if (res.data.user.type === 'member_client') {
          if (handleRedirectUrlCheck(redirectURL || '/'))
            return (window.location.href = redirectURL || '/');
          else return (window.location.href = '/');
        }
        axiosClient
          .get('/auth/member-manufacture/mypage', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
          .then((res) => {
            if (res.data.ceo_name !== null) {
              if (handleRedirectUrlCheck(redirectURL || '/'))
                window.location.href = redirectURL || '/';
              else window.location.href = '/';
            } else if (res.data.ceo_name === null && cookies['today-close']) {
              if (handleRedirectUrlCheck(redirectURL || '/'))
                window.location.href = redirectURL || '/';
              else window.location.href = '/';
            } else if (res.data.ceo_name === null && !cookies['today-close'])
              setCompanyProfileOpen(true);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) alert(err.response.data.msg);
      });
  };

  const handleAutoLogin = (e: any) => {
    setIsCheck(e.target.checked);
  };

  return (
    <>
      {isDesktop && <Header />}
      <div
        className={`${styles.container} ${isDesktop ? '' : styles.isMobile}`}
      >
        <div className={styles.contentWrap}>
          <div className={styles.logoWrap}>PARTNER</div>
          <form className={styles.loginForm} onSubmit={(e) => handleSubmit(e)}>
            <p>로그인</p>
            <div className={styles.signupWrap}>
              <span>신규 사용자이신가요?</span>
              <span onClick={() => navigate('/join')}>회원가입</span>
            </div>
            <div className={styles.loginInputWrap}>
              <input
                type="text"
                placeholder="아이디를 입력해 주세요."
                value={id}
                onChange={(e) => setId(e.currentTarget.value)}
                onBlur={() => setId((prev) => prev.trim())}
              />
              <input
                type="password"
                placeholder="비밀번호를 입력해 주세요."
                value={pw}
                onChange={(e) => setPw(e.currentTarget.value)}
                onBlur={() => setPw((prev) => prev.trim())}
              />
              {/* <div className={styles.autoLoginWrap}>
              <input type="checkbox" />
              <span>자동로그인</span>
            </div> */}
              <div className={styles.customCheckbox}>
                <input
                  type="checkbox"
                  id="auto"
                  onChange={(e) => handleAutoLogin(e)}
                />
                <label htmlFor="auto">
                  <span>자동로그인</span>
                </label>
              </div>
            </div>
            <button className={styles.loginBtnWrap}>로그인</button>
            <div className={styles.findWrap}>
              <div onClick={() => navigate('/find-account?tab=0')}>
                아이디찾기
              </div>
              <div onClick={() => navigate('/find-account?tab=1')}>
                비밀번호찾기
              </div>
            </div>
            <div className={styles.or}>
              <div></div>
              <span>또는</span>
              <div></div>
            </div>
            <div className={styles.snsLoginBtnWrap}>
              <div
                className={styles.kakaoBtnWrap}
                onClick={() => {
                  localStorage.setItem('rurl', redirectURL || '/');
                  window.location.href = KAKAO_AUTH_URL;
                  return;
                }}
              >
                <img src={kakao} alt="kakao" />
                <span>카카오로 로그인</span>
              </div>
              <div
                className={styles.naverBtnWrap}
                onClick={() => {
                  localStorage.setItem('rurl', redirectURL || '/');
                  window.location.href = NAVER_AUTH_URL;
                  return;
                }}
              >
                <img src={naver} alt="naver" />
                <span>네이버로 로그인</span>
              </div>
            </div>
          </form>
        </div>
        {companyProfileOpen && (
          <CompanyProfileAlert closer={setCompanyProfileOpen} />
        )}
      </div>
    </>
  );
}

export default Login;
