import styles from './CompanyProfile.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MypageSideMenu, {
  IMypageType,
} from '../../components/MypageSideMenu/MypageSideMenu';
import company from '../../assets/partnerDetail/company.png';
import add from '../../assets/mypage/add.png';
import firstRemove from '../../assets/mypage/firstRemove.png';
import remove from '../../assets/mypage/remove.png';
import { useEffect, useRef, useState } from 'react';
import { CheckboxOption } from '../Partner/Partner';
import { useNavigate, useNavigationType } from 'react-router-dom';
import drop from '../../assets/join/drop.png';
import check from '../../assets/join/agree.png';
import cancel from '../../assets/mypage/cancel.png';
import axiosClient from '../../libs/axiosClient';
import { isDesktop } from 'react-device-detect';
import { loginCheck } from '../../auth/auth';

const serviceOption: CheckboxOption[] = [
  { name: '농산물', id: 's1', type: 'check', option: 'service' },
  { name: '축산물', id: 's2', type: 'check', option: 'service' },
  { name: '수산물', id: 's3', type: 'check', option: 'service' },
  { name: '분말', id: 's4', type: 'check', option: 'service' },
  { name: '액상', id: 's5', type: 'check', option: 'service' },
  { name: '가공', id: 's6', type: 'check', option: 'service' },
  { name: '건강기능', id: 's7', type: 'check', option: 'service' },
  { name: '포장', id: 's8', type: 'check', option: 'service' },
  { name: '기타', id: 's9', type: 'check', option: 'service' },
];

const areaOption: CheckboxOption[] = [
  { name: '서울특별시', id: 'a1', type: 'check', option: 'area' },
  { name: '부산광역시', id: 'a2', type: 'check', option: 'area' },
  { name: '대구광역시', id: 'a3', type: 'check', option: 'area' },
  { name: '인천광역시', id: 'a4', type: 'check', option: 'area' },
  { name: '광주광역시', id: 'a5', type: 'check', option: 'area' },
  { name: '대전광역시', id: 'a6', type: 'check', option: 'area' },
  { name: '울산광역시', id: 'a7', type: 'check', option: 'area' },
  { name: '세종특별자치시', id: 'a8', type: 'check', option: 'area' },
  { name: '경기도', id: 'a9', type: 'check', option: 'area' },
  { name: '강원도', id: 'a10', type: 'check', option: 'area' },
  { name: '충청북도', id: 'a11', type: 'check', option: 'area' },
  { name: '충청남도', id: 'a12', type: 'check', option: 'area' },
  { name: '전라북도', id: 'a13', type: 'check', option: 'area' },
  { name: '전라남도', id: 'a14', type: 'check', option: 'area' },
  { name: '경상북도', id: 'a15', type: 'check', option: 'area' },
  { name: '경상남도', id: 'a16', type: 'check', option: 'area' },
  { name: '제주특별자치도', id: 'a17', type: 'check', option: 'area' },
];

const storageOption: CheckboxOption[] = [
  { name: '냉장보관', id: 'st1', type: 'check', option: 'storage' },
  { name: '냉동보관', id: 'st2', type: 'check', option: 'storage' },
  { name: '실온보관', id: 'st3', type: 'check', option: 'storage' },
];

function CompanyProfile() {
  // const dateRegex = /^[0-9]{4}년[0-9]{1,2}월$/;
  const test = '001230';
  let updateList: string[] = [];
  const dateRegex = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
  const memberRegex = /^[0-9]*$/;
  const phoneRegex =
    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-[0-9]{3,4}-[0-9]{4}$/;
  const emailRegex =
    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const intervalRef = useRef<NodeJS.Timeout | null | any>(null);
  const [phoneTime, setPhoneTime] = useState(300);
  const [phoneSecondOpen, setPhoneSecondOpen] = useState(false);
  const [phoneThirdOpen, setPhoneThirdOpen] = useState(false);
  const [isPhoneCheck, setIsPhoneCheck] = useState(false);
  const [phoneCheckNum, setPhoneCheckNum] = useState('');
  const [profile, setProfile] = useState<IMypageType>();
  const [name, setName] = useState('');
  const [ceoName, setCeoName] = useState('');
  const [sinceDate, setSinceDate] = useState('');
  const [memberQuantity, setMemberQuantity] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [businessPartner, setBusinessPartner] = useState('');
  const [addr, setAddr] = useState('');
  const [homepage, setHomepage] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailDirectly, setEmailDirectly] = useState(true);
  const [managerName, setManagerName] = useState('');
  const [managerPhone, setManagerPhone] = useState('');
  const [memo, setMemo] = useState('');
  const [selectMenu, setSelectMenu] = useState('');
  const [initImageSrc, setInitImageSrc] = useState<string[]>([]);
  const [imageSrc, setImageSrc] = useState<any[]>([]);
  const [imageBinary, setImageBinary] = useState<any[]>([]);
  const [deleteImageSrc, setDeleteImageSrc] = useState<any[]>([]);
  const [deleteImageBinary, setDeleteImageBinary] = useState<any[]>([]);
  const [companyImgList, setCompanyImgList] = useState([]);
  const [initService, setInitService] = useState<CheckboxOption[]>([]);
  const [initArea, setInitArea] = useState<CheckboxOption[]>([]);
  const [initStorage, setInitStorage] = useState<CheckboxOption[]>([]);
  const [selectServiceList, setSelectServiceList] = useState<CheckboxOption[]>(
    [],
  );
  const [selectAreaList, setSelectAreaList] = useState<CheckboxOption[]>([]);
  const [selectStorageList, setSelectStorageList] = useState<CheckboxOption[]>(
    [],
  );
  const [selectText, setSelectText] = useState('선택해주세요.');
  const [selectPhoneText, setSelectPhoneText] = useState('통신사');

  const getCompanyImg = async () => {
    await axiosClient
      .get(`/member-manufacture-imgs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setImageSrc(res.data.data);
        setInitImageSrc(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompanyProfile = async () => {
    await axiosClient
      .get('/auth/member-manufacture/mypage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setProfile(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!loginCheck()) return navigate('/login');
    getCompanyImg();
    if (navigationType !== 'POP') sessionStorage.removeItem('saveProfile');
    getCompanyProfile();
  }, []);

  useEffect(() => {
    if (profile === undefined) return;

    const saveInfo = JSON.parse(
      sessionStorage.getItem('saveProfile') || JSON.stringify(''),
    );

    if (saveInfo && navigationType === 'POP') {
      setName(saveInfo.name);
      setCeoName(saveInfo.ceoName);
      setSinceDate(saveInfo.sinceDate);
      setMemberQuantity(saveInfo.memberQuantity);
      setCategoryName(saveInfo.categoryName);
      setBusinessPartner(saveInfo.businessPartner);
      setAddr(saveInfo.addr);
      setHomepage(saveInfo.homepage);
      setPhone(saveInfo.phone);
      setFax(saveInfo.fax);
      setEmail1(saveInfo.email1);
      setEmail2(saveInfo.email2);
      setManagerName(saveInfo.managerName);
      setManagerPhone(saveInfo.managerPhone);
      setMemo(saveInfo.memo);
      setIsPhoneCheck(saveInfo.isPhoneCheck);
      if (saveInfo.isPhoneCheck) {
        setPhoneCheckNum(saveInfo.phoneCheckNum);
        setPhoneSecondOpen(saveInfo.phoneSecondOpen);
        setPhoneThirdOpen(saveInfo.phoneThirdOpen);
      }
      handleCategory('session');
      sessionStorage.removeItem('saveProfile');
    } else {
      setName(profile.name);
      setCeoName(profile.ceo_name);
      setSinceDate(profile.since_date);
      setMemberQuantity(profile.member_quantity);
      setCategoryName(profile.category_name);
      setBusinessPartner(profile.business_partner);
      setAddr(profile.addr);
      setHomepage(profile.homepage);
      setPhone(profile.hp);
      setFax(profile.fax);
      setEmail1(profile.email.split('@')[0]);
      setEmail2(profile.email.split('@')[1]);
      setManagerName(profile.manager_name);
      setManagerPhone(profile.manager_hp);
      setMemo(profile.memo);
      handleCategory('profile');
    }
  }, [profile]);

  const handleCategory = (method: string) => {
    let categorySplit1;
    let categorySplit2;
    let categorySplit3;
    if (method === 'session') {
      const saveInfo = JSON.parse(
        sessionStorage.getItem('saveProfile') || JSON.stringify(''),
      );
      categorySplit1 = saveInfo.categoryone.split(',');
      categorySplit2 = saveInfo.categorytwo.split(',');
      categorySplit3 = saveInfo.categorythree.split(',');
    } else if (method === 'profile') {
      if (
        !profile ||
        !profile.produce_category ||
        !profile.addr_category ||
        !profile.store_category
      )
        return;
      categorySplit1 = profile.produce_category.split(',');
      categorySplit2 = profile.addr_category.split(',');
      categorySplit3 = profile.store_category.split(',');
    }

    const newList1 = categorySplit1.map((category: any) => {
      const matchOption = serviceOption.find((option) =>
        option.name.includes(category.trim()),
      );
      return {
        name: matchOption ? matchOption.name : '',
        id: matchOption ? matchOption.id : '',
        type: 'check',
        option: 'service',
      };
    });
    setSelectServiceList(newList1);
    setInitService(newList1);

    const newList2 = categorySplit2.map((category: any) => {
      const matchOption = areaOption.find((option) =>
        option.name.includes(category.trim()),
      );
      return {
        name: matchOption ? matchOption.name : '',
        id: matchOption ? matchOption.id : '',
        type: 'check',
        option: 'area',
      };
    });
    setSelectAreaList(newList2);
    setInitArea(newList2);

    const newList3 = categorySplit3.map((category: any) => {
      const matchOption = storageOption.find((option) =>
        option.name.includes(category.trim()),
      );
      return {
        name: matchOption ? matchOption.name : '',
        id: matchOption ? matchOption.id : '',
        type: 'check',
        option: 'storage',
      };
    });
    setSelectStorageList(newList3);
    setInitStorage(newList3);
  };

  function encodeFileToBase64(file: any): any {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsDataURL(file);
    });
  }

  function deleteUploadImage(idx: number) {
    axiosClient
      .post(
        `/member-manufacture-img/delete`,
        {
          id: imageSrc[idx].id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        for (let i = 0; i <= idx; i++) {
          if (imageSrc[i] === 'deleted') idx += 1;
          else break;
        }
        let list = [...imageSrc];
        let binaryList = [...imageBinary];
        if (typeof imageBinary[idx] == 'string') {
          list[idx] = 'deleted';
          binaryList[idx] = 'deleted';
        } else {
          list.splice(idx, 1);
          binaryList.splice(idx, 1);
        }
        setImageSrc(list);
        setImageBinary(binaryList);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const selectCategory = (option: any) => {
    if (option.option === 'service')
      setSelectServiceList((list) => {
        const exists = list.some((prev) => prev.name === option.name);
        if (exists) return list.filter((prev) => prev.name !== option.name);
        else return [...list, option];
      });
    else if (option.option === 'area')
      setSelectAreaList((list) => {
        const exists = list.some((prev) => prev.name === option.name);
        if (exists) return list.filter((prev) => prev.name !== option.name);
        else return [...list, option];
      });
    else if (option.option === 'storage')
      setSelectStorageList((list) => {
        const exists = list.some((prev) => prev.name === option.name);
        if (exists) return list.filter((prev) => prev.name !== option.name);
        else return [...list, option];
      });
  };

  const saveInfo = () => {
    const categoryone = selectServiceList.map((item) => item.name).join(',');
    const categorytwo = selectAreaList.map((item) => item.name).join(',');
    const categorythree = selectStorageList.map((item) => item.name).join(',');
    const infomation: any = {};
    infomation.name = name;
    infomation.ceoName = ceoName;
    infomation.sinceDate = sinceDate;
    infomation.memberQuantity = memberQuantity;
    infomation.categoryName = categoryName;
    infomation.businessPartner = businessPartner;
    infomation.addr = addr;
    infomation.homepage = homepage;
    infomation.phone = phone;
    infomation.fax = fax;
    infomation.email1 = email1;
    infomation.email2 = email2;
    infomation.managerName = managerName;
    infomation.managerPhone = managerPhone;
    infomation.memo = memo;
    infomation.categoryone = categoryone;
    infomation.categorytwo = categorytwo;
    infomation.categorythree = categorythree;
    infomation.isPhoneCheck = isPhoneCheck;
    infomation.phoneCheckNum = phoneCheckNum;
    infomation.phoneSecondOpen = phoneSecondOpen;
    infomation.phoneThirdOpen = phoneThirdOpen;
    sessionStorage.setItem('saveProfile', JSON.stringify(infomation));
  };

  const onPreview = () => {
    if (!profile) return;
    saveInfo();
    const data = {
      page: '정보',
      img: imageSrc,
      name: name,
      ceo_name: ceoName,
      since_date: sinceDate,
      member_quantity: memberQuantity,
      category_name: categoryName,
      business_partner: businessPartner,
      addr: addr,
      homepage: homepage,
      hp: phone,
      fax: fax,
      email1: email1,
      email2: email2,
      manager_name: managerName,
      manager_hp: managerPhone,
      memo: memo,
      isPhoneCheck: isPhoneCheck,
      phoneCheckNum: phoneCheckNum,
    };
    navigate(`/preview/${profile.id}`, { state: data });
  };

  const onCancel = () => {
    if (!profile) return;
    setName(profile.name);
    setCeoName(profile.ceo_name);
    setSinceDate(profile.since_date);
    setMemberQuantity(profile.member_quantity);
    setCategoryName(profile.category_name);
    setBusinessPartner(profile.business_partner);
    setAddr(profile.addr);
    setHomepage(profile.homepage);
    setPhone(profile.hp);
    setFax(profile.fax);
    setEmail1(profile.email.split('@')[0]);
    setEmail2(profile.email.split('@')[1]);
    setEmailDirectly(true);
    setManagerName(profile.manager_name);
    setManagerPhone(profile.manager_hp);
    setMemo(profile.memo);
    handleCategory('profile');
  };

  const handleEmailCheck = () => {
    if (!emailRegex.test(email1 + '@' + email2)) {
      alert('올바르지 않은 이메일 형식입니다.');
      return;
    }

    axiosClient
      .post(`/auth/check/email`, {
        email: email1 + '@' + email2,
      })
      .then((res) => {
        if (res.status === 200) {
          setEmailCheck(true);
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) alert(err.response.data.email);
      });
  };

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setPhoneTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(intervalRef.current);
          alert('연락처 인증 시간이 초과되었습니다. 다시 시도해주세요.');
          setPhoneTime(300);
          setPhoneSecondOpen(false);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);
  };

  const stopTimer = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const minutes = Math.floor(phoneTime / 60);
  const seconds = phoneTime % 60;

  const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}`;

  const onSubmit = () => {
    const email = email1 + '@' + email2;

    if (!name) return alert('회사이름을 입력해 주세요.');
    if (name !== profile?.name) updateList.push('name');
    if (!ceoName) return alert('대표자를 입력해 주세요.');
    if (ceoName !== profile?.ceo_name) updateList.push('ceoName');
    if (!sinceDate) return alert('설립연도를 입력해 주세요.');
    if (sinceDate.length) {
      if (!dateRegex.test(sinceDate))
        return alert('설립연도 형식을 지켜주세요. 2024-01-01');
      if (sinceDate !== profile?.since_date) updateList.push('sinceDate');
    }
    if (!memberQuantity) return alert('직원인원을 입력해 주세요.');
    if (memberQuantity) {
      if (!memberRegex.test(memberQuantity))
        return alert('직원인원을 숫자만 입력해 주세요.');
      if (memberQuantity !== profile?.member_quantity)
        updateList.push('memberQuantity');
    }
    if (!categoryName) return alert('관련업종을 입력해 주세요.');
    if (categoryName !== profile?.category_name)
      updateList.push('categoryName');
    if (!businessPartner) return alert('주요거래처를 입력해 주세요.');
    if (businessPartner !== profile?.business_partner)
      updateList.push('businessPartner');
    if (!addr) return alert('회사주소를 입력해 주세요.');
    if (addr !== profile?.addr) updateList.push('addr');
    if (homepage !== profile?.homepage) updateList.push('homepage');
    // if (selectPhoneText === '통신사') return alert('통신사를 선택해주세요.');
    if (phone) {
      if (!phoneRegex.test(phone))
        return alert(
          '대표전화 형식을 지켜주세요. 010-1234-1234 또는 02-123-1234',
        );
    }
    if (phone !== profile?.hp) updateList.push('phone');
    if (fax !== profile?.fax) updateList.push('fax');
    if (!managerName) return alert('담당자이름을 입력해 주세요.');
    if (managerName !== profile?.manager_name) updateList.push('managerName');
    if (!managerPhone) return alert('담당자 연락처를 입력해 주세요.');
    if (managerPhone.length) {
      if (!phoneRegex.test(managerPhone))
        return alert(
          '담당자 연락처 형식을 지켜주세요. 010-1234-1234 또는 02-123-1234',
        );
      if (managerPhone !== profile?.manager_hp) {
        updateList.push('managerPhone');
        // if (!isPhoneCheck)
        //   return alert('담당자 연락처 본인인증을 완료해주세요.');
      }
      // else setIsPhoneCheck(true);
    }
    if (!email1.length || !email2.length)
      return alert('담당자 이메일을 입력해주세요.');
    if (profile?.email !== email) {
      updateList.push('email');
      if (!emailCheck) return alert('담당자 이메일 중복확인을 해주세요.');
    }
    if (memo !== profile?.memo) updateList.push('memo');

    // 연락처인증완료여부 추가
    if (!selectServiceList.length) return alert('제조서비스를 선택해 주세요.');
    if (!selectAreaList.length) return alert('지역을 선택해 주세요.');
    if (!selectStorageList.length) return alert('보관방식을 선택해 주세요.');
    if (initService !== selectServiceList) updateList.push('service');
    if (initArea !== selectAreaList) updateList.push('area');
    if (initStorage !== selectStorageList) updateList.push('storage');
    if (updateList.length === 0) return alert('수정된 정보가 없습니다.');

    let formData = new FormData();
    const categoryone = selectServiceList.map((item) =>
      item.id.replace(/\D/g, ''),
    );
    const categorytwo = selectAreaList.map((item) =>
      item.id.replace(/\D/g, ''),
    );
    const categorythree = selectStorageList.map((item) =>
      item.id.replace(/\D/g, ''),
    );

    formData.append('name', name);
    formData.append('ceo_name', ceoName);
    formData.append('since_date', sinceDate);
    formData.append('member_quantity', memberQuantity);
    formData.append('category_name', categoryName);
    formData.append('business_partner', businessPartner);
    formData.append('addr', addr);
    if (homepage !== null) formData.append('homepage', homepage);
    if (phone !== null) formData.append('hp', phone);
    if (fax !== null) formData.append('fax', fax);
    formData.append('manager_name', managerName);
    formData.append('manager_hp', managerPhone);
    formData.append('email', email);
    if (memo !== null) formData.append('memo', memo);
    for (let i = 0; i < categoryone.length; i++) {
      formData.append(`produce_category[]`, categoryone[i]);
    }
    for (let i = 0; i < categorytwo.length; i++) {
      formData.append(`addr_category[]`, categorytwo[i]);
    }
    for (let i = 0; i < categorythree.length; i++) {
      formData.append(`store_category[]`, categorythree[i]);
    }

    axiosClient
      .post(`/auth/member-manufacture/mypage/info`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        alert(res.data.msg);
        getCompanyProfile();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <MypageSideMenu />
        <div className={styles.mainContentWrap}>
          <p>회사 정보</p>
          <div className={styles.companyImgWrap}>
            <p>회사이미지</p>
            <div className={styles.companyListWrap}>
              {imageSrc
                .filter((src) => src !== 'deleted')
                .map((image, idx) => (
                  <div key={idx} className={styles.imgFrame}>
                    <img
                      className={`${styles.remove} ${
                        idx === 0 ? styles.firstRemove : ''
                      }`}
                      onClick={() => deleteUploadImage(idx)}
                      src={idx === 0 ? firstRemove : remove}
                      alt="remove"
                    />
                    <img
                      className={styles.company}
                      src={image.img ? image.img : image}
                      alt="selectedImg"
                    />
                  </div>
                ))}
              {imageSrc.length < 8 && (
                <div className={styles.imgAddFrame}>
                  <input
                    key={imageSrc.length}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      let files = e.currentTarget.files;
                      let readers = [];
                      if (!files!.length) return;
                      let binaryList: File[] = [];
                      for (let i = 0; i < 8 - imageBinary.length; i++) {
                        if (!files![i]) break;
                        binaryList.push(files![i]);
                      }
                      setImageBinary([...imageBinary, ...binaryList]);
                      for (let i = 0; i < files!.length; i++) {
                        readers.push(encodeFileToBase64(files![i]));
                      }
                      Promise.all(readers).then((values) => {
                        let list = [];
                        if (values.length + imageSrc.length > 8) {
                          alert('최대 8장까지만 첨부 가능합니다.');
                        }
                        for (let i = 0; i < 8 - imageSrc.length; i++) {
                          if (!values[i]) break;
                          list.push(values[i]);
                        }
                        setImageSrc([...imageSrc, ...list]);
                        let formData = new FormData();
                        if (binaryList[0] && typeof binaryList[0] == 'object') {
                          formData.append('img', binaryList[0]);
                          axiosClient
                            .post(`/member-manufacture-img/add`, formData, {
                              headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                  'token',
                                )}`,
                              },
                            })
                            .then((res) => {
                              getCompanyImg();
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      });
                    }}
                  />
                  <div className={styles.imgBtn}>
                    <img src={add} alt="add" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.contentWrap}>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>회사이름</span>
                <span>*</span>
              </div>
              <input
                type="text"
                value={name || ''}
                placeholder="회사이름을 입력해 주세요."
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>대표자</span>
                <span>*</span>
              </div>
              <input
                type="text"
                value={ceoName || ''}
                placeholder="대표자를 입력해 주세요."
                onChange={(e) => {
                  setCeoName(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>설립연도</span>
                <span>*</span>
              </div>
              <input
                type="text"
                value={sinceDate || ''}
                placeholder="설립연도를 입력해 주세요."
                onChange={(e) => {
                  setSinceDate(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>직원인원</span>
                <span>*</span>
              </div>
              <div className={styles.memberWrap}>
                <input
                  type="number"
                  value={memberQuantity || ''}
                  placeholder="직원인원을 입력해 주세요."
                  onChange={(e) => {
                    setMemberQuantity(e.currentTarget.value);
                  }}
                />
                <label>명</label>
              </div>
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>관련업종</span>
                <span>*</span>
              </div>
              <input
                type="text"
                value={categoryName || ''}
                placeholder="관련업종을 입력해 주세요."
                onChange={(e) => {
                  setCategoryName(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>주요거래처</span>
                <span>*</span>
              </div>
              <input
                type="text"
                value={businessPartner || ''}
                placeholder="주요거래처를 입력해 주세요."
                onChange={(e) => {
                  setBusinessPartner(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>회사주소</span>
                <span>*</span>
              </div>
              <input
                type="text"
                value={addr || ''}
                placeholder="회사주소를 입력해 주세요."
                onChange={(e) => {
                  setAddr(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>홈페이지(선택)</span>
              </div>
              <input
                type="text"
                value={homepage || ''}
                placeholder="홈페이지를 입력해 주세요."
                onChange={(e) => {
                  setHomepage(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>대표전화(선택)</span>
              </div>
              <input
                type="text"
                value={phone || ''}
                placeholder="대표전화를 입력해 주세요."
                onChange={(e) => {
                  setPhone(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>팩스(선택)</span>
              </div>
              <input
                type="text"
                value={fax || ''}
                placeholder="팩스를 입력해 주세요."
                onChange={(e) => {
                  setFax(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>담당자</span>
                <span>*</span>
              </div>
              <div>
                <p>담당자이름</p>
                <input
                  type="text"
                  value={managerName || ''}
                  placeholder="담당자이름을 입력해 주세요."
                  onChange={(e) => {
                    setManagerName(e.currentTarget.value);
                  }}
                />
              </div>
              <div>
                <p>담당자 연락처</p>
                <div className={styles.phoneInputWrap}>
                  <div className={styles.phoneRequestWrap}>
                    <div className={styles.customSelect}>
                      <select
                        disabled={phoneThirdOpen ? true : false}
                        defaultValue="default"
                        onChange={(e) => {
                          setSelectPhoneText(e.currentTarget.value);
                        }}
                        className={`${styles.selectText} ${
                          selectPhoneText === '통신사' ? '' : styles.hit
                        }`}
                      >
                        <option disabled value="default">
                          통신사
                        </option>
                        <option value="skt">SKT</option>
                        <option value="kt">KT</option>
                        <option value="lg">LG U+</option>
                      </select>
                      <img
                        src={drop}
                        alt="selectOpen"
                        style={{
                          display: emailDirectly ? 'none' : '',
                          pointerEvents: 'none',
                        }}
                      />
                      <img
                        src={drop}
                        alt="inputCancel"
                        style={{ display: !emailDirectly ? 'none' : '' }}
                        onClick={() => {
                          setEmailDirectly(false);
                        }}
                      />
                    </div>
                    <input
                      type="text"
                      disabled={phoneThirdOpen ? true : false}
                      placeholder="연락처를 입력해 주세요."
                      value={managerPhone || ''}
                      onChange={(e) => {
                        if (phoneSecondOpen) {
                          setPhoneSecondOpen(false);
                          setPhoneCheckNum('');
                          stopTimer();
                        }
                        const value = e.currentTarget.value;
                        setManagerPhone(value);
                      }}
                    />
                    {/* <div
                      className={styles.requestBtnWrap}
                      onClick={() => {
                        if (phoneThirdOpen) return;
                        if (managerPhone === profile?.manager_hp) return;
                        if (selectPhoneText === '통신사')
                          alert('통신사를 선택해 주세요.');
                        else if (managerPhone === '')
                          alert('연락처를 입력해 주세요.');
                        else if (!phoneRegex.test(managerPhone))
                          alert(
                            '연락처 형식을 지켜주세요. 010-1234-1234 또는 02-123-1234',
                          );
                        else {
                          setPhoneSecondOpen(true);
                          setPhoneTime(300);
                          startTimer();
                        }
                      }}
                    >
                      인증요청
                    </div> */}
                  </div>
                  {phoneSecondOpen && (
                    <div className={styles.requestNumberWrap}>
                      <input
                        type="text"
                        placeholder="인증번호를 입력해 주세요."
                        value={phoneCheckNum || ''}
                        maxLength={6}
                        disabled={phoneThirdOpen ? true : false}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          setPhoneCheckNum(value);
                          if (value.length === 6) {
                            // 인증번호맞는지 확인하는 함수 필요
                            // setIsPhoneCheck(true);
                            if (test === value) {
                              setPhoneThirdOpen(true);
                              setIsPhoneCheck(true);
                              stopTimer();
                            } else alert('인증번호가 올바르지 않습니다.');
                          } else setPhoneThirdOpen(false);
                        }}
                      />
                      <label>{formattedTime}</label>
                    </div>
                  )}
                  {phoneThirdOpen && (
                    <div className={styles.requestConfirmWrap}>
                      <input
                        type="text"
                        defaultValue={phoneCheckNum || ''}
                        disabled
                      />
                      <div className={styles.requestConfirmImg}>
                        <img src={check} alt="check" />
                        <span>인증완료</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>담당자 이메일</span>
                <span>*</span>
              </div>
              <div className={styles.emailInputWrap}>
                <div className={styles.left}>
                  <input
                    type="text"
                    placeholder="이메일을 입력해 주세요."
                    value={email1 || ''}
                    onChange={(e) => {
                      setEmail1(e.currentTarget.value);
                      setEmailCheck(false);
                    }}
                  />
                  <span>@</span>
                  <div className={styles.emailSelectWrap}>
                    <div className={styles.customSelect}>
                      <input
                        type="text"
                        placeholder="직접입력"
                        value={email2 || ''}
                        onChange={(e) => {
                          setEmail2(e.currentTarget.value);
                          setEmailCheck(false);
                        }}
                      />
                      <select
                        defaultValue="default"
                        name=""
                        id=""
                        onChange={(e) => {
                          setSelectText(e.currentTarget.value);
                          if (e.currentTarget.value == 'directly') {
                            setEmailDirectly(true);
                            setEmail2('');
                          } else setEmail2(e.currentTarget.value);
                          setEmailCheck(false);
                        }}
                        style={{ display: emailDirectly ? 'none' : '' }}
                        className={`${styles.selectText} ${
                          selectText === '선택해주세요.' ? '' : styles.hit
                        }`}
                      >
                        <option disabled value="default">
                          선택해주세요.
                        </option>
                        <option value="naver.com">naver.com</option>
                        <option value="hanmail.net">hanmail.net</option>
                        <option value="daum.net">daum.net</option>
                        <option value="gmail.com">gmail.com</option>
                        <option value="nate.com">nate.com</option>
                        <option value="hotmail.com">hotmail.com</option>
                        <option value="outlook.com">outlook.com</option>
                        <option value="icloud.com">icloud.com</option>
                        <option value="directly">직접입력</option>
                      </select>
                      <img
                        src={drop}
                        alt="selectOpen"
                        style={{
                          display: emailDirectly ? 'none' : '',
                          pointerEvents: 'none',
                        }}
                      />
                      <img
                        src={cancel}
                        alt="cancel"
                        style={{ display: !emailDirectly ? 'none' : '' }}
                        onClick={() => {
                          setEmailDirectly(false);
                          setEmailCheck(false);
                          setEmail2('');
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={styles.duplicateBtnWrap}
                  onClick={handleEmailCheck}
                >
                  중복확인
                </div>
              </div>
            </div>
            <div className={styles.inputFrame}>
              <div className={styles.title}>
                <span>메모(선택)</span>
              </div>
              <input
                type="text"
                value={memo || ''}
                placeholder="메모를 입력해 주세요."
                onChange={(e) => {
                  setMemo(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          {/* <p>계정 정보</p>
          <div className={styles.contentWrap}>
            <div className={styles.inputFrame}>
              <p>아이디</p>
              <input type="text" />
            </div>
            <div className={styles.inputFrame}>
              <p>비밀번호</p>
              <input type="text" />
            </div>
            <div className={styles.inputFrame}>
              <p>이름</p>
              <input type="text" />
            </div>
            <div className={styles.inputFrame}>
              <p>휴대폰 번호</p>
              <input type="text" />
            </div>
            <div className={styles.inputFrame}>
              <p>이메일</p>
              <input type="text" />
            </div>
          </div> */}
          <div className={styles.categoryWrap}>
            <div className={styles.title}>
              <span>카테고리</span>
              <span>*</span>
            </div>
            <div className={styles.categoryList}>
              <div className={styles.categoryFrame}>
                <p>제조서비스</p>
                <div className={styles.checkboxWrap}>
                  {serviceOption &&
                    serviceOption.map((option: any, idx: any) => (
                      <div key={option.name} className={styles.customCheckbox}>
                        <input
                          checked={selectServiceList.some(
                            (opt) => opt.name === option.name,
                          )}
                          onChange={() => selectCategory(option)}
                          type="checkbox"
                          id={`service-${idx}`}
                        />
                        <label htmlFor={`service-${idx}`}>
                          <span>{option.name}</span>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              <div className={styles.categoryFrame}>
                <p>지역</p>
                <div className={styles.checkboxWrap}>
                  {areaOption &&
                    areaOption.map((option: any, idx: any) => (
                      <div key={option.name} className={styles.customCheckbox}>
                        <input
                          checked={selectAreaList.some(
                            (opt) => opt.id === option.id,
                          )}
                          onChange={() => selectCategory(option)}
                          type="checkbox"
                          id={`area-${idx}`}
                        />
                        <label htmlFor={`area-${idx}`}>
                          <span>{option.name}</span>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              <div className={styles.categoryFrame}>
                <p>보관방식</p>
                <div className={styles.checkboxWrap}>
                  {storageOption &&
                    storageOption.map((option: any, idx: any) => (
                      <div key={option.name} className={styles.customCheckbox}>
                        <input
                          checked={selectStorageList.some(
                            (opt) => opt.id === option.id,
                          )}
                          onChange={() => selectCategory(option)}
                          type="checkbox"
                          id={`storage-${idx}`}
                        />
                        <label htmlFor={`storage-${idx}`}>
                          <span>{option.name}</span>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className={styles.selectCategoryWrap}>
              <span>선택한 카테고리: </span>
              <span>
                {selectServiceList &&
                  selectServiceList.map((select) => select.name).join(', ')}
                {selectAreaList && selectAreaList.length > 0 && '>'}
                {selectAreaList &&
                  selectAreaList.map((select) => select.name).join(', ')}
                {selectStorageList && selectStorageList.length > 0 && '>'}
                {selectStorageList &&
                  selectStorageList.map((select) => select.name).join(', ')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.bottomBtnWrap} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <div className={styles.btns}>
          <div className={styles.previewBtn} onClick={onPreview}>
            미리보기
          </div>
          <div className={styles.saveBtn} onClick={onSubmit}>
            저장하기
          </div>
          <div className={styles.cancelBtn} onClick={onCancel}>
            취소
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CompanyProfile;
