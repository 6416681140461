import styles from './Notice.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import banner from '../../assets/banner/banner1.png';
import unablePrev from '../../assets/partnerList/unablePrev.png';
import ableNext from '../../assets/partnerList/ableNext.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../libs/axiosClient';
import { isDesktop } from 'react-device-detect';

export interface INoticeType {
  board_id: string;
  board_name: string;
  created_at: string;
  id: number;
  img: {
    count: number;
    data: string;
  };
  title: string;
  title_img: string;
  view_count: string;
}

function Notice() {
  const navigate = useNavigate();
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageList, setPageList] = useState([1]);
  const [noticePage, setNoticePage] = useState(1);
  const [allCount, setAllCount] = useState(0);
  const [noticeList, setNoticeList] = useState<INoticeType[]>([]);

  useEffect(() => {
    getNotice();
  }, [noticePage]);

  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt((noticePage / 5).toString()) * 5 + 1;
    if (!(noticePage % 5)) startPage -= 5;
    let endPage =
      startPage + 4 < allCount / 10 + (allCount % 10 !== 0 ? 1 : 0)
        ? startPage + 4
        : allCount / 10 + (allCount % 10 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    let lastPage = noticePage === Math.ceil(allCount / 10);
    if (allCount === 0) lastPage = true;
    setIsLastPage(lastPage);
    setPageList([...list]);
  }, [allCount, noticePage]);

  const getNotice = () => {
    axiosClient
      .get(`board/notice?page=${noticePage}&per_page=10`)
      .then((res) => {
        setNoticeList(res.data.data);
        setAllCount(res.data.count);
      });
  };

  function handlePaginationDown() {
    if (noticePage == 1) return;
    setNoticePage((prev) => prev - 1);
  }
  function handlePaginationUp() {
    if (noticePage >= allCount / 10) return;
    setNoticePage((prev) => prev + 1);
  }

  return (
    <>
      <Header />
      <div
        className={`${styles.contentWrap} ${isDesktop ? '' : styles.isMobile}`}
      >
        <div className={styles.mainContentWrap}>
          <p>공지사항</p>
          <div className={styles.noticeWrap}>
            {noticeList &&
              noticeList.map((list) => (
                <div
                  key={list.id}
                  className={styles.noticeFrame}
                  onClick={() => navigate(`/notice-detail/${list.id}`)}
                >
                  <img src={list.title_img} alt="thumnail" />
                  <div>
                    <span>{list.title}</span>
                    <span>
                      {list.created_at.slice(0, 10).replaceAll('-', '.')}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {pageList.length > 0 && (
        <div className={styles.paginationWrap}>
          <img
            style={{ transform: noticePage === 1 ? '' : 'rotate(180deg)' }}
            src={noticePage === 1 ? unablePrev : ableNext}
            alt="prev"
            onClick={handlePaginationDown}
          />
          <ul>
            {pageList &&
              pageList.map((page) => (
                <li
                  key={page}
                  onClick={() => {
                    setNoticePage(page);
                  }}
                  className={page == noticePage ? styles.hit : ''}
                >
                  <p>{page}</p>
                </li>
              ))}
          </ul>
          <img
            style={{ transform: isLastPage ? 'rotate(180deg)' : '' }}
            src={isLastPage ? unablePrev : ableNext}
            alt="next"
            onClick={handlePaginationUp}
          />
        </div>
      )}
      <Footer />
    </>
  );
}

export default Notice;
