import styles from './JoinClientSuccess.module.scss';
import success from '../../assets/join/success.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';

function JoinClientSuccess(props: any) {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div
      className={`${styles.joinSuccessModal} ${
        isDesktop ? '' : styles.isMobile
      }`}
    >
      <div className={styles.joinSuccessContent}>
        <img src={success} alt="success" />
        <p className={styles.successText}>회원가입 완료!</p>
        <p className={styles.welcomeText}>파트너 회원이 되신 것을 환영해요.</p>
        <div className={styles.alertWrap}>
          <div>
            <span>지금 바로 </span>
            <span className={styles.alertMsg}>파트너의 서비스</span>
            <span>를</span> <br />
          </div>
          <span>편리하게 이용해 보세요!</span>
        </div>
        <div
          className={styles.successBtnWrap}
          onClick={() => {
            props.closer(false);
            navigate('/');
          }}
        >
          닫기
        </div>
      </div>
    </div>
  );
}

export default JoinClientSuccess;
