import styles from './JoinCompanySuccess.module.scss';
import success from '../../assets/join/success.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';

function JoinComapnySuccess(props: any) {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <div
      className={`${styles.joinSuccessModal} ${
        isDesktop ? '' : styles.isMobile
      }`}
    >
      <div className={styles.joinSuccessContent}>
        <img src={success} alt="success" />
        <p className={styles.successText}>회원가입 완료!</p>
        <p className={styles.welcomeText}>파트너 회원이 되신 것을 환영해요.</p>
        <div className={styles.alertWrap}>
          <div>
            <span>지금 바로 </span>
            <span className={styles.alertMsg}>회사정보</span>
            <span>를</span> <br />
          </div>
          <span>입력해보세요!</span>
        </div>
        <div
          className={styles.successBtnWrap}
          onClick={() => navigate('/mypage/company-profile')}
        >
          마이페이지 바로가기
        </div>
        {/* <div>
          <p onClick={() => props.closer(false)}>닫기</p>
        </div> */}
      </div>
    </div>
  );
}

export default JoinComapnySuccess;
