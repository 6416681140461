import styles from './JoinComapny.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import axiosClient from '../../libs/axiosClient';
import { setToken } from '../../auth/auth';
import { useNavigate } from 'react-router-dom';
import drop from '../../assets/join/drop.png';
import cancel from '../../assets/mypage/cancel.png';
import check from '../../assets/join/agree.png';
import JoinComapnySuccess from '../../components/JoinCompanySuccess/JoinCompanySuccess';
import { isDesktop } from 'react-device-detect';
import axios from 'axios';
import JoinAgreement from '../../components/JoinAgreement/JoinAgreement';

function JoinCompany() {
  const navigate = useNavigate();
  const test = '001230';
  const idRegex = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{4,30}$/;
  var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,30}$/;
  const nameRegex = /^[가-힣a-zA-Z ]+$/;
  const emailRegex =
    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
  const phoneRegex =
    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-[0-9]{3,4}-[0-9]{4}$/;
  const businessNumRegex = /^\d{3}-\d{2}-\d{5}$/;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const intervalRef = useRef<NodeJS.Timeout | null | any>(null);
  const [phoneTime, setPhoneTime] = useState(300);
  const [joinSuccessOpen, setJoinSuccessOpen] = useState(false);
  const [phoneSecondOpen, setPhoneSecondOpen] = useState(false);
  const [phoneThirdOpen, setPhoneThirdOpen] = useState(false);
  const [phoneDirectly, setPhoneDirectly] = useState(false);
  const [emailDirectly, setEmailDirectly] = useState(false);
  const [imageSrc, setImageSrc] = useState<string[]>([]);
  const [imageBinary, setImageBinary] = useState<any[]>([]);
  const [maskingNum, setMaskingNum] = useState('');
  const [isPhoneCheck, setIsPhoneCheck] = useState(false);
  const [phoneCheckNum, setPhoneCheckNum] = useState('');
  const [selectPhoneText, setSelectPhoneText] = useState('통신사');
  const [selectText, setSelectText] = useState('선택해주세요.');

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) navigate(-1);
  }, []);

  const handleIdCheck = () => {
    if (!idRegex.test(watch().id)) {
      alert('아이디는 영문, 숫자를 포함한 4자 이상 30자 이하만 가능합니다.');
      return;
    }

    axiosClient
      .post(`/auth/check/login_id`, {
        login_id: watch().id,
      })
      .then((res) => {
        if (res.status === 200) {
          setValue('idCheck', true);
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) alert(err.response.data.login_id);
      });
  };

  const handleEmailCheck = () => {
    if (!emailRegex.test(watch().email1 + '@' + watch().email2)) {
      alert('올바르지 않은 이메일 형식입니다.');
      return;
    }

    axiosClient
      .post(`/auth/check/email`, {
        email: watch().email1 + '@' + watch().email2,
      })
      .then((res) => {
        if (res.status === 200) {
          setValue('emailCheck', true);
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) alert(err.response.data.email);
      });
  };

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setPhoneTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(intervalRef.current);
          alert('연락처 인증 시간이 초과되었습니다. 다시 시도해 주세요.');
          setPhoneTime(300);
          setPhoneSecondOpen(false);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalRef.current);
  };

  const stopTimer = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const minutes = Math.floor(phoneTime / 60);
  const seconds = phoneTime % 60;

  const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}`;

  const api =
    'PVHkA7CeX%2FKYaFcFyhOzRmQf3ckJqZUBnW4rXcnVfTo9EO8kFqoejVUZXs9FnWdiIs2VlK4as%2BWxWKqBfSgKmA%3D%3D';

  const handleBusiness = (companyNum: string) => {
    if (companyNum === '') return;
    if (!businessNumRegex.test(companyNum))
      return alert(
        '올바르지 않은 사업자번호 형식입니다. 012-34-56789 (10자리)',
      );

    const numFormat =
      companyNum.split('-')[0] +
      companyNum.split('-')[1] +
      companyNum.split('-')[2];

    axios
      .post(
        `https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=${api}`,
        {
          b_no: [numFormat],
        },
      )
      .then((res) => {
        //01 사업자, 02 휴업자, 03 폐업자
        const code = res.data.data[0].b_stt_cd;
        if (code === '01') {
          setValue('isBusinessOk', true);
          alert('인증되었습니다.');
        } else if (code === '02' || code === '03')
          alert('휴업자 또는 폐업자 사업자등록입니다.');
        else alert('국세청에 등록되지 않은 사업자등록번호입니다.');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (data: any) => {
    // if (!isPhoneCheck) return alert('담당자 연락처 본인인증을 완료해주세요.');

    let formData = new FormData();
    let imageList = [...imageBinary];

    formData.append('login_id', data.id);
    formData.append('login_pw', data.pw);
    formData.append('login_pw_confirm', data.pwCheck);
    formData.append('name', data.companyname);
    formData.append('manager_name', data.managerName);
    formData.append('jumin_num', data.residentNum1 + data.residentNum2);
    formData.append('manager_hp', data.managerPhone);
    formData.append('email', data.email1 + '@' + data.email2);
    formData.append('company_num', data.companyNum);
    if (typeof imageList[0] == 'object')
      formData.append(`company_num_img`, imageList[0]);
    formData.append('agree_terms', '1');
    formData.append('agree_privacy', '1');

    axiosClient
      .post(`/auth/member-manufacture/register`, formData)
      .then((res) => {
        setJoinSuccessOpen(true);
        setToken(res.data);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data.manager_hp) {
            alert('이미 등록된 담당자 연락처입니다.');
            setPhoneCheckNum('');
            setPhoneSecondOpen(false);
            setPhoneThirdOpen(false);
          }
        }
      });
  };

  const onError = (error: any) => {
    let errorList: { message: string; ref: HTMLElement }[] =
      Object.values(error);
    alert(errorList[0].message);
    window.scrollTo(0, errorList[0].ref.offsetTop - 150);
    errorList[0].ref.focus();
  };

  function encodeFileToBase64(file: any): any {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsDataURL(file);
    });
  }

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <p>회원가입</p>
        <form
          className={styles.joinFormWrap}
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>아이디</span>
              <span>*</span>
            </div>
            <div className={styles.idInputWrap}>
              <input
                type="text"
                placeholder="영문, 숫자 4자 이상 아이디를 입력해 주세요."
                {...register('id', {
                  value: '',
                  required: {
                    value: true,
                    message: '아이디를 입력해 주세요.',
                  },
                  pattern: {
                    value: idRegex,
                    message: '아이디 형식이 올바르지 않습니다',
                  },
                  onChange: () => {
                    setValue('idCheck', false);
                  },
                })}
              />
              <div
                className={styles.duplicateBtnWrap}
                onClick={handleIdCheck}
                {...register('idCheck', {
                  value: false,
                  validate: () =>
                    watch().idCheck !== true
                      ? '아이디 중복 확인을 해주세요.'
                      : true,
                })}
              >
                중복확인
              </div>
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>비밀번호</span>
              <span>*</span>
            </div>
            <input
              type="password"
              placeholder="비밀번호를 입력해 주세요."
              {...register('pw', {
                value: '',
                required: '비밀번호를 입력해 주세요.',
                pattern: {
                  value: passwordRegex,
                  message:
                    '비밀번호는 영문, 숫자, 특수기호(필수x)를 사용하여 8자 이상 30자 이내만 가능합니다.',
                },
              })}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>비밀번호 확인</span>
              <span>*</span>
            </div>
            <input
              type="password"
              placeholder="비밀번호를 재입력해 주세요."
              {...register('pwCheck', {
                value: '',
                required: '비밀번호 확인을 입력해 주세요.',
                validate: () =>
                  watch().pw !== watch().pwCheck
                    ? '비밀번호가 일치하지 않습니다.'
                    : true,
              })}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>회사이름</span>
              <span>*</span>
            </div>
            <input
              type="text"
              placeholder="이름을 입력해 주세요."
              {...register('companyname', {
                value: '',
                required: '회사이름을 입력해 주세요.',
              })}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>담당자 이름</span>
              <span>*</span>
            </div>
            <input
              type="text"
              placeholder="이름을 입력해 주세요."
              {...register('managerName', {
                value: '',
                required: '담당자이름을 입력해 주세요.',
                pattern: {
                  value: nameRegex,
                  message: '담당자이름 형식이 올바르지 않습니다.',
                },
              })}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>주민등록번호</span>
              <span>*</span>
            </div>
            <div className={styles.residentNumberWrap}>
              <input
                type="text"
                placeholder="생년월일 앞 7자리를 입력해 주세요."
                maxLength={6}
                {...register('residentNum1', {
                  value: '',
                  required: '주민등록번호 앞자리를 입력해 주세요.',
                })}
              />
              <span>-</span>
              <div>
                <input
                  type="text"
                  placeholder="0"
                  value={maskingNum}
                  maxLength={1}
                  {...register('residentNum2', {
                    value: '',
                    required: '주민등록번호 뒷자리를 입력해 주세요.',
                  })}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    const valueRegex = value.match(/[1-4]/);
                    if (valueRegex) setMaskingNum(e.currentTarget.value);
                    else setMaskingNum('');
                  }}
                />
                <label>••••••</label>
              </div>
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>담당자 연락처</span>
              <span>*</span>
            </div>
            <div className={styles.phoneInputWrap}>
              <div className={styles.phoneRequestWrap}>
                <div className={styles.customSelect}>
                  <select
                    disabled={phoneThirdOpen ? true : false}
                    defaultValue="default"
                    {...register('phoneSelect', {
                      validate: () =>
                        selectPhoneText === '통신사'
                          ? '통신사를 선택해 주세요.'
                          : true,
                      onChange: (e) => {
                        setSelectPhoneText(e.currentTarget.value);
                        setValue('phoneSelect', e.currentTarget.value);
                      },
                    })}
                    style={{ display: phoneDirectly ? 'none' : '' }}
                    className={`${styles.selectText} ${
                      selectPhoneText === '통신사' ? '' : styles.hit
                    }`}
                  >
                    <option disabled value="default">
                      통신사
                    </option>
                    <option value="skt">SKT</option>
                    <option value="kt">KT</option>
                    <option value="lg">LG U+</option>
                  </select>
                  <img
                    src={drop}
                    alt="selectOpen"
                    style={{
                      display: phoneDirectly ? 'none' : '',
                      pointerEvents: 'none',
                    }}
                  />
                  <img
                    src={drop}
                    alt="inputCancel"
                    style={{ display: !phoneDirectly ? 'none' : '' }}
                    onClick={() => {
                      setPhoneDirectly(false);
                      setValue('phoneSelect', '');
                    }}
                  />
                </div>
                <input
                  type="text"
                  disabled={phoneThirdOpen ? true : false}
                  placeholder="연락처를 입력해 주세요."
                  {...register('managerPhone', {
                    value: '',
                    required: '담당자 연락처를 입력해 주세요.',
                    validate: () =>
                      !phoneRegex.test(watch().managerPhone)
                        ? '연락처 형식을 지켜주세요. 010-1234-1234 또는 02-123-1234'
                        : true,
                    onChange: (e) => {
                      if (phoneSecondOpen) {
                        setPhoneSecondOpen(false);
                        setPhoneCheckNum('');
                        stopTimer();
                      }
                      const value = e.currentTarget.value;
                      if (isNaN(Number(value)) && value !== '+')
                        setValue(
                          'tel',
                          watch().managerPhone.slice(
                            0,
                            watch().managerPhone.length - 1,
                          ),
                        );
                    },
                  })}
                />
                {/* <div
                  className={styles.requestBtnWrap}
                  onClick={() => {
                    if (phoneThirdOpen) return;
                    if (watch().phoneSelect === 'default')
                      alert('통신사를 선택해 주세요.');
                    else if (watch().managerPhone === '')
                      alert('연락처를 입력해 주세요.');
                    else if (!phoneRegex.test(watch().managerPhone))
                      alert(
                        '연락처 형식을 지켜주세요. 010-1234-1234 또는 02-123-1234',
                      );
                    else {
                      setPhoneSecondOpen(true);
                      setPhoneTime(300);
                      startTimer();
                    }
                  }}
                >
                  인증요청
                </div> */}
              </div>
              {phoneSecondOpen && (
                <div className={styles.requestNumberWrap}>
                  <input
                    type="text"
                    placeholder="인증번호를 입력해 주세요."
                    value={phoneCheckNum || ''}
                    maxLength={6}
                    disabled={phoneThirdOpen ? true : false}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setPhoneCheckNum(value);
                      if (value.length === 6) {
                        // 인증번호맞는지 확인하는 함수 필요
                        // setIsPhoneCheck(true);
                        if (test === value) {
                          setPhoneThirdOpen(true);
                          setIsPhoneCheck(true);
                          stopTimer();
                        } else alert('인증번호가 올바르지 않습니다.');
                      } else setPhoneThirdOpen(false);
                    }}
                  />
                  <label>{formattedTime}</label>
                </div>
              )}
              {phoneThirdOpen && (
                <div className={styles.requestConfirmWrap}>
                  <input
                    type="text"
                    defaultValue={phoneCheckNum || ''}
                    disabled
                  />
                  <div className={styles.requestConfirmImg}>
                    <img src={check} alt="check" />
                    <span>인증완료</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>담당자 이메일</span>
              <span>*</span>
            </div>
            <div className={styles.emailInputWrap}>
              <div className={styles.left}>
                <input
                  type="text"
                  placeholder="담당자 이메일을 입력해 주세요."
                  {...register('email1', {
                    value: '',
                    required: '담당자 이메일을 입력해 주세요.',
                    onChange: () => {
                      setValue('emailCheck', false);
                    },
                  })}
                />
                <span>@</span>
                <div className={styles.emailSelectWrap}>
                  <div className={styles.customSelect}>
                    <input
                      type="text"
                      placeholder="직접입력"
                      {...register('email2', {
                        value: '',
                        onChange: (e) => {
                          setValue('emailCheck', false);
                        },
                      })}
                    />
                    <select
                      defaultValue="default"
                      {...register('emailSelect', {
                        onChange: (e) => {
                          setSelectText(e.currentTarget.value);
                          if (e.currentTarget.value == 'directly') {
                            setEmailDirectly(true);
                            setValue('email2', '');
                          } else setValue('email2', e.currentTarget.value);
                          setValue('emailCheck', false);
                        },
                      })}
                      style={{ display: emailDirectly ? 'none' : '' }}
                      className={`${styles.selectText} ${
                        selectText === '선택해주세요.' ? '' : styles.hit
                      }`}
                    >
                      <option disabled value="default">
                        선택해주세요.
                      </option>
                      <option value="naver.com">naver.com</option>
                      <option value="hanmail.net">hanmail.net</option>
                      <option value="daum.net">daum.net</option>
                      <option value="gmail.com">gmail.com</option>
                      <option value="nate.com">nate.com</option>
                      <option value="hotmail.com">hotmail.com</option>
                      <option value="outlook.com">outlook.com</option>
                      <option value="icloud.com">icloud.com</option>
                      <option value="directly">직접입력</option>
                    </select>
                    <img
                      src={drop}
                      alt="selectOpen"
                      style={{
                        display: emailDirectly ? 'none' : '',
                        pointerEvents: 'none',
                      }}
                    />
                    <img
                      src={cancel}
                      alt="cancel"
                      style={{ display: !emailDirectly ? 'none' : '' }}
                      onClick={() => {
                        setEmailDirectly(false);
                        setValue('emailCheck', false);
                        setValue('email2', '');
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={styles.duplicateBtnWrap}
                onClick={handleEmailCheck}
                {...register('emailCheck', {
                  value: false,
                  validate: () =>
                    watch().emailCheck !== true
                      ? '이메일 중복 확인을 해주세요.'
                      : true,
                })}
              >
                중복확인
              </div>
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>사업자번호</span>
              <span>*</span>
            </div>
            <div className={styles.businessWrap}>
              <input
                type="text"
                disabled={watch().isBusinessOk ? true : false}
                placeholder="사업자번호를 입력해 주세요."
                {...register('companyNum', {
                  value: '',
                  required: '사업자번호를 입력해 주세요.',
                })}
              />
              <div
                className={styles.requestBtnWrap}
                onClick={() => {
                  handleBusiness(watch().companyNum);
                }}
                {...register('isBusinessOk', {
                  value: false,
                  validate: () =>
                    watch().isBusinessOk !== true
                      ? '사업자번호 인증을 완료해 주세요.'
                      : true,
                })}
              >
                인증요청
              </div>
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>사업자등록증(선택)</span>
            </div>
            <div className={styles.businessNumberFileWrap}>
              {imageBinary
                .filter((src) => src !== 'deleted')
                .map((image, idx) => (
                  <input key={idx} type="text" disabled value={image.name} />
                ))}
              {imageBinary.length === 0 && (
                <input
                  type="text"
                  placeholder="파일을 선택해 주세요."
                  disabled
                />
              )}
              <div className={styles.fileBtnWrap}>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf"
                  onChange={(e) => {
                    let maxSize = 20 * 1024 * 1024;
                    let files: any = e.currentTarget.files;
                    if (!files!.length) return;
                    const file = files[0];
                    if (file.size > maxSize)
                      return alert('파일첨부 사이즈는 20MB 이내로 가능합니다.');
                    encodeFileToBase64(file)
                      .then((value: any) => {
                        setImageBinary([file]);
                        setImageSrc([value]);
                      })
                      .catch((error: any) => {
                        console.error('Error encoding file:', error);
                      });
                    // let readers = [];
                    // let binaryList = [];
                    // for (let i = 0; i < 1 - imageBinary.length; i++) {
                    //   if (!files![i]) break;
                    //   binaryList.push(files![i]);
                    // }
                    // setImageBinary([...imageBinary, ...binaryList]);
                    // for (let i = 0; i < files!.length; i++) {
                    //   readers.push(encodeFileToBase64(files![i]));
                    // }
                    // Promise.all(readers).then((values) => {
                    //   let list = [];
                    //   if (values.length + imageSrc.length > 1) {
                    //     alert('최대 1장까지만 첨부 가능합니다.');
                    //   }
                    //   for (let i = 0; i < 1 - imageSrc.length; i++) {
                    //     if (!values[i]) break;
                    //     list.push(values[i]);
                    //   }
                    //   setImageSrc([...imageSrc, ...list]);
                    // });
                  }}
                />
                <div className={styles.fileBtn}>
                  <span>파일선택</span>
                </div>
              </div>
            </div>
            <span className={styles.businessNotice}>
              *지금 사업자등록증을 등록하지 않아도 마이페이지에서 등록할 수
              있습니다.
            </span>
          </div>
          <div className={styles.agreementFrame}>
            <div className={styles.title}>
              <span>회원가입약관</span>
              <span>*</span>
            </div>
            <div className={styles.agreementWrap}>
              <JoinAgreement type={1} />
            </div>
            <div
              className={styles.customCheckbox}
              {...register('agreementTerms', {
                required: '회원가입약관을 동의해 주세요.',
              })}
            >
              <input
                type="checkbox"
                id="agree1"
                onClick={() => {
                  setValue('agreementTerms', !watch().agreementTerms);
                }}
              />
              <label htmlFor="agree1">
                <span>동의합니다.</span>
              </label>
            </div>
          </div>
          <div className={styles.agreementFrame}>
            <div className={styles.title}>
              <span>개인정보취급방침</span>
              <span>*</span>
            </div>
            <div className={styles.agreementWrap}>
              <JoinAgreement type={2} />
            </div>
            <div
              className={styles.customCheckbox}
              {...register('agreementPrivacy', {
                required: '개인정보취급방침을 동의해 주세요.',
              })}
            >
              <input
                type="checkbox"
                id="agree2"
                onClick={() => {
                  setValue('agreementPrivacy', !watch().agreementPrivacy);
                }}
              />
              <label htmlFor="agree2">
                <span>동의합니다.</span>
              </label>
            </div>
          </div>
          <button className={styles.joinBtnWrap}>회원가입</button>
        </form>
      </div>
      <Footer />
      {joinSuccessOpen && <JoinComapnySuccess closer={setJoinSuccessOpen} />}
    </>
  );
}

export default JoinCompany;
