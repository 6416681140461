import styles from './CompanyProfileAlert.module.scss';
import no from '../../assets/companyProfile/no.png';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';

function CompanyProfileAlert(props: any) {
  const navigate = useNavigate();
  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // 쿠키 유무 확인
  useEffect(() => {
    if (cookies['today-close']) setHasCookie(true);
    else setHasCookie(false);
  }, []);

  const todayClose = () => {
    const expires = getExpiredDate(1);
    setCookies('today-close', true, { path: '/', expires });
    props.closer(false);
    navigate('/');
  };

  // 쿠키의 유효기한을 지정하는 함수
  const getExpiredDate = (days: number) => {
    const date = new Date();

    // 현재시간의 날짜에 days 만큼 더하여 유효기간을 지정
    date.setDate(date.getDate() + days);
    return date;
  };

  return (
    <>
      {!hasCookie && (
        <div
          className={`${styles.profileInputModal} ${
            isDesktop ? '' : styles.isMobile
          }`}
        >
          <div className={styles.profileInputContent}>
            <img src={no} alt="no" />
            <div className={styles.noInputTextWrap}>
              아직 회사정보가
              <br />다 입력되지 않았어요🥲
            </div>
            <div className={styles.alertWrap}>
              <div>
                <span>지금 바로 회사정보를</span>
                <br />
                <span>입력하고 </span>
                <span className={styles.alertMsg}>파트너 인증</span>
                <span>을 받아보세요!</span>
              </div>
            </div>
            <div
              className={styles.directBtnWrap}
              onClick={() => {
                navigate('/mypage/company-profile');
              }}
            >
              마이페이지 바로가기
            </div>
            <div className={styles.closeBtnWrap} onClick={todayClose}>
              오늘 하루 보지 않기
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CompanyProfileAlert;
