import styles from './Home.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import main from '../../assets/home/main.png';
import partner from '../../assets/home/partner.png';
import category1 from '../../assets/home/category1.png';
import category2 from '../../assets/home/category2.png';
import category3 from '../../assets/home/category3.png';
import category4 from '../../assets/home/category4.png';
import category5 from '../../assets/home/category5.png';
import partnerDetail from '../../assets/home/partnerDetail.png';
import business1 from '../../assets/home/business1.png';
import business2 from '../../assets/home/business2.png';
import business3 from '../../assets/home/business3.png';
import chat from '../../assets/home/chat.png';
import estimate from '../../assets/home/estimate.png';
import wish from '../../assets/home/wish.png';
import notice from '../../assets/home/notice.png';
import mypage from '../../assets/home/mypage.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isDesktop, isMobile } from 'react-device-detect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';

function Home() {
  const navigate = useNavigate();
  const mainRef = useRef<HTMLDivElement>(null);
  const [mainTop, setMainTop] = useState(true);

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (!mainRef.current) return;
        if (window.scrollY > mainRef.current.offsetTop) setMainTop(false);
        else setMainTop(true);
      }, 500),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  return (
    <>
      <Header mainTop={mainTop} />
      <div
        className={`${styles.mainContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <img src={main} alt="main" />
        <div className={styles.mainTextWrap}>
          <p>
            빠르게 제조업체를 매칭하다,
            <br /> PARTNER
          </p>
        </div>
      </div>
      <div
        ref={mainRef}
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <div className={styles.mainWrap}>
          <div className={styles.partnerContainer}>
            <div className={styles.partnerWrap}>
              <div className={styles.left}>
                <p className={styles.title}>파트너 찾기</p>
                <p>
                  지금 파트너에서 원하는
                  <br />
                  식품제조업체를 찾아보세요.
                </p>
                <p>
                  원하는 파트너를 직접 찾아 온라인으로
                  <br />
                  간편하게 상담 요청할 수 있습니다.
                </p>
                <div
                  className={styles.directBtnWrap}
                  onClick={() => navigate('/partner')}
                >
                  바로가기
                </div>
              </div>
              <div className={styles.right}>
                <img src={partner} alt="partner" />
              </div>
            </div>
          </div>
          <div className={styles.categoryContainer}>
            <p className={styles.title}>카테고리</p>
            <p>
              카테고리 기반으로
              {isMobile && <br />} 파트너를 찾아볼 수 있어요.
            </p>
            <p>
              제조 서비스, 지역, 보관 방식 등 다양한 카테고리를
              <br /> 통해 원하는 파트너를 찾아보세요.
            </p>
            <div className={styles.imgWrap}>
              <div>
                <img src={category1} alt="category" />
                <p>농산물</p>
              </div>
              <div>
                <img src={category2} alt="category" />
                <p>축산물</p>
              </div>
              <div>
                <img src={category3} alt="category" />
                <p>수산물</p>
              </div>
              <div>
                <img src={category4} alt="category" />
                <p>서울특별시</p>
              </div>
              <div>
                <img src={category5} alt="category" />
                <p>부산광역시</p>
              </div>
            </div>
          </div>
          {isDesktop ? (
            <div className={styles.detailContainer}>
              <img src={partnerDetail} alt="partnerDetail" />
              <div className={styles.right}>
                <p className={styles.title}>상세페이지</p>
                <p>
                  회사소개, 생산제품 등<br /> 회사정보를 한눈에
                  {!isMobile && <br />} 볼 수 있어요.
                </p>
              </div>
            </div>
          ) : (
            <div className={styles.detailContainer}>
              <div className={styles.right}>
                <p className={styles.title}>상세페이지</p>
                <p>
                  회사소개, 생산제품 등<br /> 회사정보를 한눈에 볼 수 있어요.
                </p>
              </div>
              <img src={partnerDetail} alt="partnerDetail" />
            </div>
          )}

          <div className={styles.businessOutContainer}>
            <div className={styles.businessContainer}>
              <div className={styles.left}>
                <p className={styles.title}>파트너사</p>
                <p>
                  파트너와 함께 성장하고
                  <br /> 있는 기업들입니다.
                </p>
              </div>
              <div className={styles.right}>
                <div className={styles.imgWrap}>
                  <img src={business1} alt="business1" />
                  <img src={business2} alt="business2" />
                  <img src={business3} alt="business3" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.chatContainer}>
            <div className={styles.left}>
              <div className={styles.expectedTitleWrap}>
                <p className={styles.title}>1:1 문의하기</p>
                {/* <span className={styles.expectedTitle}>출시예정</span> */}
              </div>
              <p>
                샘플요청과 견적요청도 <br /> 채팅으로 편리하게!
              </p>
              <p>
                채팅을 통해 샘플요청과 견적요청을
                <br /> 빠르고 편리하게 받아보세요!
                <br />
                파트너의 견적이 마음에 들었다면
                <br /> 견적서와 계약서도 받아볼 수 있어요.
              </p>
            </div>
            <div className={styles.right}>
              <img src={chat} alt="chat" />
            </div>
          </div>
          {isDesktop ? (
            <div className={styles.estimateContainer}>
              <img src={estimate} alt="estimate" />
              <div className={styles.right}>
                <div className={styles.expectedTitleWrap}>
                  <p className={styles.title}>견적서 & 계약서</p>
                  {/* <span className={styles.expectedTitle}>출시예정</span> */}
                </div>
                <p>
                  채팅에서 받은 견적서와 <br /> 계약서를 한눈에!
                </p>
                <p>
                  더 이상 번거롭게 서류를 정리하지
                  <br /> 않아도 돼요. 이제는 마이페이지에
                  <br /> 서 내가 받은 서류들을 손쉽게
                  <br /> 관리해 보세요.
                </p>
              </div>
            </div>
          ) : (
            <div className={styles.estimateContainer}>
              <div className={styles.right}>
                <div className={styles.expectedTitleWrap}>
                  <p className={styles.title}>견적서 & 계약서</p>
                  {/* <span className={styles.expectedTitle}>출시예정</span> */}
                </div>
                <p>
                  채팅에서 받은 견적서와 <br /> 계약서를 한눈에!
                </p>
                <p>
                  더 이상 번거롭게 서류를 정리하지 않아도 돼요. <br /> 이제는
                  마이페이지에서 내가 받은 서류들을 손쉽게
                  <br /> 관리해 보세요.
                </p>
              </div>
              <img src={estimate} alt="estimate" />
            </div>
          )}

          <div className={styles.wishOutContainer}>
            <div className={styles.wishContainer}>
              <div className={styles.left}>
                <p className={styles.title}>관심있는 파트너</p>
                <p>
                  최근에 본 파트너
                  <br /> 좋아요 한 파트너
                </p>
                <p>
                  마이페이지에 저장된 파트너에게
                  <br /> 견적을 문의해 보세요!
                </p>
              </div>
              <img src={wish} alt="wish" />
            </div>
          </div>
          <div className={styles.etcContainer}>
            <p>더 많은 기능들</p>
            <div>
              <div className={styles.left}>
                <p className={styles.title}>공지사항</p>
                <p>
                  도움이 되는 파트너의 최신 소식을 가장 빠르게{' '}
                  {!isMobile && <br />}
                  알아보세요.
                </p>
                <img src={notice} alt="notice" />
              </div>
              <div className={styles.right}>
                <p className={styles.title}>마이페이지</p>
                <p>
                  파트너에서 손쉽게 나의 계정 정보를 <br /> 수정해 보세요.
                </p>
                <img src={mypage} alt="mypage" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Home;
