import Header from '../../components/Header/Header';
import styles from './PartnerPreview.module.scss';
import company from '../../assets/partnerDetail/company.png';
import copy from '../../assets/partnerDetail/copy.png';
import prev from '../../assets/partnerDetail/prev.png';
import heart from '../../assets/partnerList/heart.png';
import filtercheck from '../../assets/partnerDetail/filtercheck.png';
import heartblack from '../../assets/partnerList/heartblack.png';
import share from '../../assets/partnerList/share.png';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import Footer from '../../components/Footer/Footer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import unablePrev from '../../assets/partnerList/unablePrev.png';
import ableNext from '../../assets/partnerList/ableNext.png';
import nocompany from '../../assets/partnerList/nocompany.png';
import { ICompanyDetailType } from '../PartnerDetail/PartnerDetail';
import axiosClient from '../../libs/axiosClient';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import ScrollUp from '../../components/ScrollUp/ScrollUp';
import { isDesktop, isMobile } from 'react-device-detect';
import PartnerPreviewInfo from '../../components/PartnerPreviewInfo/PartnerPreviewInfo';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PartnerPreview(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [naviMenu, setNaviMenu] = useState('회사소개');
  const [productFilter, setProductFilter] = useState('최근등록순');
  const [currentCompanyImg, setCurrentCompanyImg] = useState();
  const [companyImgList, setCompanyImgList] = useState([]);
  const [partnerPageList, setPartnerPageList] = useState([1]);
  const [partnerPage, setPartnerPage] = useState(1);
  const [allPartnerCount, setAllPartnerCount] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [filterMenu, setFilterMenu] = useState([
    '최근등록순',
    '용량높은순',
    '용량낮은순',
  ]);
  const [companyProduct, setCompanyProduct] = useState([]);
  const [companyDetail, setCompanyDetail] = useState<ICompanyDetailType>();
  const [preview, setPreview] = useState<any>();
  const [isPdf, setIsPdf] = useState(false);

  useEffect(() => {
    axiosClient
      .get(`/member-manufacture/${params.partnerId}`)
      .then((res) => {
        setCompanyDetail(res.data);
        setCompanyImgList(res.data.img.data);
        const cert = res.data.company_cert_file;
        if (cert === null || cert === '' || cert === undefined) return;
        if (
          cert.slice(-3) === 'pdf' &&
          !location.state.company_cert_file?.length
        )
          setIsPdf(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!location.state) return;
    if (location.state.img && location.state.img.length > 0)
      setCurrentCompanyImg(location.state.img[0].img);
    setPreview(location.state);
    const cert = location.state.company_cert_file;
    if (cert) {
      if (!cert[0]) return setIsPdf(false);
      if (cert[0].includes('application/pdf')) setIsPdf(true);
      else if (cert.slice(-3) === 'pdf') setIsPdf(true);
      else setIsPdf(false);
    } else setIsPdf(false);
  }, []);

  useEffect(() => {
    if (!currentCompanyImg) setCurrentCompanyImg(companyImgList[0]);
  }, [companyImgList]);

  useEffect(() => {
    if (!preview) return;
    handleNull(preview);
  }, [preview]);

  useEffect(() => {
    if (!companyDetail) return;
    handleNull(companyDetail);
  }, [companyDetail]);

  const handleNull = (data: any) => {
    for (const key in data) {
      //직원인원은 0으로 와서 추가 0명적어도 그땐 string으로 옴
      if (data[key] === null || data[key] === 0) {
        data[key] = '';
      }
    }
  };

  function handlePaginationDown() {
    if (partnerPage == 1) return;
    setPartnerPage((prev) => prev - 1);
  }

  function handlePaginationUp() {
    if (partnerPage >= allPartnerCount / 15) return;
    setPartnerPage((prev) => prev + 1);
  }

  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt((partnerPage / 5).toString()) * 5 + 1;
    if (!(partnerPage % 5)) startPage -= 5;
    let endPage =
      startPage + 4 <
      allPartnerCount / 15 + (allPartnerCount % 15 !== 0 ? 1 : 0)
        ? startPage + 4
        : allPartnerCount / 15 + (allPartnerCount % 15 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    let lastPage = partnerPage === Math.ceil(allPartnerCount / 15);
    if (allPartnerCount === 0) lastPage = true;
    setIsLastPage(lastPage);
    setPartnerPageList([...list]);
  }, [allPartnerCount, partnerPage]);

  const [swiper, setSwiper] = useState<SwiperCore>();
  const movingBarRef = useRef<HTMLDivElement>(null);

  function handleNavigationBarHide(
    e: React.MouseEvent<HTMLUListElement, MouseEvent>,
  ) {
    if (!movingBarRef.current) return;
    movingBarRef.current.style.width = '0px';
  }

  const handleFilter = (filter: string) => {
    setProductFilter(filter);
    // filterMenu 용량높은순/낮은순 weight 기준
    // filterMenu 최근등록순 업데이트순 (DB에서 전달오는 순)
    switch (filter) {
      case '용량높은순':
        return setCompanyProduct(
          companyProduct
            .slice()
            .sort((a: any, b: any) => parseInt(b.weight) - parseInt(a.weight)),
        );
      case '용량낮은순':
        return setCompanyProduct(
          companyProduct
            .slice()
            .sort((a: any, b: any) => parseInt(a.weight) - parseInt(b.weight)),
        );
      case '최근등록순':
        return setCompanyProduct(
          companyProduct
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.date).getTime() - new Date(a.date).getTime(),
            ),
        );
      default:
        return filterMenu;
    }
  };

  useEffect(() => {
    if (productFilter === '최근등록순') {
      setCompanyProduct(
        companyProduct
          .slice()
          .sort(
            (a: any, b: any) =>
              new Date(b.date).getTime() - new Date(a.date).getTime(),
          ),
      );
    }
  }, []);

  return (
    <>
      <Header />
      <div
        className={`${styles.mainContentWrap} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <div className={styles.leftWrap}>
          <img
            className={`${styles.thumbnail} ${
              companyImgList.length > 2 ? styles.listMargin : ''
            }`}
            src={currentCompanyImg ? currentCompanyImg : nocompany}
            alt="company"
          />

          <div className={styles.companyImgListWrap}>
            <Swiper
              className={styles.companyImgSwiper}
              slidesPerView={isDesktop ? 4 : 2}
              spaceBetween={8}
              onSwiper={setSwiper}
            >
              {preview?.img && preview?.img.length > 4 && (
                <div className={styles.companyPagination}>
                  <div
                    className={styles.leftSwiper}
                    onClick={() => swiper?.slidePrev()}
                  >
                    <img src={prev} alt="prev" />
                  </div>
                  <div
                    className={styles.rightSwiper}
                    onClick={() => swiper?.slideNext()}
                  >
                    <img src={prev} alt="prev" />
                  </div>
                </div>
              )}
              {companyImgList.length > 4 && (
                <div className={styles.companyPagination}>
                  <div
                    className={styles.leftSwiper}
                    onClick={() => swiper?.slidePrev()}
                  >
                    <img src={prev} alt="prev" />
                  </div>
                  <div
                    className={styles.rightSwiper}
                    onClick={() => swiper?.slideNext()}
                  >
                    <img src={prev} alt="prev" />
                  </div>
                </div>
              )}
              {companyImgList &&
                companyImgList.map((img: any, idx: any) => (
                  <SwiperSlide className={styles.imgListSwiperWrap} key={idx}>
                    <img
                      className={`${styles.currentImgWrap} ${
                        currentCompanyImg === img ? styles.current : ''
                      }`}
                      onClick={() => setCurrentCompanyImg(img)}
                      src={img ? img : nocompany}
                      alt="company"
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          {isMobile && companyDetail && (
            <PartnerPreviewInfo
              companyDetail={companyDetail}
              preview={preview}
            />
          )}
          <div className={styles.companyInfoNavigationWrap}>
            <ul onMouseLeave={(e) => handleNavigationBarHide(e)}>
              <li className={naviMenu === '회사소개' ? styles.hit : ''}>
                회사소개
              </li>
              <li className={naviMenu === '생산제품' ? styles.hit : ''}>
                생산제품
              </li>
              {/* <div ref={movingBarRef} className={styles.movingBar}></div> */}
            </ul>
          </div>
          {naviMenu === '회사소개' && preview && (
            <>
              <div className={styles.companyIntroWrap}>
                <p className={styles.companyIntroTitle}>회사소개글</p>
                {preview.page === '정보' ? (
                  <>
                    {companyDetail?.introduce1 &&
                      companyDetail?.introduce1
                        .split('\n')
                        .map((line: any, idx: any) => (
                          <p key={idx}>
                            {line}
                            <br />
                          </p>
                        ))}
                  </>
                ) : (
                  <>
                    {(!preview.introduce1 && !companyDetail?.introduce1) ||
                    (!preview.introduce1 && companyDetail?.introduce1) ? (
                      ''
                    ) : (
                      <>
                        {preview.introduce1 !== null &&
                          preview.introduce1
                            .split('\n')
                            .map((line: any, idx: any) => (
                              <p key={idx}>
                                {line}
                                <br />
                              </p>
                            ))}
                      </>
                    )}
                  </>
                )}
              </div>
              {preview.page === '정보' ? (
                <>
                  {companyDetail?.introduce2 && (
                    <div className={styles.companyIntroWrap}>
                      <p className={styles.companyIntroTitle}>경영이념</p>
                      {companyDetail?.introduce2
                        .split('\n')
                        .map((line: any, idx: any) => (
                          <p key={idx}>
                            {line}
                            <br />
                          </p>
                        ))}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {(!preview.introduce2 && !companyDetail?.introduce2) ||
                  (!preview.introduce2 && companyDetail?.introduce2) ? (
                    ''
                  ) : (
                    <div className={styles.companyIntroWrap}>
                      <p className={styles.companyIntroTitle}>경영이념</p>
                      {preview.introduce2 !== null &&
                        preview.introduce2
                          .split('\n')
                          .map((line: any, idx: any) => (
                            <p key={idx}>
                              {line}
                              <br />
                            </p>
                          ))}
                    </div>
                  )}
                </>
              )}
              {preview.page === '정보' ? (
                <>
                  {companyDetail?.introduce3 && (
                    <div className={styles.companyIntroWrap}>
                      <p className={styles.companyIntroTitle}>생산설비</p>
                      {companyDetail?.introduce3
                        .split('\n')
                        .map((line: any, idx: any) => (
                          <p key={idx}>
                            {line}
                            <br />
                          </p>
                        ))}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {(!preview.introduce3 && !companyDetail?.introduce3) ||
                  (!preview.introduce3 && companyDetail?.introduce3) ? (
                    ''
                  ) : (
                    <div className={styles.companyIntroWrap}>
                      <p className={styles.companyIntroTitle}>생산설비</p>
                      {preview.introduce3 !== null &&
                        preview.introduce3
                          .split('\n')
                          .map((line: any, idx: any) => (
                            <p key={idx}>
                              {line}
                              <br />
                            </p>
                          ))}
                    </div>
                  )}
                </>
              )}
              {(preview?.company_cert_file?.length ||
                companyDetail?.company_cert_file) && (
                <div className={styles.companyIntroWrap}>
                  <p className={styles.companyIntroTitle}>보유인증서</p>
                  {((!isPdf && preview?.company_cert_file?.length) ||
                    (!isPdf && companyDetail?.company_cert_file)) && (
                    <img
                      src={
                        preview.company_cert_file?.length
                          ? preview.company_cert_file
                          : companyDetail?.company_cert_file
                      }
                      alt="certfile"
                    />
                  )}
                  {isPdf && (
                    <span className={styles.pdfInfo}>
                      pdf는 미리보기를 제공하지 않습니다.
                    </span>
                  )}
                </div>
              )}
            </>
          )}
          {naviMenu === '생산제품' && (
            <div className={styles.companyProductWrap}>
              <div className={styles.companyProductTop}>
                <div className={styles.left}>
                  <span>생산제품</span>
                  <span>{companyDetail?.product.count}개의 제품</span>
                </div>
                <div className={styles.right}>
                  {filterMenu &&
                    filterMenu.map((filter, idx) => (
                      <div
                        key={idx}
                        className={styles.filterMenuWrap}
                        onClick={() => handleFilter(filter)}
                      >
                        <div className={styles.imgWrap}>
                          {productFilter === filter && (
                            <img src={filtercheck} alt="filtercheck" />
                          )}
                        </div>
                        <p
                          className={`${styles.filterMenu} ${
                            productFilter === filter ? styles.hit : ''
                          }`}
                        >
                          {filter}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
              <div className={styles.companyProductList}>
                {companyProduct.map((product, idx) => (
                  <div key={idx} className={styles.companyProductFrame}>
                    <img src={company} alt="product" />
                    <div className={styles.productContentWrap}>
                      <p className={styles.title}>product.title</p>
                      <div className={styles.productInfo}>
                        <p>product.category</p>
                        <p>product.standard</p>
                        <p>product.weight</p>
                      </div>
                      <div className={styles.tagWrap}>
                        {/* {product.tag &&
                          product.tag.map((tag, idx) => (
                            <span key={idx}>
                              {tag}
                              {idx + 1 === product.tag.length ? '' : ','}{' '}
                            </span>
                          ))} */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {naviMenu === '생산제품' && (
            <div className={styles.paginationWrap}>
              <img
                style={{ transform: partnerPage === 1 ? '' : 'rotate(180deg)' }}
                src={partnerPage === 1 ? unablePrev : ableNext}
                alt="prev"
                onClick={handlePaginationDown}
              />
              <ul>
                {partnerPageList &&
                  partnerPageList.map((page) => (
                    <li
                      key={page}
                      onClick={() => {
                        setPartnerPage(page);
                      }}
                      className={page == partnerPage ? styles.hit : ''}
                    >
                      <p>{page}</p>
                    </li>
                  ))}
              </ul>
              <img
                style={{ transform: isLastPage ? 'rotate(180deg)' : '' }}
                src={isLastPage ? unablePrev : ableNext}
                alt="next"
                onClick={handlePaginationUp}
              />
            </div>
          )}
        </div>
        {isDesktop && companyDetail && (
          <PartnerPreviewInfo companyDetail={companyDetail} preview={preview} />
        )}
        <ScrollUp />
      </div>
      <Footer />
    </>
  );
}

export default PartnerPreview;
