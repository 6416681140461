function JoinAgreement({ type }: any) {
  return (
    <>
      {type === 1 && (
        <div
          dangerouslySetInnerHTML={{
            __html: `제1장 서비스 이용<br /><br />
            제1조 (목적)<br /><br />`,
          }}
        ></div>
      )}
      {type === 2 && (
        <div
          dangerouslySetInnerHTML={{
            __html: `주식회사 - 개인정보 처리방침<br/><br/>
            주식회사 -(이하 -)는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 두고 있습니다. -는 개인정보 처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.`,
          }}
        ></div>
      )}
    </>
  );
}

export default JoinAgreement;
