import styles from './EstimateImgModal.module.scss';
import close from '../../assets/productEdit/close.png';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useEffect, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';

function EstimateImgModal(props: any) {
  const modalRef = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number>(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!window.visualViewport) return;
    setWidth(window.visualViewport?.width - 42);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const certArray = [];
  for (let i = 1; i <= numPages; i++) {
    certArray.push(
      <Page
        key={i}
        width={isDesktop ? 500 : width}
        pageNumber={i}
        renderTextLayer={false}
      />,
    );
  }

  const handleOutClick = (event: any) => {
    if (!modalRef.current) return;
    if (modalRef && !modalRef.current.contains(event.target))
      props.closer(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutClick);
    return () => {
      document.removeEventListener('mousedown', handleOutClick);
    };
  }, [modalRef]);

  return (
    <div className={`${styles.modal} ${isDesktop ? '' : styles.isMobile}`}>
      <div ref={modalRef} className={styles.content}>
        <img
          className={styles.closeBtnWrap}
          src={close}
          alt="close"
          onClick={() => props.closer(false)}
        />
        <div className={styles.contentForm}>
          <div className={styles.imgWrap}>
            {props.estimateImg.slice(-3) === 'pdf' ? (
              <Document
                file={props.estimateImg}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <div
                  style={{
                    overflow: 'hidden',
                    borderRadius: '6px',
                    boxSizing: 'border-box',
                  }}
                >
                  {certArray}
                </div>
              </Document>
            ) : (
              <img src={props.estimateImg} alt="estimate" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EstimateImgModal;
