import styles from './Chat.module.scss';
import Header from '../../components/Header/Header';
import profile from '../../assets/partnerList/profile.png';
import Footer from '../../components/Footer/Footer';
import { useEffect, useRef, useState } from 'react';
import plus from '../../assets/companyInfo/plus.png';
import minus from '../../assets/companyInfo/minus.png';
import prev from '../../assets/chat/prev.png';
// import plus from '../../assets/chat/plus.png';
import exit from '../../assets/chat/exit.png';
import file from '../../assets/chat/file.png';
import send from '../../assets/chat/send.png';
import download from '../../assets/chat/download.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITokenInfoType, loginCheck } from '../../auth/auth';
import axiosClient from '../../libs/axiosClient';
import ChatFileModal from '../../components/ChatFileModal/ChatFileModal';
import ChatFileDetailModal from '../../components/ChatFileDetailModal/ChatFileDetailModal';
import { isDesktop, isMobile } from 'react-device-detect';
import jwt_decode from 'jwt-decode';

interface chatList {
  user: string;
  msg: string;
  date: string | Date;
  read: string;
  msgForm: string;
  site: string;
  img: any;
}

interface IUserType {
  loginToken: string;
  createdAt: string;
  memberType: string;
  disablePushNotification: boolean;
  id: string;
  profileImageUrl: string;
  updatedAt: string;
  username: SVGStringList;
}

export interface IChatType {
  bannedUsers: string;
  category: string;
  createdAt: string;
  data: any;
  hideMessagesBeforeJoin: boolean;
  id: string;
  imageUrl: string;
  invitationCode: string;
  isForzen: boolean;
  lastMessage: {
    channelId: string;
    createdAt: string;
    data: string;
    fileUrl: string;
    id: string;
    isDeleted: boolean;
    mentions: string;
    parentMessage: string;
    parentMeesageId: string;
    profileImageUrl: string;
    reactions: string;
    text: string;
    title: string;
    translations: string;
    type: string;
    updatedAt: string;
    userId: string;
    username: string;
  };
  lastReadAt: string;
  lastSendAt: string;
  maxMemberCount: string;
  memberCount: string;
  members: any;
  // {
  //   createAt: string;
  //   data: {
  //     memberType: string;
  //   };
  //   disabledPushNotification: boolean;
  //   id: string;
  //   lastReadAt: string;
  //   lastSentAt: string;
  //   memberInfo: string;
  //   profileImageUrl: string;
  //   updatedAt: string;
  //   userName: string;
  // };
  mutedUsers: string;
  name: string;
  ownerId: string;
  privateData: string;
  privateTag: string;
  pushNotificationDisabled: boolean;
  pushNotificationSoundAOS: string;
  pushNotificationSoundIOS: string;
  subcategory: string;
  type: string;
  unreadCount: number;
  updatedAt: string;
}

declare global {
  interface Window {
    TalkPlus: any;
  }
}

const client = new window.TalkPlus.Client({
  appId: '0776d1de-af7f-4972-8cc8-28e82c77d782',
});

function Chat() {
  const linkRegex = /^(https?|ftp):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i;
  const urlRegex = /^http[s]?:\/\/([\S]{3,})/i;
  // 'gif', 'mp4', 'mp3'
  const fileExtension = ['jpg', 'jpeg', 'png', 'gif'];
  const navigate = useNavigate();
  const date = new Date();
  const location = useLocation();
  let testList: any[] = [];
  const groupParams = new URLSearchParams(location.search);
  let channelId = groupParams.get('chat-group');
  const prevRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const [mEstiOpen, setMEstiOpen] = useState(false);
  const [mSampleOpen, setMSampleOpen] = useState(false);
  const [mobileFileModalOpen, setMobileFileModalOpen] = useState(false);
  const [fileDetailModalOpen, setFileDetailModalOpen] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [myUserInfo, setMyUserInfo] = useState<any>();
  const [otherUserInfo, setOtherUserInfo] = useState<any>();
  const [userInfo, setUserInfo] = useState<IUserType>();
  const [userType, setUserType] = useState('');
  const [selectChat, setSelectChat] = useState('');
  const [sendChat, setSendChat] = useState('');
  const [selectFileId, setSelectFileId] = useState('');
  const [imgType, setImgType] = useState('');
  const [fileType, setFileType] = useState('');
  const [imageSrc, setImageSrc] = useState<any[]>([]);
  const [imageBinary, setImageBinary] = useState<any[]>([]);
  const [selectChatInfo, setSelectChatInfo] = useState<IChatType>();
  const [morePrevChat, setMorePrevChat] = useState(1); // 이전 메시지 클릭하다가 더 이전 없는지 확인 용도
  const [isPrevChat, setIsPrevChat] = useState(false);
  const [newChat, setNewChat] = useState<IChatType[]>([]);
  const [chatList, setChatList] = useState<IChatType[]>([]);
  const [previewList, setPreviewList] = useState<IChatType[]>([]);
  // 초기 견적 / 샘플 링크 값
  const [initEstimateSite, setInitEstimateSite] = useState('');
  const [initSampleSite, setInitSampleSite] = useState('');
  // 변경되는 견적 / 샘플 링크 값
  const [estimateSite, setEstimateSite] = useState('');
  const [estimateDate, setEstimateDate] = useState('업데이트없음');
  const [sampleSite, setSampleSite] = useState('');
  const [sampleDate, setSampleDate] = useState('업데이트없음');

  useEffect(() => {
    if (!window.location.search && selectChatInfo) setSelectChatInfo(undefined);
  }, [window.location.search]);

  useEffect(() => {
    // const token = localStorage.getItem('token');
    // if (token == null || token == 'null') return;
    // try {
    //   const decodedUserInfo: ITokenInfoType = jwt_decode(token!);

    //   console.log(decodedUserInfo);
    // } catch (err) {}
    // 유저 생성
    axiosClient
      .post('/chat/user/create', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        getLoginToken();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 로그인토큰 얻기
  const getLoginToken = async () => {
    await axiosClient
      .post('/chat/user/login', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        // 로그인토큰 저장
        setUserToken(res.data.loginToken);
        // 사용자정보 저장
        setUserInfo(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 로그인 토큰이랑 사용자 정보로 로그인 진행
  useEffect(() => {
    if (!userInfo || !userToken) return;
    handleLogin();
  }, [userInfo, userToken]);

  // 로그인토큰으로 로그인 진행
  const handleLogin = async () => {
    try {
      await client.loginWithToken({
        userId: userInfo?.id,
        username: userInfo?.username,
        profileImageUrl: userInfo?.profileImageUrl,
        loginToken: userToken,
      });
    } catch (err) {
      console.log(err);
    }

    const isLoggedIn = client.isLoggedIn();
    // console.log(isLoggedIn);

    // 채널 목록 가져오기
    if (isLoggedIn) {
      // 이벤트 발생여부
      client.on('event', handleChannelInfo);
      getChannelList();
      let channelId = groupParams.get('chat-group');
      if (channelId) getChannelInfo(channelId);
    }
  };

  const getMessage = async (channelId: string) => {
    const msgList = await client.getMessages({
      channelId: channelId,
    });

    if (msgList) {
      setIsPrevChat(msgList.hasNext);
      setChatList(msgList.messages.reverse());
      testList = msgList.messages;
    }
  };

  const getPrevMessage = async () => {
    let channelId = groupParams.get('chat-group');
    const lastMessageId = chatList[0].id;

    const msgList = await client.getMessages({
      channelId: channelId,
      lastMessageId: lastMessageId,
    });

    if (msgList) {
      // setMorePrevChat(msgList.hasNext);
      setMorePrevChat((prev) => (prev += 1));
      setIsPrevChat(msgList.hasNext);
      const updateList = msgList.messages.reverse().concat(chatList);
      setChatList(updateList);
    }
    scrollUp();
  };

  // 채팅칠 때마다 스크롤 제일 밑으로
  // useEffect(() => {
  //   if (!chatRef.current) return;
  //   chatRef.current.scrollTo({ top: 9999, behavior: 'smooth' });
  //   if (!isPrevChat)
  //     return chatRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  // }, [chatList, selectChatInfo]);

  // useEffect(() => {
  //   // console.log(isPrevChat);
  //   // console.log(morePrevChat);
  //   // if (isPrevChat || !morePrevChat) return scrollUp();
  //   console.log('chatlist');
  // }, [chatList]);

  useEffect(() => {
    if (!selectChatInfo) return;
    scrollDown();
    if (!userInfo) return;
    const myUserInfo = selectChatInfo.members.filter((item: any) => {
      if (item.id === userInfo?.id) return item;
    });
    const opponentUserInfo = selectChatInfo.members.filter((item: any) => {
      if (item.id !== userInfo?.id) return item;
    });
    setMyUserInfo(myUserInfo[0]);
    setOtherUserInfo(opponentUserInfo[0]);
  }, [selectChatInfo]);

  const scrollUp = () => {
    if (!chatRef.current) return;
    chatRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollDown = () => {
    if (!chatRef.current) return;
    chatRef.current.scrollTo({ top: 99999, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollDown();
    if (!chatList.length) return;
    handleRead();
  }, [chatList]);

  useEffect(() => {
    scrollUp();
  }, [morePrevChat]);

  useEffect(() => {
    if (newChat.length === 0) return;
    const updateList = chatList.concat(newChat);
    setChatList(updateList);
  }, [newChat]);

  // 읽음처리
  const handleRead = async () => {
    let channelId = groupParams.get('chat-group');
    let read;
    if (channelId) {
      read = await client
        .markAsRead({ channelId: channelId })
        .catch((err: any) => {
          console.log(err);
        });
    }
    if (read) getChannelList();
  };

  // 현재 참여중인 채널 목록
  const getChannelList = async () => {
    const hideList = await client.getHiddenChannels({});
    if (!window.location.search) channelId = '';

    if (
      channelId &&
      hideList.channels.filter((item: any) => item.id === channelId)[0]
    ) {
      await client.showChannel({ channelId: channelId });
    }

    await axiosClient
      .get('/chat/room/list', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setPreviewList(res.data.channels);
        if (groupParams.get('chat-group') && window.location.search) {
          setSelectChat(
            res.data.channels.filter(
              (item: any) => item.id === groupParams.get('chat-group'),
            )[0].id,
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // const channelId = await client.getChannels({});
    // const channelArr = channelId.channels;
    // console.log(channelArr);
    // setPreviewList(channelArr);
  };

  // 특정 채널 조회
  const getChannelInfo = async (channelId: string) => {
    const getChannelInfo = await client
      .getChannel({
        channelId: channelId,
      })
      .catch((err: any) => {
        console.log(err);
        alert('메시지 리스트를 불러올 수 없습니다.');
        return navigate('/chat');
      });

    if (getChannelInfo) {
      getMessage(channelId);
      setSelectChatInfo(getChannelInfo.channel);
    }
  };

  // 채널 참여 및 생성
  const handleChannelJoin = async (channelId: string, userId: string) => {
    axiosClient
      .post(
        '/chat/room/create',
        {
          channel_id: channelId,
          login_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then(async (res) => {
        getChannelInfo(channelId);
        getMessage(channelId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChannelInfo = async (data: any) => {
    const searchParam = new URLSearchParams(window.location.search);
    const channelID = searchParam.get('chat-group');
    if (data.type === 'message') {
      console.log('message event');
      // if (data.channel.id === channelID) getMessage(data.channel.id);
      if (data.channel.id === channelID) setNewChat(data.message);
    }
    if (data.type === 'messageDeleted') {
      console.log('message deleted devent');
    }

    if (data.type === 'channelAdded') {
      console.log('new channel added with me as member');
    }

    if (data.type === 'channelChanged') {
      console.log('one of my channels changed');
      if (data.channel.id !== channelID) getChannelList();
    }

    if (data.type === 'channelRemoved') {
      console.log('one of my channels was removed');
    }

    if (data.type === 'memberAdded') {
      console.log('new channel member');
    }

    if (data.type === 'memberLeft') {
      console.log('channel member left');
      if (data.channel.id !== channelID) getChannelList();
    }

    if (data.type === 'memberBanned') {
      console.log('channel member left');
    }
  };

  // 채팅 날짜 포맷
  const chatDateFormat = (date: Date): string => {
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amOrPm = date.getHours() < 12 ? '오전' : '오후';
    return `${year}.${month}.${day} ${amOrPm} ${hours}:${minutes}`;
  };

  // 채팅리스트 포맷
  const chatListDateFormat = (date: Date): string => {
    const now = new Date();
    const year = date.getFullYear().toString().slice(-2);
    // const month = (date.getMonth() + 1).toString().padStart(2, '0');
    // const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    // padStart: 숫자앞에 0붙일때 사용, 첫 번째 파라미터를 받아 문자열 길이가 작은 경우 0으로 채워줌
    // padEnd : 뒤에서
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amOrPm = date.getHours() < 12 ? '오전' : '';

    const isToday = date.toDateString() === now.toDateString();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) {
      return `${amOrPm} ${hours}:${minutes}`;
    } else if (isYesterday) {
      return '어제';
    } else {
      return `${month}월 ${day}일`;
    }
  };

  useEffect(() => {
    if (!loginCheck()) navigate('/login');
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  // 견적 / 샘플 양식 링크 알림 전송
  const submitLinkAlert = (form: string) => {
    const category = form === '견적' ? 'estimate' : 'sample';
    axiosClient
      .post(
        '/member-client-notification/link',
        {
          category: category,
          member_client_login_id: otherUserInfo.data.loginId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  // 견적 / 샘플 양식 링크 등록
  const submitLink = (estimateSite: string, sampleSite: string) => {
    let esti = false;
    let sample = false;
    if (estimateSite === initEstimateSite && sampleSite === initSampleSite)
      return;
    if (estimateSite === initEstimateSite) esti = true;
    if (sampleSite === initSampleSite) sample = true;

    let formData = new FormData();
    if (!esti) formData.append('estimate_link', estimateSite);
    if (!sample) formData.append('sample_link', sampleSite);

    axiosClient
      .post('/auth/member-manufacture/mypage/link', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        getLink();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const linkDate = (today: any) => {
    let dateString = '';
    return (dateString = (
      today.getFullYear().toString().slice(2, 4) +
      '년 ' +
      (today.getMonth() + 1) +
      '월 ' +
      today.getDate() +
      '일 '
    ).toString());
  };

  // 견적 / 샘플 양식 링크 조회
  const getLink = () => {
    axiosClient
      .get(`/auth/member-manufacture/mypage/link?estimate_link&sample_link`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setInitEstimateSite(res.data.estimate_link);
        setInitSampleSite(res.data.sample_link);
        setEstimateSite(res.data.estimate_link);
        if (res.data.estimate_link_updated_at.charAt(0) !== '0') {
          let today = new Date(res.data.estimate_link_updated_at);
          setEstimateDate(linkDate(today));
        }
        setSampleSite(res.data.sample_link);
        if (res.data.sample_link_updated_at.charAt(0) !== '0') {
          let today = new Date(res.data.sample_link_updated_at);
          setSampleDate(linkDate(today));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userType !== 'member_manufacture') return;
    getLink();
  }, [userType]);

  const formChange = (e: any, method: string) => {
    const site = e.target.value;
    if (method === '견적') setEstimateSite(site);
    else setSampleSite(site);
  };

  // 자동 URL 처리
  const autoLinkify = (text: string) => {
    const Rexp =
      /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?/=~_|!:,.;]*)[-A-Z0-9+&@#/%=~_|])/gi;

    return text.replace(Rexp, "<a href='$1' target='_blank'>$1</a>");
  };

  useEffect(() => {
    if (!mobileFileModalOpen) {
      setMEstiOpen(false);
      setMSampleOpen(false);
    }
  }, [mobileFileModalOpen]);

  const enterForm = async (e: any, form: string) => {
    if (form === '견적' && !estimateSite.length) return;
    else if (form === '샘플' && !sampleSite.length) return;
    if (
      e.key === 'Enter' &&
      (estimateSite.trim() !== '' || sampleSite.trim() !== '')
    ) {
      if (e.nativeEvent.isComposing) return;
      if (form === '견적' && !urlRegex.test(estimateSite))
        return alert(
          '견적 형식이 올바르지 않습니다. http:// 또는 https:// 로 시작하는 url 형식',
        );
      if (form === '샘플' && !urlRegex.test(sampleSite))
        return alert(
          '샘플 형식이 올바르지 않습니다. http:// 또는 https:// 로 시작하는 url 형식',
        );
      let channelId = selectChatInfo?.id ? selectChatInfo?.id.toString() : '';
      const sendMsg = await client.sendMessage({
        channelId: channelId,
        type: 'custom',
        data: {
          custom: form,
          profile: myUserInfo.profileImageUrl,
          category: selectChatInfo?.data.member_manufacture_category,
        },
        text: form === '견적' ? `${estimateSite}` : `${sampleSite}`,
      });
      if (sendMsg) {
        const updateList = chatList.concat(sendMsg.message);
        setChatList(updateList);
        submitLink(estimateSite, sampleSite);
        submitLinkAlert(form);
        setMobileFileModalOpen(false);
      }
      // if (sendMsg) getMessage(channelId);
    }
  };

  const formSendBtn = async (form: string) => {
    if (!selectChatInfo) return;
    if (form === '견적' && !estimateSite.length) return;
    else if (form === '샘플' && !sampleSite.length) return;

    if (form === '견적' && !urlRegex.test(estimateSite))
      return alert(
        '견적 형식이 올바르지 않습니다. http:// 또는 https:// 로 시작하는 url 형식',
      );
    if (form === '샘플' && !urlRegex.test(sampleSite))
      return alert(
        '샘플 형식이 올바르지 않습니다. http:// 또는 https:// 로 시작하는 url 형식',
      );
    let channelId = selectChatInfo?.id ? selectChatInfo?.id.toString() : '';
    const sendMsg = await client.sendMessage({
      channelId: channelId,
      type: 'custom',
      data: {
        custom: form,
        profile: myUserInfo.profileImageUrl,
        category: selectChatInfo?.data.member_manufacture_category,
      },
      text: form === '견적' ? `${estimateSite}` : `${sampleSite}`,
    });
    if (sendMsg) {
      const updateList = chatList.concat(sendMsg.message);
      setChatList(updateList);
      submitLink(estimateSite, sampleSite);
      submitLinkAlert(form);
      setMobileFileModalOpen(false);
    }
    // if (sendMsg) getMessage(channelId);
    // setSendChatList((prev) => [
    //   ...prev,
    //   {
    //     user: 'company',
    //     msg: `${form}`,
    //     date: date,
    //     read: 'N',
    //     msgForm: 'form',
    //     site: form === '견적' ? `${estimateSite}` : `${sampleSite}`,
    //     img: '',
    //   },
    // ]);
  };

  const onSendBtn = async () => {
    if (sendChat.trim() === '') return setSendChat('');
    const sendChatTrim = sendChat.trim();
    let channelId = selectChatInfo?.id ? selectChatInfo?.id.toString() : '';
    const sendMsg = await client.sendMessage({
      channelId: channelId,
      type: 'text',
      text: sendChatTrim,
      // data: {
      //   imageUrl: '',
      // },
    });
    if (sendMsg) {
      const updateList = chatList.concat(sendMsg.message);
      setChatList(updateList);
    }
    // if (sendMsg) getMessage(channelId);
    setSendChat('');
  };

  const enterChat = async (e: any) => {
    if (sendChat.trim() === '') return setSendChat('');
    if (e.key === 'Enter' && !e.shiftKey && sendChat.trim() !== '') {
      e.preventDefault();
      if (e.nativeEvent.isComposing) return;
      const sendChatTrim = sendChat.trim();
      let channelId = selectChatInfo?.id ? selectChatInfo?.id.toString() : '';
      const sendMsg = await client.sendMessage({
        channelId: channelId,
        type: 'text',
        text: sendChatTrim,
      });
      if (sendMsg) {
        const updateList = chatList.concat(sendMsg.message);
        setChatList(updateList);
      }
      // if (sendMsg) getMessage(channelId);
      setSendChat('');
    }
  };

  // 선택한 대화방 초기화
  useEffect(() => {
    if (!window.location.search || window.location.search === '')
      setSelectChat('');
  }, [window.location.search]);

  // 견적서 등록
  const submitEstimate = (file: any) => {
    let imageList = [...file];
    let formData = new FormData();
    formData.append('member_client_login_id', otherUserInfo.data.loginId);
    if (typeof imageList[0] == 'object') formData.append('img', imageList[0]);
    axiosClient
      .post(`/estimate`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  // 계약서 등록
  const submitContract = (file: any) => {
    let imageList = [...file];
    let formData = new FormData();

    formData.append('member_client_login_id', otherUserInfo.data.loginId);
    if (typeof imageList[0] == 'object') formData.append('img', imageList[0]);
    axiosClient
      .post(`/contract`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (imageBinary.length < 1 || imageSrc.length < 1) return;
    onSendImg();
  }, [imageBinary, imageSrc]);

  // 이미지 전송
  const onSendImg = async () => {
    if (!imageSrc[0] || !imageBinary[0]) return;
    let channelId = selectChatInfo?.id ? selectChatInfo?.id.toString() : '';
    const sendMsg = await client.sendMessage({
      channelId: channelId,
      type: 'text',
      text: '',
      data: {
        meta: 'image',
        imgType: imgType,
        fileType: fileType,
        fileName: imageBinary[0].name,
      },
      file: imageBinary[0],
    });
    if (sendMsg) {
      const updateList = chatList.concat(sendMsg.message);
      setChatList(updateList);
      setMobileFileModalOpen(false);
      if (fileType === '견적서') submitEstimate(imageBinary);
      else if (fileType === '계약서') submitContract(imageBinary);
    }
    // if (sendMsg) getMessage(channelId);
    setImageBinary([]);
    setImageSrc([]);
    setSendChat('');
  };

  const handleFileSelect = (
    imageBinary: any,
    imageSrc: any,
    fileType: any,
    imgType: any,
  ) => {
    setImageBinary(imageBinary);
    setImageSrc(imageSrc);
    setFileType(fileType);
    setImgType(imgType);
  };

  const handleChatExit = async (channelId: string, selectChatList: any) => {
    const memberCnt = selectChatList.memberCount;
    if (memberCnt > 1) {
      const hideChannel = await client
        .hideChannel({ channelId: channelId })
        .catch((err: any) => {
          console.log(err);
        });
      if (hideChannel) {
        channelId = '';
        navigate('/chat');
        getChannelList();
      }
    } else {
      axiosClient
        .post(
          '/chat/room/quit',
          {
            channel_id: channelId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        .then((res) => {
          getChannelList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDownload = (url: string, name: string) => {
    fetch(url, { method: 'GET' })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout((_: any) => {
          window.URL.revokeObjectURL(url);
        }, 1000);
        a.remove();
      })
      .catch((err) => {
        console.error('err', err);
      });
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.contentWrap} ${isDesktop ? '' : styles.isMobile}`}
      >
        <div className={styles.chatListWrap}>
          <div
            className={`${styles.chatListCustomScroll} ${
              selectChatInfo && userType === 'member_manufacture'
                ? styles.companyChatList
                : ''
            }`}
          >
            {previewList &&
              previewList.map((item, idx) => {
                // 날짜포맷 첫 생성시 createdAt / 채팅내용 있는 채팅방 마지막 채팅 시간
                const chatDate = item.lastMessage
                  ? item.lastMessage.createdAt
                  : item.createdAt;
                const format = chatDateFormat(new Date(chatDate));
                const listDate = chatListDateFormat(new Date(chatDate));
                const time = format.slice(9, 11);
                const timeAm = format.slice(9, 17);
                const timePm = format.slice(12, 17);

                let lastText = '';
                if (item.lastMessage)
                  lastText = item.lastMessage.text
                    ? item.lastMessage.text
                    : '파일을 보냈습니다.';

                // 상대 채팅 유저
                let opponentUserProfile = '';
                let opponentUserName = '';
                let opponentUserId = '';
                let opponentUserLoginId = '';
                if (item.members.length > 1) {
                  opponentUserProfile = item.members.filter(
                    (item: any) => item.username !== userInfo?.username,
                  )[0].profileImageUrl;
                  opponentUserName = item.members.filter(
                    (item: any) => item.username !== userInfo?.username,
                  )[0].username;
                  opponentUserId = item.members.filter(
                    (item: any) => item.id !== userInfo?.id,
                  )[0].id;
                  opponentUserLoginId = item.members.filter(
                    (item: any) => item.id !== userInfo?.id,
                  )[0].data.loginId;
                }
                return (
                  <div
                    key={idx}
                    className={`${styles.chatListFrame} ${
                      selectChat === item.id ? styles.selectChatFrame : ''
                    }`}
                    onClick={() => {
                      setSelectChat(item.id);
                      navigate(`/chat?chat-group=${item.id}`);
                      // navigate(`/chat?chat-group=${}&chat-number=${item.id}`);
                      handleChannelJoin(item.id, opponentUserLoginId);
                    }}
                  >
                    <img
                      src={opponentUserProfile ? opponentUserProfile : profile}
                      alt="profile"
                    />
                    <div className={styles.rightWrap}>
                      <div className={styles.chatListTitleWrap}>
                        <span>
                          {opponentUserName && opponentUserName}
                          {/* {item.lastMessage && item.lastMessage.channelId} */}
                        </span>
                        {/* <span>{time === '오전' ? timeAm : timePm}</span> */}
                        <span>{listDate}</span>
                      </div>
                      <div className={styles.chatListContentWrap}>
                        <p>{lastText && lastText}</p>
                        {item.unreadCount !== 0 && (
                          <div className={styles.chatCountWrap}>
                            <p>{item.unreadCount}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {selectChatInfo && userType === 'member_manufacture' && (
            <div className={styles.formWrap}>
              <div className={styles.estimateSampleWrap}>
                <div className={styles.formTitle}>
                  <span>견적 양식</span>
                  <span>
                    {estimateDate === '업데이트없음'
                      ? estimateDate
                      : `${estimateDate} 업데이트`}
                  </span>
                </div>
                <div className={styles.formListWrap}>
                  <div className={styles.formListFrame}>
                    <input
                      type="text"
                      placeholder="견적 링크를 입력해 주세요."
                      value={estimateSite}
                      onChange={(e) => formChange(e, '견적')}
                      onKeyDown={(e) => enterForm(e, '견적')}
                      disabled={!selectChatInfo ? true : false}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.sendBtn} ${
                    estimateSite.length ? styles.sendAbleBtn : ''
                  }`}
                  onClick={() => {
                    formSendBtn('견적');
                  }}
                >
                  <span>견적보내기</span>
                </div>
              </div>
              <div className={styles.estimateSampleWrap}>
                <div className={styles.formTitle}>
                  <span>샘플 양식</span>
                  <span>
                    {sampleDate === '업데이트없음'
                      ? sampleDate
                      : `${sampleDate} 업데이트`}{' '}
                  </span>
                </div>
                <div className={styles.formListWrap}>
                  <div className={styles.formListFrame}>
                    <input
                      type="text"
                      placeholder="샘플 링크를 입력해 주세요."
                      value={sampleSite}
                      onChange={(e) => formChange(e, '샘플')}
                      onKeyDown={(e) => enterForm(e, '샘플')}
                      disabled={!selectChatInfo ? true : false}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.sendBtn} ${
                    sampleSite.length ? styles.sendAbleBtn : ''
                  }`}
                  onClick={() => {
                    formSendBtn('샘플');
                  }}
                >
                  <span>샘플보내기</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <>
          {window.location.search && selectChatInfo?.id === channelId ? (
            <div className={styles.chatWrap}>
              <div className={styles.chatTopWrap}>
                {isMobile && (
                  <div className={styles.prevMobileWrap}>
                    <img
                      src={prev}
                      alt="prev"
                      onClick={() => {
                        navigate('/chat');
                      }}
                    />
                  </div>
                )}
                <div className={styles.left}>
                  <div>
                    <p>
                      {selectChatInfo.members &&
                        selectChatInfo.members.map((item: any, idx: any) => {
                          const opponentUserName =
                            item.username !== userInfo?.username
                              ? item.username
                              : '';
                          return <span key={idx}>{opponentUserName}</span>;
                        })}
                    </p>
                    <p>
                      {selectChatInfo.data &&
                        userType === 'member_manufacture' &&
                        selectChatInfo.data.member_client_memo}
                      {selectChatInfo.data &&
                        userType !== 'member_manufacture' &&
                        selectChatInfo.data.member_manufacture_memo}
                    </p>
                  </div>
                </div>
                <div
                  className={styles.right}
                  onClick={() => {
                    handleChatExit(selectChatInfo.id, selectChatInfo);
                    setSelectChat('');
                    navigate('/chat');
                  }}
                >
                  <img src={exit} alt="exit" />
                  <p>방나가기</p>
                </div>
              </div>
              <div className={styles.chatFrameWrap}>
                <div
                  ref={chatRef}
                  className={`${styles.chatCustomScroll} ${
                    mobileFileModalOpen ? styles.mOpenChatCustomScroll : ''
                  }`}
                >
                  {isPrevChat && (
                    <div
                      className={styles.prevWrap}
                      ref={prevRef}
                      onClick={() => {
                        getPrevMessage();
                      }}
                    >
                      이전 메시지 보기
                    </div>
                  )}

                  {chatList &&
                    chatList.map((send: any, idx) => {
                      const format = chatDateFormat(new Date(send.createdAt));
                      const date = format.slice(0, 8);
                      const time = format.slice(9, 11);
                      const timeAm = format.slice(9, 17);
                      const timePm = format.slice(12, 17);
                      return (
                        <div
                          key={idx}
                          style={{
                            marginTop: idx === 0 && !isPrevChat ? '60px' : '',
                          }}
                          className={`${styles.chatFrame} ${
                            send.username === userInfo?.username
                              ? styles.sendChat
                              : styles.receiveChat
                          }`}
                        >
                          <div className={styles.sendDate}>
                            <p className={styles.dateAndRead}>{date && date}</p>
                            {/* <p
                            className={`${styles.dateAndRead} ${
                              send.read === 'N' ? styles.noRead : ''
                            }`}
                          >
                            {read === 'Y' ? date : 1}
                          </p> */}
                            <p>{time === '오전' ? timeAm : timePm}</p>
                          </div>
                          {send.type === 'text' && send.fileUrl === '' && (
                            <div className={styles.sendText}>
                              {send.text &&
                                send.text
                                  .split('\n')
                                  .map((line: any, idx: any) => {
                                    if (line === '') line = '<br />';
                                    return (
                                      <div
                                        className={styles.htmlSendWrap}
                                        key={idx}
                                        dangerouslySetInnerHTML={{
                                          __html: autoLinkify(line),
                                        }}
                                      ></div>
                                    );
                                  })}
                            </div>
                          )}
                          {send.type === 'text' && send.fileUrl !== '' && (
                            // 이미지아닌지 확인
                            <>
                              {send.data.imgType === 'image' ? (
                                <div
                                  className={styles.imgWrap}
                                  onClick={() => {
                                    setFileDetailModalOpen(true);
                                    setSelectFileId(send.id);
                                  }}
                                >
                                  <img
                                    className={styles.sendImg}
                                    src={send.fileUrl}
                                    alt="file"
                                  />
                                </div>
                              ) : send.data.imgType === 'video' ? (
                                <video
                                  width={isDesktop ? '250' : '180'}
                                  height="180"
                                  controls
                                >
                                  <source
                                    src={send.fileUrl}
                                    type="video/mp4"
                                  ></source>
                                </video>
                              ) : (
                                <div
                                  className={styles.fileWrap}
                                  onClick={() => {
                                    handleDownload(
                                      send.fileUrl,
                                      send.data.fileName,
                                    );
                                  }}
                                >
                                  <img src={download} alt="download" />
                                  <p>{send.data.fileName}</p>
                                </div>
                              )}
                            </>
                          )}
                          {send.type === 'custom' && (
                            <>
                              <div className={styles.formWrap}>
                                <p className={styles.sendText}>
                                  파트너 {send.data.custom && send.data.custom}{' '}
                                  요청을 받았어요.
                                </p>
                                <div className={styles.formCompanyWrap}>
                                  <img
                                    src={
                                      send.data.profile
                                        ? send.data.profile
                                        : profile
                                    }
                                    alt="profile"
                                  />
                                  <div className={styles.formCompanyInfo}>
                                    <p>{send.username && send.username}</p>
                                    <p>{send.data.category}</p>
                                  </div>
                                </div>
                                <div className={styles.requestTextWrap}>
                                  <p>
                                    아래 버튼을 클릭하여{' '}
                                    {send.data.custom && send.data.custom}{' '}
                                    요청에 필요한 양식을 작성해 주세요.
                                  </p>
                                </div>
                                <div
                                  className={styles.requestBtnWrap}
                                  onClick={() => window.open(`${send.text}`)}
                                >
                                  <p>
                                    {send.data.custom && send.data.custom}{' '}
                                    요청하기
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* 제조회원 모바일 견적양식 샘플양식 열려있을때  */}
              {mEstiOpen || mSampleOpen ? (
                <div
                  className={`${styles.inputWrap} ${
                    mobileFileModalOpen ? styles.addFileModalWrap : ''
                  }`}
                >
                  <div className={styles.imgAddFrame}>
                    <div
                      className={styles.imgBtn}
                      onClick={() => {
                        setMobileFileModalOpen(true);
                        setMEstiOpen(false);
                        setMSampleOpen(false);
                      }}
                    >
                      <img src={prev} alt="prev" />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.inputWrap} ${
                    mobileFileModalOpen ? styles.addFileModalWrap : ''
                  }`}
                >
                  {/* 제조회원 모바일 닫혀있을때 / 열려있을때 */}
                  <div className={styles.imgAddFrame}>
                    {isMobile && userType === 'member_manufacture' ? (
                      <div
                        className={styles.imgBtn}
                        onClick={() => {
                          setMobileFileModalOpen((prev) => !prev);
                          setMEstiOpen(false);
                          setMSampleOpen(false);
                        }}
                      >
                        <img
                          src={mobileFileModalOpen ? minus : plus}
                          alt="plus"
                        />
                      </div>
                    ) : (
                      <div className={styles.imgAddFrame}>
                        {/* 고객회원 모바일  */}
                        <div
                          className={styles.imgBtn}
                          onClick={() => setFileModalOpen(true)}
                        >
                          <img src={file} alt="file" />
                        </div>
                      </div>
                    )}
                  </div>
                  <textarea
                    placeholder="채팅을 입력하세요."
                    value={sendChat}
                    rows={1}
                    onChange={(e) => setSendChat(e.target.value)}
                    onKeyDown={enterChat}
                    // wrap="physical"
                  ></textarea>
                  <div
                    onClick={onSendBtn}
                    className={`${styles.send} ${sendChat ? styles.hit : ''}`}
                  >
                    <img src={send} alt="send" />
                  </div>
                </div>
              )}
              {mobileFileModalOpen && (
                <div
                  className={`${styles.addFileWrap} ${
                    mobileFileModalOpen ? '' : styles.closeWrap
                  }`}
                >
                  <div onClick={() => setFileModalOpen(true)}>파일첨부</div>
                  <div
                    onClick={() => {
                      setMEstiOpen(true);
                    }}
                  >
                    견적양식
                  </div>
                  <div onClick={() => setMSampleOpen(true)}>샘플양식</div>
                </div>
              )}
              {mEstiOpen && (
                <div className={styles.estimateSampleWrap}>
                  <div className={styles.formTitle}>
                    <span>견적 양식</span>
                    <span>
                      {estimateDate === '업데이트없음'
                        ? estimateDate
                        : `${estimateDate} 업데이트`}
                    </span>
                  </div>
                  <div className={styles.formListWrap}>
                    <div className={styles.formListFrame}>
                      <input
                        type="text"
                        placeholder="견적 링크를 입력해 주세요."
                        value={estimateSite}
                        onChange={(e) => formChange(e, '견적')}
                        onKeyDown={(e) => enterForm(e, '견적')}
                        disabled={!selectChatInfo ? true : false}
                      />
                    </div>
                  </div>
                  <div
                    className={`${styles.sendBtn} ${
                      estimateSite.length ? styles.sendAbleBtn : ''
                    }`}
                    onClick={() => {
                      formSendBtn('견적');
                    }}
                  >
                    <span>견적보내기</span>
                  </div>
                </div>
              )}
              {mSampleOpen && (
                <div className={styles.estimateSampleWrap}>
                  <div className={styles.formTitle}>
                    <span>샘플 양식</span>
                    <span>
                      {sampleDate === '업데이트없음'
                        ? sampleDate
                        : `${sampleDate} 업데이트`}{' '}
                    </span>
                  </div>
                  <div className={styles.formListWrap}>
                    <div className={styles.formListFrame}>
                      <input
                        type="text"
                        placeholder="샘플 링크를 입력해 주세요."
                        value={sampleSite}
                        onChange={(e) => formChange(e, '샘플')}
                        onKeyDown={(e) => enterForm(e, '샘플')}
                        disabled={!selectChatInfo ? true : false}
                      />
                    </div>
                  </div>
                  <div
                    className={`${styles.sendBtn} ${
                      sampleSite.length ? styles.sendAbleBtn : ''
                    }`}
                    onClick={() => {
                      formSendBtn('샘플');
                    }}
                  >
                    <span>샘플보내기</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {isDesktop && (
                <div className={styles.chatWrap}>
                  <div className={styles.chatListNoWrap}>
                    <p>대화할 파트너를 선택해 주세요.</p>
                    <p>
                      원하는 파트너와 함께 프로젝트에 대해 소통할 수 있어요.
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </div>
      {fileModalOpen && (
        <ChatFileModal
          closer={() => {
            setFileModalOpen(false);
          }}
          handleFileSelect={handleFileSelect}
        />
      )}
      {fileDetailModalOpen && (
        <ChatFileDetailModal
          closer={() => {
            setFileDetailModalOpen(false);
          }}
          selectFileId={selectFileId}
          client={client}
          chatList={chatList}
        />
      )}
    </>
  );
}
export default Chat;
