import styles from './MainBanner.module.scss';
import { useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import banner01 from '../../assets/banner/banner01.png';
import mbanner01 from '../../assets/banner/mbanner01.png';
import { useNavigate } from 'react-router-dom';

function MainBanner() {
  const navigate = useNavigate();
  const [mainSwiperIndex, setMainSwiperIndex] = useState<number>(1);
  const [mainBannerList, setMainBannerList] = useState([
    {
      img: banner01,
      mImg: mbanner01,
      site: `/notice-detail/14`,
    },
  ]);

  SwiperCore.use([Autoplay, Navigation]);
  const handleSlideChange = (swiper: SwiperCore) => {
    setMainSwiperIndex(swiper.realIndex + 1);
  };

  return (
    <div className={`${styles.container} ${isDesktop ? '' : styles.isMobile}`}>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        className={`${styles.bannerWrap} ${isDesktop ? '' : styles.isMobile}`}
        onSlideChange={(swiper) => handleSlideChange(swiper)}
        loop={mainBannerList.length > 2 ? true : false}
        autoplay={
          mainBannerList.length > 2
            ? { delay: 3000, disableOnInteraction: false }
            : false
        }
      >
        {mainBannerList &&
          mainBannerList.map((banner, idx) => (
            <SwiperSlide
              key={idx}
              className={styles.bannerSlide}
              onClick={() => navigate(`${banner.site}`)}
            >
              <img src={isDesktop ? banner.img : banner.mImg} alt="banner" />
            </SwiperSlide>
          ))}
        <div className={styles.mainBannerPaginationWrap}>
          {mainSwiperIndex} / {mainBannerList.length}
        </div>
      </Swiper>
    </div>
  );
}

export default MainBanner;
