import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import Navigation from './components/Navigation/Navigation';
import ScrollTop from './components/ScrollTop/ScrollTop';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Join from './pages/Join/Join';
import JoinClient from './pages/JoinClient/JoinClient';
import JoinCompany from './pages/JoinCompany/JoinCompany';
import FindAccount from './pages/FindAccount/FindAccount';
import Notice from './pages/Notice/Notice';
import NoticeDetail from './pages/NoticeDetail/NoticeDetail';
import Partner from './pages/Partner/Partner';
import PartnerDetail from './pages/PartnerDetail/PartnerDetail';
import PartnerPreview from './pages/PartnerPreview/PartnerPreview';
import Chat from './pages/Chat/Chat';
import ScrollUp from './components/ScrollUp/ScrollUp';
import Mypage from './pages/Mypage/Mypage';
import CompanyProfile from './pages/CompanyProfile/CompanyProfile';
import CompanyInfo from './pages/CompanyInfo/CompanyInfo';
import CompanyProduct from './pages/CompanyProduct/CompanyProduct';
import RecentPartner from './pages/RecentPartner/RecentPartner';
import WishPartner from './pages/WishPartner/WishPartner';
import ManagementEstimate from './pages/ManagementEstimate/ManagementEstimate';
import ManagementContract from './pages/ManagementContract/ManagementContract';
import RequestQuote from './pages/RequestQuote/RequestQuote';
import QuoteDetail from './pages/QuoteDetail/QuoteDetail';
import Terms from './pages/Terms/Terms';
import Guide from './pages/Guide/Guide';
import EmailTerms from './pages/EmailTerms/EmailTerms';
import Privacy from './pages/Privacy/Privacy';
import LocationTerms from './pages/LocationTerms/LocationTerms';

import { Document, Page, pdfjs } from 'react-pdf';
import Kakao from './auth/Kakao';
import Naver from './auth/Naver';

// external CDN에서 호출
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function App() {
  useEffect(() => {
    if (isDesktop) document.body.classList.add('pc');
  }, []);
  return (
    <div>
      <BrowserRouter>
        {/* <ScrollUp /> */}
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/auth/kakao/callback" element={<Kakao />} />
          <Route path="/auth/naver/callback" element={<Naver />} />
          <Route path="/join" element={<Join />} />
          <Route path="/join/company" element={<JoinCompany />} />
          <Route path="/join/client" element={<JoinClient />} />
          <Route path="/find-account" element={<FindAccount />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/notice-detail/:noticeId" element={<NoticeDetail />} />
          <Route path="/partner" element={<Partner />} />
          <Route
            path="/partner-detail/:partnerId"
            element={<PartnerDetail />}
          />
          <Route path="/preview/:partnerId" element={<PartnerPreview />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/mypage" element={<Mypage />} />
          <Route path="/mypage/company-profile" element={<CompanyProfile />} />
          <Route path="/mypage/company-info" element={<CompanyInfo />} />
          <Route path="/mypage/company-product" element={<CompanyProduct />} />
          <Route path="/mypage/recent-partner" element={<RecentPartner />} />
          <Route path="/mypage/wish-partner" element={<WishPartner />} />
          <Route path="/mypage/estimate" element={<ManagementEstimate />} />
          <Route path="/mypage/contract" element={<ManagementContract />} />
          <Route path="/request-quote" element={<RequestQuote />} />
          <Route path="/quote/:quoteId" element={<QuoteDetail />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/guide" element={<Guide />} />
          <Route
            path="/unauthorized-email-collection"
            element={<EmailTerms />}
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/location-terms" element={<LocationTerms />} />
        </Routes>
        {!isDesktop && <Navigation />}
      </BrowserRouter>
    </div>
  );
}

export default App;
