import { useNavigate } from 'react-router-dom';
import styles from './Join.module.scss';
import client from '../../assets/join/client.png';
import company from '../../assets/join/company.png';
import direct from '../../assets/join/direct.png';
import { useEffect } from 'react';
import Header from '../../components/Header/Header';
import { isDesktop } from 'react-device-detect';

function Join() {
  const navigate = useNavigate();

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) navigate(-1);
  }, []);

  return (
    <>
      {isDesktop && <Header />}
      <div
        className={`${styles.container} ${isDesktop ? '' : styles.isMobile}`}
      >
        <div className={styles.contentWrap}>
          <div className={styles.logoWrap}>PARTNER</div>
          <div className={styles.joinWrap}>
            <p>회원가입</p>
            <div className={styles.signinWrap}>
              <span>이미 계정이 있으신가요?</span>
              <span onClick={() => navigate('/login')}>로그인</span>
            </div>
            <div className={styles.singupSelectWrap}>
              <div
                className={styles.signupFrame}
                onClick={() => navigate('/join/client')}
              >
                <div className={styles.left}>
                  <div className={styles.top}>
                    <span>
                      제조사를
                      <br />
                      찾으세요?
                    </span>
                  </div>
                  <div className={styles.directWrap}>
                    <span>일반회원으로 가입</span>
                    <img src={direct} alt="direct" />
                  </div>
                </div>
                <img className={styles.client} src={client} alt="client" />
              </div>
              <div
                className={styles.signupFrame}
                onClick={() => navigate('/join/company')}
              >
                <div className={styles.left}>
                  <div className={styles.top}>
                    <span>
                      고객을
                      <br />
                      찾으세요?
                    </span>
                  </div>
                  <div className={styles.directWrap}>
                    <span>제조사로 가입</span>
                    <img src={direct} alt="direct" />
                  </div>
                </div>
                <img className={styles.company} src={company} alt="company" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Join;
