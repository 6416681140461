import { useEffect, useRef, useState } from 'react';
import styles from './ProductEditModal.module.scss';
import close from '../../assets/productEdit/close.png';
import camera from '../../assets/productEdit/camera.png';
import remove from '../../assets/productEdit/remove.png';
import drop from '../../assets/join/drop.png';
import axiosClient from '../../libs/axiosClient';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';

function ProductEditModal(props: any) {
  const navigate = useNavigate();
  const editRef = useRef<HTMLDivElement>(null);
  const [editSizeMethod, setEditSizeMethod] = useState(0);
  const [editMaterialMethod, setEditMaterialMethod] = useState(0);
  const [editSizeDisable, setEditSizeDisable] = useState(false);
  const [editMaterialDisable, setEditMaterailDisable] = useState(false);
  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [productSizeFirst, setProductSizeFirst] = useState('');
  const [productSizeSecond, setProductSizeSecond] = useState('');
  const [productSizeThird, setProductSizeThird] = useState('');
  const [productWeight, setProductWeight] = useState('');
  const [productWeightUnit, setProductWeightUnit] = useState('');
  const [productMaterial, setProductMaterial] = useState('');
  const [initImage, setInitImage] = useState('');
  const [imageSrc, setImageSrc] = useState<string[]>([]);
  const [imageBinary, setImageBinary] = useState<any[]>([]);
  const [emailDirectly, setEmailDirectly] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (!props.productEdit) return;
    setInitImage(props.productEdit.img);
    setProductName(props.productEdit.name);
    setProductCode(props.productEdit.product_code);
    if (props.productEdit.standard_is_custom === 0) {
      setProductSizeFirst(formatNumber(props.productEdit.standard_width));
      setProductSizeSecond(formatNumber(props.productEdit.standard_height));
      setProductSizeThird(formatNumber(props.productEdit.standard_length));
      setEditSizeMethod(0);
    } else setEditSizeMethod(1);
    setProductWeight(formatNumber(props.productEdit.volume));
    setProductWeightUnit(props.productEdit.volume_unit);
    if (props.productEdit.material_is_custom === 0) {
      setProductMaterial(props.productEdit.material);
      setEditMaterialMethod(0);
    } else setEditMaterialMethod(1);
  }, []);

  function encodeFileToBase64(file: any): any {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsDataURL(file);
    });
  }

  const deleteInitImage = () => {
    setInitImage('');
  };

  const deleteUploadImage = (idx: number) => {
    for (let i = 0; i <= idx; i++) {
      if (imageSrc[i] == 'deleted') idx += 1;
      else break;
    }
    let list = [...imageSrc];
    let binaryList = [...imageBinary];
    if (typeof imageBinary[idx] == 'string') {
      list[idx] = 'deleted';
      binaryList[idx] = 'deleted';
    } else {
      list.splice(idx, 1);
      binaryList.splice(idx, 1);
    }
    setImageSrc(list);
    setImageBinary(binaryList);
  };

  useEffect(() => {
    if (editSizeMethod === 1) {
      setProductSizeFirst('');
      setProductSizeSecond('');
      setProductSizeThird('');
      setEditSizeDisable(true);
    } else setEditSizeDisable(false);
  }, [editSizeMethod]);

  useEffect(() => {
    if (editMaterialMethod === 1) {
      setProductMaterial('');
      setEditMaterailDisable(true);
    } else setEditMaterailDisable(false);
  }, [editMaterialMethod]);

  const onSubmit = () => {
    let formData = new FormData();
    let imageList = [...imageBinary];

    if (typeof imageList[0] !== 'object' && !initImage)
      return alert('제품 이미지를 등록해 주세요.');
    if (!productName.length) return alert('제품명을 입력해 주세요.');
    if (!productCode.length) return alert('품목 코드를 입력해 주세요.');
    if (editSizeMethod === 0) {
      if (!productSizeFirst.length) return alert('규격(가로)을 입력해 주세요.');
      if (!productSizeSecond.length)
        return alert('규격(세로)을 입력해 주세요.');
      if (!productSizeThird.length) return alert('규격(높이)을 입력해 주세요.');
    }
    if (!productWeight) return alert('용량을 입력해 주세요.');
    if (!productWeightUnit.length) return alert('용량 단위를 입력해 주세요.');
    if (editMaterialMethod === 0 && !productMaterial.length)
      return alert('소재를 입력해 주세요.');
    if (productMaterial.length) {
      if (productMaterial.length > 40)
        return alert('소재를 40자 이내로 입력해 주세요.');
    }

    formData.append('id', props.productEdit.id);
    formData.append('name', productName);
    formData.append('product_code', productCode);
    if (editSizeMethod === 0) {
      formData.append('standard_width', productSizeFirst);
      formData.append('standard_height', productSizeSecond);
      formData.append('standard_length', productSizeThird);
      formData.append('standard_is_custom', '0');
    } else {
      formData.append('standard_is_custom', '1');
    }
    formData.append('volume', productWeight);
    formData.append('volume_unit', productWeightUnit);
    if (editMaterialMethod === 0) {
      formData.append('material', productMaterial);
      formData.append('material_is_custom', '0');
    } else formData.append('material_is_custom', '1');
    if (imageList[0] && typeof imageList[0] === 'object')
      formData.append('img', imageList[0]);

    axiosClient
      .post(`/member-manufacture-product/edit`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatNumber = (num: string) => {
    const number = Number(num);
    const formattedNumber =
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(1);
    return formattedNumber;
  };

  const handleOutClick = (event: any) => {
    if (!editRef.current) return;
    if (editRef && !editRef.current.contains(event.target)) props.closer(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutClick);
    return () => {
      document.removeEventListener('mousedown', handleOutClick);
    };
  }, [editRef]);

  return (
    <div
      className={`${styles.productEditModal} ${
        isDesktop ? '' : styles.isMobile
      }`}
    >
      <div ref={editRef} className={styles.productEditContent}>
        <img
          className={styles.closeBtnWrap}
          src={close}
          alt="close"
          onClick={() => props.closer(false)}
        />
        <div className={styles.mainContentWrap}>
          <div className={styles.editTitle}>
            <p>상품 수정</p>
          </div>
          <div className={styles.editContentForm}>
            <div className={styles.editContentWrap}>
              <div className={styles.editTopWrap}>
                <div className={styles.left}>
                  {imageSrc &&
                    imageSrc
                      .filter((src) => src !== 'deleted')
                      .map((image, idx) => (
                        <li className={styles.editProductImg} key={image}>
                          <img src={image} alt="productImg" />
                          <div
                            className={styles.remove}
                            onClick={() => deleteUploadImage(idx)}
                          >
                            <img src={remove} alt="remove" />
                          </div>
                        </li>
                      ))}
                  {!imageSrc.length && initImage && (
                    <li className={styles.editProductImg}>
                      <img src={initImage} alt="productImg" />
                      <div
                        className={styles.remove}
                        onClick={() => deleteInitImage()}
                      >
                        <img src={remove} alt="remove" />
                      </div>
                    </li>
                  )}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      let maxSize = 20 * 1024 * 1024;
                      let files: any = e.currentTarget.files;
                      if (!files!.length) return;
                      const file = files[0];
                      if (file.size > maxSize)
                        return alert(
                          '파일첨부 사이즈는 20MB 이내로 가능합니다.',
                        );
                      encodeFileToBase64(file)
                        .then((value: any) => {
                          setImageBinary([file]);
                          setImageSrc([value]);
                          e.target.value = '';
                        })
                        .catch((error: any) => {
                          console.error('Error encoding file:', error);
                        });
                    }}
                  />
                  {!imageSrc.length && !initImage.length && (
                    <div className={styles.camera}>
                      <img src={camera} alt="camera" />
                      <span>사진첨부</span>
                    </div>
                  )}
                </div>
                <div className={styles.right}>
                  <div className={styles.topInputFrame}>
                    <p>제품명</p>
                    <input
                      type="text"
                      value={productName}
                      placeholder="제품명을 입력해 주세요."
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </div>
                  <div className={styles.topInputFrame}>
                    <p>품목코드</p>
                    <input
                      type="text"
                      value={productCode}
                      placeholder="품목코드를 입력해 주세요."
                      onChange={(e) => setProductCode(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.editSizeWrap}>
                <p>규격</p>
                <div className={styles.editSizeList}>
                  <div className={styles.left}>
                    <div className={styles.customRadioButton}>
                      <input
                        type="radio"
                        name="size"
                        checked={editSizeMethod === 0 ? true : false}
                        onChange={() => setEditSizeMethod(0)}
                      />
                    </div>
                    <div className={styles.inputFrame}>
                      <input
                        type="text"
                        placeholder="가로"
                        value={productSizeFirst}
                        onChange={(e) => {
                          let value = e.target.value;
                          const formatValue = formatNumber(value);
                          setProductSizeFirst(formatValue);
                        }}
                        disabled={editSizeDisable}
                      />
                      <label>mm</label>
                    </div>
                    <span>x</span>
                    <div className={styles.inputFrame}>
                      <input
                        type="text"
                        placeholder="세로"
                        value={productSizeSecond}
                        onChange={(e) => setProductSizeSecond(e.target.value)}
                        disabled={editSizeDisable}
                      />
                      <label>mm</label>
                    </div>
                    <span>x</span>
                    <div className={styles.inputFrame}>
                      <input
                        type="text"
                        placeholder="높이"
                        value={productSizeThird}
                        onChange={(e) => setProductSizeThird(e.target.value)}
                        disabled={editSizeDisable}
                      />
                      <label>mm</label>
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.customRadioButton}>
                      <input
                        type="radio"
                        name="size"
                        checked={editSizeMethod === 1 ? true : false}
                        onChange={() => setEditSizeMethod(1)}
                      />
                    </div>
                    <div className={styles.inputFrame}>
                      <input type="text" defaultValue="주문제작" disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.editWeightWrap}>
                <p>용량</p>
                <div className={styles.editWeightList}>
                  <div className={styles.left}>
                    <div className={styles.inputFrame}>
                      <input
                        type="number"
                        value={productWeight}
                        placeholder="용량을 입력해 주세요."
                        onChange={(e) => setProductWeight(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.customSelect}>
                      <select
                        value={productWeightUnit || 'default'}
                        style={{ display: emailDirectly ? 'none' : '' }}
                        onChange={(e) => {
                          let value = e.currentTarget.value;
                          if (value === 'default') value = 'g';
                          setProductWeightUnit(value);
                        }}
                      >
                        <option value="default">g</option>
                        <option value="kg">kg</option>
                        <option value="ml">ml</option>
                        <option value="l">l</option>
                      </select>
                      <img
                        src={drop}
                        alt="selectOpen"
                        style={{
                          display: emailDirectly ? 'none' : '',
                          pointerEvents: 'none',
                        }}
                      />
                      <img
                        src={drop}
                        alt="inputCancel"
                        style={{ display: !emailDirectly ? 'none' : '' }}
                      />
                    </div>
                    {/* <div className={styles.inputFrame}>
                    <input
                      type="text"
                      value={productWeightUnit}
                      onChange={(e) => setProductWeightUnit(e.target.value)}
                    />
                  </div> */}
                  </div>
                </div>
              </div>
              <div className={styles.editMaterialWrap}>
                <p>소재</p>
                <div className={styles.editMaterialList}>
                  <div className={styles.left}>
                    <div className={styles.customRadioButton}>
                      <input
                        type="radio"
                        name="material"
                        checked={editMaterialMethod === 0 ? true : false}
                        onChange={() => setEditMaterialMethod(0)}
                      />
                    </div>
                    <div className={styles.inputFrame}>
                      <input
                        type="text"
                        disabled={editMaterialDisable}
                        value={productMaterial}
                        placeholder="소재를 입력해 주세요."
                        onChange={(e) => setProductMaterial(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.customRadioButton}>
                      <input
                        type="radio"
                        name="material"
                        checked={editMaterialMethod === 1 ? true : false}
                        onChange={() => setEditMaterialMethod(1)}
                      />
                    </div>
                    <div className={styles.inputFrame}>
                      <input type="text" defaultValue="주문제작" disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.btnWrap} onClick={onSubmit}>
              <p>완료</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductEditModal;
