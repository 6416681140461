export function handleRedirectUrlCheck(url) {
  if (
    url !== '/join' &&
    url !== '/join/client' &&
    url !== '/join/company' &&
    url.substring(0, 13) !== '/find-account'
  )
    return true;
  else return false;
}
