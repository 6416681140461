import styles from './RequestQuote.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import calendar from '../../assets/quote/calendar.png';
import next from '../../assets/quote/next.png';
import prev from '../../assets/quote/prev.png';
import cancel from '../../assets/mypage/cancel.png';
import DueCalendar from '../../components/DueCalendar/DueCalendar';
import { CheckboxOption } from '../Partner/Partner';
import axiosClient from '../../libs/axiosClient';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { loginCheck } from '../../auth/auth';

const categoryOption: CheckboxOption[] = [
  { name: '농산물', id: 's1', type: 'check', option: 'service' },
  { name: '축산물', id: 's2', type: 'check', option: 'service' },
  { name: '수산물', id: 's3', type: 'check', option: 'service' },
  { name: '분말', id: 's4', type: 'check', option: 'service' },
  { name: '액상', id: 's5', type: 'check', option: 'service' },
  { name: '가공', id: 's6', type: 'check', option: 'service' },
  { name: '건강기능', id: 's7', type: 'check', option: 'service' },
  { name: '포장', id: 's8', type: 'check', option: 'service' },
  { name: '기타', id: 's9', type: 'check', option: 'service' },
];

function RequestQuote() {
  const navigate = useNavigate();
  const budgetRegex = /^[0-9]*$/;
  const nameRegex = /^[가-힣a-zA-Z ]+$/;
  const [emailDirectly, setEmailDirectly] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(null);
  const [appointmentTime, setAppointmentTime] = useState('');
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarValue, setCalendarValue] = useState();
  const [selectCategoryList, setSelectCategoryList] =
    useState<CheckboxOption>();
  const [projectName, setProjectName] = useState('');
  const [requestMsg, setRequestMsg] = useState('');
  const [budget, setBudget] = useState('');
  const [name, setName] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [phone3, setPhone3] = useState('');
  const [isPhoneCheck, setIsPhoneCheck] = useState(false);

  useEffect(() => {
    if (!loginCheck()) navigate('/login');
    const type = localStorage.getItem('type');
    if (type == null || type == 'null' || type === 'member_manufacture') {
      alert('견적요청은 고객 회원만 이용 가능합니다.');
      return navigate('/');
    }
  }, []);

  const handleCalendarValue = (value: any) => {
    setCalendarValue(value);
  };

  const handleCategoryChange = (option: any) => {
    setSelectCategoryList(option);
  };

  const onSubmit = () => {
    if (!projectName) return alert('프로젝트 이름을 입력해 주세요.');
    if (!selectCategoryList) return alert('카테고리를 선택해 주세요.');
    if (!requestMsg) return alert('요청사항을 입력해 주세요.');
    if (!calendarValue) return alert('희망생산일을 선택해 주세요.');
    if (!budget) return alert('예산을 입력해 주세요.');
    if (budget.length) {
      if (!budgetRegex.test(budget))
        return alert('예산을 숫자만 입력해 주세요.');
    }
    if (!name) return alert('이름을 입력해 주세요.');
    if (name.length) {
      if (!nameRegex.test(name)) return alert('이름 형식이 올바르지 않습니다.');
    }
    if (!phone1) return alert('연락처 첫번째 자리를 입력해 주세요.');
    if (!phone2) return alert('연락처 두번째 자리를 입력해 주세요.');
    if (!phone3) return alert('연락처 세번째 자리를 입력해 주세요.');
    if (!isPhoneCheck) return alert('연락처 확인을 해주세요.');

    const phone = phone1 + '-' + phone2 + '-' + phone3;
    const dueDate = moment(calendarValue).format('YYYY-MM-DD');
    const category = selectCategoryList.id.replace(/\D/g, '');

    let formData = new FormData();
    formData.append('name', projectName);
    formData.append('request', requestMsg);
    formData.append('deadline', dueDate);
    formData.append('budget', budget);
    formData.append('manager_name', name);
    formData.append('manager_hp', phone);
    formData.append('produce_category[]', category);

    axiosClient
      .post(`/estimate/request`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        alert(res.data.msg);
        navigate('/');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <p>견적요청하기</p>
        <div className={styles.requestFromWrap}>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>프로젝트 이름</span>
              <span>*</span>
            </div>
            <input
              type="text"
              placeholder="프로젝트 이름을 입력해 주세요."
              value={projectName || ''}
              onChange={(e) => setProjectName(e.currentTarget.value)}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>카테고리</span>
              <span>*</span>
            </div>
            <div className={styles.categoryWrap}>
              {categoryOption &&
                categoryOption.map((option: any, idx: any) => (
                  <div key={option.name} className={styles.customRadioButton}>
                    <input
                      onChange={() => handleCategoryChange(option)}
                      type="radio"
                      id={`category-${idx}`}
                      name="category"
                    />
                    <label htmlFor={`category-${idx}`}>
                      <span>{option.name}</span>
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>요청사항</span>
              <span>*</span>
            </div>
            <textarea
              placeholder="프로젝트를 이해하는 데 도움되는 요청사항을 적어 주세요."
              value={requestMsg || ''}
              onChange={(e) => setRequestMsg(e.currentTarget.value)}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>희망생산일</span>
              <span>*</span>
            </div>
            <div className={styles.selectWrap}>
              <div
                className={styles.customSelect}
                onClick={() => {
                  setCalendarOpen((prev) => !prev);
                }}
              >
                <span
                  className={`${styles.dueText} ${
                    calendarValue ? styles.hit : ''
                  }`}
                >
                  {calendarValue
                    ? moment(calendarValue).format('YYYY년 MM월')
                    : '희망생산일을 선택해 주세요.'}
                </span>
                <img src={calendar} alt="selectOpen" />
              </div>
              {calendarOpen && (
                <DueCalendar
                  closer={setCalendarOpen}
                  calendarValue={calendarValue}
                  onValue={handleCalendarValue}
                />
                // <Calendar
                //   className={styles.calendar}
                //   minDate={new Date()}
                //   onChange={(value: any, event) => {
                //     setCalendarValue(value);
                //     setCalendarOpen(false);
                //   }}
                //   // prevLabel={<img src={prev} />}
                //   prevLabel=""
                //   // nextLabel={<img src={next} />}
                //   nextLabel=""
                //   maxDetail="year"
                //   defaultView="year"
                //   value={calendarValue}
                //   formatDay={(locale, date) => moment(date).format('D')}
                // />
              )}
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>예산</span>
              <span>*</span>
            </div>
            <div className={styles.inputWrap}>
              <input
                type="number"
                placeholder="예산을 입력해 주세요."
                value={budget || ''}
                onChange={(e) => setBudget(e.currentTarget.value)}
              />
              <label>원</label>
            </div>
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>이름</span>
              <span>*</span>
            </div>
            <input
              type="text"
              placeholder="이름을 입력해 주세요."
              value={name || ''}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </div>
          <div className={styles.inputFrame}>
            <div className={styles.title}>
              <span>견적 받을 연락처</span>
              <span>*</span>
            </div>
            <div className={styles.phoneInputWrap}>
              <input
                type="text"
                maxLength={3}
                placeholder="연락처를 입력해 주세요."
                value={phone1 || ''}
                onChange={(e) => setPhone1(e.currentTarget.value)}
              />
              <span>-</span>
              <input
                type="text"
                maxLength={4}
                value={phone2 || ''}
                onChange={(e) => setPhone2(e.currentTarget.value)}
              />
              <span>-</span>
              <input
                type="text"
                maxLength={4}
                value={phone3 || ''}
                onChange={(e) => setPhone3(e.currentTarget.value)}
              />
            </div>
            <div className={styles.customCheckbox}>
              <input
                type="checkbox"
                id="agree1"
                onClick={(e) => {
                  setIsPhoneCheck(e.currentTarget.checked);
                  // setValue('agreementTerms', !watch().agreementTerms);
                }}
              />
              <label htmlFor="agree1">
                <span>연락처를 한 번 더 확인해 주세요.</span>
              </label>
            </div>
          </div>
          <button className={styles.btnWrap} onClick={onSubmit}>
            신청하기
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default RequestQuote;
