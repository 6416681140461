import styles from './Footer.module.scss';
import { useNavigate } from 'react-router-dom';
import center from '../../assets/footer/center.png';
import { isDesktop, isMobile } from 'react-device-detect';

function Footer() {
  const navigate = useNavigate();
  function handleBusinessInfoOPen() {
    window.open(
      'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=8768602786&apv_perm_no=',
      '',
      'width=750,height=700',
    );
  }
  return (
    <div className={`${styles.container} ${isDesktop ? '' : styles.isMobile}`}>
      <div className={styles.footerWrap}>
        <div className={styles.topWrap}>
          <div className={styles.logoWrap}>
            <p>PARTNER</p>
          </div>
          {isDesktop && (
            <div className={styles.centerWrap}>
              <img src={center} alt="center" />
              <span>고객센터 02-3443-1119</span>
            </div>
          )}
        </div>
        <div className={styles.border}></div>
        <div className={styles.contentWrap}>
          <div className={styles.contentFirstWrap}>
            <div className={styles.left}>
              <span>(주)메디커넥트</span>
              <span>대표 : 노승원대표</span>
              <div className={styles.businessInfoWrap}>
                <span>사업자 등록번호 : 876-86-02786</span>
                <span
                  className={styles.businessInfoOpen}
                  onClick={handleBusinessInfoOPen}
                >
                  사업자 정보확인
                </span>
              </div>
              <span>통신판매업 신고번호 : 제2024-서울강남-02391호</span>
            </div>
            {isDesktop && (
              <div>
                <span>운영시간 : 평일 10시 - 18시</span>
              </div>
            )}
          </div>
          <div className={styles.contentSecondWrap}>
            <div className={styles.left}>
              <span>
                주소 : 서울특별시 강남구 테헤란로70길 12, 402-에이동
                1702호(대치동, H 타워)
              </span>
              <span>개인정보 처리 책임자 : 노승원 (partner@gmail.com)</span>
            </div>
            {isDesktop && (
              <div>
                <span>점심시간 : 12시 - 13시</span>
              </div>
            )}
          </div>
          <div className={styles.contentThirdWrap}>
            <span>Copyright(c) partner.co.kr. All Rights Reserved.</span>
          </div>
        </div>
        {isDesktop ? (
          <div className={styles.navigationWrap}>
            <span onClick={() => navigate('/terms')}>이용약관</span>
            <span onClick={() => navigate('/guide')}>이용안내</span>
            <span onClick={() => navigate('/unauthorized-email-collection')}>
              이메일무단수집거부
            </span>
            <span onClick={() => navigate('/privacy')}>개인정보처리방침</span>
            <span onClick={() => navigate('/location-terms')}>
              위치 기반 서비스 약관
            </span>
          </div>
        ) : (
          <div className={styles.mNavigationWrap}>
            <div className={styles.navigationWrap}>
              <span onClick={() => navigate('/terms')}>이용약관</span>
              <span onClick={() => navigate('/guide')}>이용안내</span>
              <span onClick={() => navigate('/unauthorized-email-collection')}>
                이메일무단수집거부
              </span>
            </div>
            <div className={styles.navigationWrap}>
              <span onClick={() => navigate('/privacy')}>개인정보처리방침</span>
              <span onClick={() => navigate('/location-terms')}>
                위치 기반 서비스 약관
              </span>
            </div>
          </div>
        )}
        {!isDesktop && (
          <div className={styles.mCustomCenterWrap}>
            <div className={styles.centerWrap}>
              <img src={center} alt="center" />
              <span>고객센터 02-3443-1119</span>
            </div>
            <div>
              <span>운영시간 : 평일 10시 - 18시</span>
              <span>점심시간 : 12시 - 13시</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Footer;
