import styles from './MypageSideMenu.module.scss';
import profile from '../../assets/partnerList/profile.png';
import edit from '../../assets/mypage/edit.png';
import side from '../../assets/mypage/sideMypage.png';
import cancel from '../../assets/notice/cancel.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import { loginCheck, logout } from '../../auth/auth';
import axiosClient from '../../libs/axiosClient';
import React from 'react';
import { isDesktop, isEdge, isMobile } from 'react-device-detect';

export interface IMypageType {
  addr: string;
  business_partner: string;
  category_name: string;
  ceo_name: string;
  company_num: string;
  company_num_img: string;
  corporate_num: string;
  email: string;
  fax: string;
  homepage: string;
  hp: string;
  id: string;
  login_id: string;
  img: {
    count: number;
    data: string;
  };
  // img: string;
  introduce: string;
  is_bookmark: number;
  is_cert: number;
  manager_hp: string;
  manager_name: string;
  member_quantity: string;
  memo: string;
  name: string;
  product: {
    count: number;
    data: string;
  };
  since_date: string;
  total_memeber_quantity: string;
  produce_category: string;
  addr_category: string;
  store_category: string;
  introduce1: string;
  introduce2: string;
  introduce3: string;
  company_cert_file: string;
}

function MypageSideMenu() {
  const navigate = useNavigate();
  const [pathname, setPathName] = useState('');
  const [userType, setUserType] = useState('');
  const [sideOpen, setSideOpen] = useState(false);
  const [mypage, setMypage] = useState<IMypageType>();
  const [imageSrc, setImageSrc] = useState<any[]>([]);
  const [imageBinary, setImageBinary] = useState<any[]>([]);
  const [name, setName] = useState<any>('');
  const [clientProfile, setClientProfile] = useState<any>();
  const [companyProfile, setCompanyProfile] = useState<any>();

  useEffect(() => {
    if (!loginCheck()) {
      logout();
      return navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (sideOpen) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [sideOpen]);

  // useLayoutEffect layout과 paint전에 동기적 실행 => 깜빡임 x
  // useEffect는 layout과 paint가 완료된 후 비동기적 실행
  useLayoutEffect(() => {
    setName(localStorage.getItem('profileName'));
    setClientProfile(localStorage.getItem('profileImg'));
    setCompanyProfile(localStorage.getItem('profileImg'));

    if (window.location.pathname) setPathName(window.location.pathname);
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  useEffect(() => {
    if (userType === '') return;
    if (userType === 'member_manufacture') {
      if (name && companyProfile) return;
      getCompanyMypage();
    } else {
      if (name && clientProfile) return;
      getClientMypage();
    }
  }, [userType]);

  const getCompanyMypage = () => {
    axiosClient
      .get('/auth/member-manufacture/mypage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setMypage(res.data);
        setName(res.data.name);
        setCompanyProfile(res.data.profile_img);
        localStorage.setItem('profileName', res.data.name);
        if (res.data.profile_img !== null)
          localStorage.setItem('profileImg', res.data.profile_img);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClientMypage = () => {
    axiosClient
      .get('/auth/member-client/mypage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setMypage(res.data);
        setName(res.data.name);
        setClientProfile(res.data.img);
        localStorage.setItem('profileName', res.data.name);
        if (res.data.img !== null)
          localStorage.setItem('profileImg', res.data.img);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function encodeFileToBase64(file: any): any {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsDataURL(file);
    });
  }

  const handleLogout = () => {
    logout();
    window.location.href = '/';
  };

  return (
    <div
      className={`${styles.sideContainer} ${isDesktop ? '' : styles.isMobile}`}
    >
      {isMobile && (
        <>
          <div className={styles.mSideWrap}>
            <img src={side} alt="side" onClick={() => setSideOpen(true)} />
          </div>
          <div style={{ height: '30px' }}></div>
        </>
      )}
      <div className={styles.sideWrap}>
        <div className={styles.profileWrap}>
          {userType === 'member_manufacture' ? (
            <img
              src={companyProfile ? companyProfile : profile}
              alt="profile"
            />
          ) : (
            <img src={clientProfile ? clientProfile : profile} alt="profile" />
          )}

          {/* <div>
            <img src={edit} alt="edit" />
          </div> */}
          <div className={styles.imgAddFrame}>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => {
                let files = e.currentTarget.files;
                let readers = [];
                if (!files!.length) return;
                let binaryList: File[] = [];
                for (let i = 0; i < 1 - imageBinary.length; i++) {
                  if (!files![i]) break;
                  binaryList.push(files![i]);
                }
                setImageBinary([...imageBinary, ...binaryList]);
                for (let i = 0; i < files!.length; i++) {
                  readers.push(encodeFileToBase64(files![i]));
                }
                Promise.all(readers).then((values) => {
                  let list = [];
                  if (values.length + imageSrc.length > 1) {
                    alert('최대 1장까지만 첨부 가능합니다.');
                  }
                  for (let i = 0; i < 1 - imageSrc.length; i++) {
                    if (!values[i]) break;
                    list.push(values[i]);
                  }
                  setImageSrc([...imageSrc, ...list]);
                  let formData = new FormData();
                  if (binaryList[0] && typeof binaryList[0] === 'object') {
                    formData.append('img', binaryList[0]);
                    let apiUrl = '';
                    if (userType === 'member_manufacture') {
                      apiUrl = '/auth/member-manufacture/mypage/img';
                    } else {
                      apiUrl = '/auth/member-client/mypage/img';
                    }
                    axiosClient
                      .post(`${apiUrl}`, formData, {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            'token',
                          )}`,
                        },
                      })
                      .then((res) => {
                        alert(res.data.msg);
                        setImageBinary([]);
                        setImageSrc([]);
                        if (userType === 'member_manufacture')
                          getCompanyMypage();
                        else getClientMypage();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                });
              }}
            />
            <div className={styles.imgBtn}>
              <img src={edit} alt="edit" />
            </div>
          </div>
        </div>
        <div className={styles.userNameWrap}>
          <span>{name}</span>
          <span> 님</span>
        </div>
        {!isMobile && (
          <div className={styles.logoutWrap} onClick={handleLogout}>
            <span>로그아웃</span>
          </div>
        )}
        <div
          className={`${styles.mMenuCloseWrap} ${
            isMobile && sideOpen ? '' : styles.closeMMenuCloseWrap
          }`}
        >
          <span onClick={() => navigate('/')}>PARTNER</span>
          <img src={cancel} alt="cancel" onClick={() => setSideOpen(false)} />
        </div>
        <div
          className={`${styles.menuWrap} ${
            (isMobile && sideOpen) || isDesktop ? '' : styles.closeMenuWrap
          }`}
        >
          {isMobile && (
            <div className={styles.mProfileWrap}>
              {userType === 'member_manufacture' ? (
                <img
                  src={companyProfile ? companyProfile : profile}
                  alt="profile"
                />
              ) : (
                <img
                  src={clientProfile ? clientProfile : profile}
                  alt="profile"
                />
              )}
              <div className={styles.mUserNameWrap}>
                <div className={styles.mUserName}>
                  <span>{name}</span>
                  <span> 님</span>
                </div>
                <div className={styles.logoutWrap} onClick={handleLogout}>
                  <span>로그아웃</span>
                </div>
              </div>
            </div>
          )}
          <div className={styles.menuFrame}>
            <p className={styles.menuTitle}>내 정보 관리</p>
            <div className={styles.border}></div>
            <div className={styles.submenu}>
              <p
                className={pathname === '/mypage' ? styles.hit : ''}
                onClick={() => navigate('/mypage')}
              >
                계정 정보
              </p>
            </div>
          </div>
          {userType === 'member_manufacture' && (
            <div className={styles.menuFrame}>
              <p className={styles.menuTitle}>내 회사 관리</p>
              <div className={styles.border}></div>
              <div className={styles.submenu}>
                <p
                  className={
                    pathname === '/mypage/company-profile' ? styles.hit : ''
                  }
                  onClick={() => navigate('/mypage/company-profile')}
                >
                  회사 정보
                </p>
                <p
                  className={
                    pathname === '/mypage/company-info' ? styles.hit : ''
                  }
                  onClick={() => navigate('/mypage/company-info')}
                >
                  회사 소개
                </p>
                <p
                  className={
                    pathname === '/mypage/company-product' ? styles.hit : ''
                  }
                  onClick={() => navigate('/mypage/company-product')}
                >
                  생산 제품
                </p>
              </div>
            </div>
          )}
          <div className={styles.menuFrame}>
            <p className={styles.menuTitle}>파트너 설정</p>
            <div className={styles.border}></div>
            <div className={styles.submenu}>
              <p
                className={
                  pathname === '/mypage/recent-partner' ? styles.hit : ''
                }
                onClick={() => navigate('/mypage/recent-partner')}
              >
                최근에 본 파트너
              </p>
              <p
                className={
                  pathname === '/mypage/wish-partner' ? styles.hit : ''
                }
                onClick={() => navigate('/mypage/wish-partner')}
              >
                좋아요 한 파트너
              </p>
            </div>
          </div>
          {/* {userType === 'member_manufacture' && ( */}
          <div className={styles.menuFrame}>
            <p className={styles.menuTitle}>서류 설정</p>
            <div className={styles.border}></div>
            <div className={styles.submenu}>
              <p
                className={pathname === '/mypage/estimate' ? styles.hit : ''}
                onClick={() => navigate('/mypage/estimate')}
              >
                견적서 관리
              </p>
              <p
                className={pathname === '/mypage/contract' ? styles.hit : ''}
                onClick={() => navigate('/mypage/contract')}
              >
                계약서 관리
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MypageSideMenu);
