import styles from './DueCalendar.module.scss';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axiosClient from '../../libs/axiosClient';
import close from '../../assets/quote/close.png';

interface IPropType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  calendarValue: any;
  onValue: any;
}

function DueCalendar({ closer, calendarValue, onValue }: IPropType) {
  const modalRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (!calendarValue) return;
    setValue(calendarValue);
  }, []);

  const onSubmit = () => {
    const newCalendarValue = value;
    onValue(newCalendarValue);
    closer(false);
  };

  const handleOutClick = (event: any) => {
    if (!modalRef.current) return;
    if (modalRef && !modalRef.current.contains(event.target)) closer(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutClick);
    return () => {
      document.removeEventListener('mousedown', handleOutClick);
    };
  }, [modalRef]);

  return (
    <div className={styles.calendarModal}>
      <div ref={modalRef} className={styles.calendarContent}>
        <div
          className={styles.closeWrap}
          onClick={() => {
            closer(false);
          }}
        >
          <img src={close} alt="close" />
        </div>
        <Calendar
          className={styles.calendar}
          minDate={new Date()}
          onChange={(value: any, e) => {
            setValue(value);
          }}
          prevLabel=""
          nextLabel=""
          maxDetail="year"
          defaultView="year"
          value={value}
          formatDay={(locale, date) => moment(date).format('D')}
        />
        <div className={styles.dateWrap}>
          <span>선택한 희망생산일 : </span>
          <span>{value ? moment(value).format('YYYY년 MM월') : ''}</span>
        </div>
        <div className={styles.btnWrap} onClick={onSubmit}>
          선택완료
        </div>
      </div>
    </div>
  );
}
export default DueCalendar;
