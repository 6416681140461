import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MypageSideMenu from '../../components/MypageSideMenu/MypageSideMenu';
import { useEffect, useState } from 'react';
import styles from './CompanyProduct.module.scss';
import productImg from '../../assets/companyProduct/product.png';
import edit from '../../assets/companyProduct/edit.png';
import add from '../../assets/companyProduct/add.png';
import ProductOptionModal from '../../components/ProductOptionModal/ProductOptionModal';
import ProductAddModal from '../../components/ProductAddModal/ProductAddModal';
import unablePrev from '../../assets/partnerList/unablePrev.png';
import ableNext from '../../assets/partnerList/ableNext.png';
import axiosClient from '../../libs/axiosClient';
import { isDesktop } from 'react-device-detect';
import { loginCheck } from '../../auth/auth';
import { useNavigate } from 'react-router-dom';
import cancel from '../../assets/search/cancel.png';

interface IProductType {
  id: number;
  name: string;
  product_code: string;
  standard_width: string;
  standard_length: string;
  standard_height: string;
  standard_is_custom: number;
  volume: string;
  volume_unit: string;
  material: string;
  material_is_custom: number;
  tag: string;
  img: string;
  created_at: string;
  updated_at: string;
}

function CompanyProduct() {
  const navigate = useNavigate();
  const [companyProduct, setCompanyProduct] = useState<IProductType[]>([]);
  const [productAddOpen, setProductAddOpen] = useState(false);
  const [productOptionOpen, setProductOptionOpen] = useState(false);
  const [productCategory, setProductCategory] = useState('');
  const [productName, setProductName] = useState('');
  const [productCategoryList, setProductCategoryList] = useState<any[]>([]);
  const [productNameList, setProductNameList] = useState<any[]>([]);
  const [productData, setProductData] = useState<any>();
  const [isLastPage, setIsLastPage] = useState(false);
  const [productPageList, setProductPageList] = useState([1]);
  const [productPage, setProductPage] = useState(1);
  const [allProductCount, setAllProductCount] = useState(0);

  useEffect(() => {
    if (!loginCheck()) return navigate('/login');
    axiosClient
      .get(`/member-manufacture-products?page=${productPage}&per_page=15`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setCompanyProduct(res.data[0].data.reverse());
        setAllProductCount(res.data[0].count);
      });

    axiosClient
      .get('/auth/member-manufacture/mypage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data.product_category)
          setProductCategoryList(
            res.data.product_category
              .split(',')
              .filter((item: any) => item !== ''),
          );
        if (res.data.product_name)
          setProductNameList(
            res.data.product_name.split(',').filter((item: any) => item !== ''),
          );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt((productPage / 5).toString()) * 5 + 1;
    if (!(productPage % 5)) startPage -= 5;
    let endPage =
      startPage + 4 <
      allProductCount / 15 + (allProductCount % 15 !== 0 ? 1 : 0)
        ? startPage + 4
        : allProductCount / 15 + (allProductCount % 15 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    let lastPage = productPage === Math.ceil(allProductCount / 15);
    if (allProductCount === 0) lastPage = true;
    setIsLastPage(lastPage);
    setProductPageList([...list]);
  }, [allProductCount, productPage]);

  const handlePaginationDown = () => {
    if (productPage == 1) return;
    setProductPage((prev) => prev - 1);
  };
  const handlePaginationUp = () => {
    if (productPage >= allProductCount / 15) return;
    setProductPage((prev) => prev + 1);
  };

  const formatNumber = (num: string) => {
    const number = Number(num);
    const formattedNumber =
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(1);
    return formattedNumber;
  };

  const handleProductCategory = (e: any) => {
    setProductCategory(e.target.value);
  };

  const removeCategory = (value: string, update: any) => {
    axiosClient
      .post(
        '/member-manufacture-product-category/delete',
        {
          product_category: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        setProductCategoryList(update);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addCategory = (keyword: string) => {
    axiosClient
      .post(
        '/member-manufacture-product-category/add',
        {
          product_category: keyword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        setProductCategoryList((prev) => [...prev, keyword]);
      })
      .catch((err) => {
        if (err.response.status === 400) return alert(err.response.data.msg);
      });
  };

  const removeName = (value: string, update: any) => {
    axiosClient
      .post(
        '/member-manufacture-product-name/delete',
        {
          product_name: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        setProductNameList(update);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addName = (keyword: string) => {
    axiosClient
      .post(
        '/member-manufacture-product-name/add',
        {
          product_name: keyword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        setProductNameList((prev) => [...prev, keyword]);
      })
      .catch((err) => {
        if (err.response.status === 400) return alert(err.response.data.msg);
      });
  };

  const keyDownProductCategory = (e: any) => {
    if (productCategory.trim() === '') return setProductCategory('');
    if (e.key === 'Enter' && productCategory.trim() !== '') {
      if (e.nativeEvent.isComposing) return;
      const keyword = productCategory.trim();
      if (!productCategoryList.find((prev) => prev === keyword)) {
        addCategory(keyword);
      }
      setProductCategory('');
    }
  };

  const handleAddProductCategory = () => {
    if (productCategory.trim() === '') return setProductCategory('');
    const keyword = productCategory.trim();
    if (!productCategoryList.find((prev) => prev === keyword)) {
      addCategory(keyword);
    }
    setProductCategory('');
  };

  const handleRemoveCategory = (value: any) => {
    const exist = productCategoryList.findIndex((prev) => prev === value);

    if (exist !== -1) {
      const update = [...productCategoryList];
      update.splice(exist, 1);
      removeCategory(value, update);
    }
  };

  const handleProductName = (e: any) => {
    setProductName(e.target.value);
  };

  const keyDownProductName = (e: any) => {
    if (productName.trim() === '') return setProductName('');
    if (e.key === 'Enter' && productName.trim() !== '') {
      if (e.nativeEvent.isComposing) return;
      const keyword = productName.trim();
      if (!productNameList.find((prev) => prev === keyword)) {
        addName(keyword);
      }
      setProductName('');
    }
  };

  const handleAddProductName = () => {
    if (productName.trim() === '') return setProductName('');
    const keyword = productName.trim();
    if (!productNameList.find((prev) => prev === keyword)) {
      addName(keyword);
    }
    setProductName('');
  };

  const handleRemoveName = (value: any) => {
    const exist = productNameList.findIndex((prev) => prev === value);

    if (exist !== -1) {
      const update = [...productNameList];
      update.splice(exist, 1);
      removeName(value, update);
    }
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <MypageSideMenu />
        <div className={styles.mainContentWrap}>
          <div className={styles.mainTitle}>
            <span>생산 제품</span>
            <span>{allProductCount}개의 제품</span>
          </div>
          <div className={styles.productInfoAddWrap}>
            <div className={styles.title}>
              <span>제품 카테고리</span>
              <span>*</span>
            </div>
            <div className={styles.inputFrame}>
              <input
                type="text"
                placeholder="카테고리를 입력해 주세요."
                value={productCategory}
                onChange={handleProductCategory}
                onKeyDown={keyDownProductCategory}
              />
              <div
                className={styles.addBtnWrap}
                onClick={handleAddProductCategory}
              >
                추가하기
              </div>
            </div>
            <div className={styles.searchWrap}>
              {productCategoryList &&
                productCategoryList.map((item, idx) => (
                  <div key={idx} className={styles.searchFrame}>
                    <span>{item}</span>
                    <img
                      src={cancel}
                      alt="cancel"
                      onClick={() => handleRemoveCategory(item)}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.productInfoAddWrap}>
            <div className={styles.title}>
              <span>제품 이름</span>
              <span>*</span>
            </div>
            <div className={styles.inputFrame}>
              <input
                type="text"
                placeholder="이름을 입력해 주세요."
                value={productName}
                onChange={handleProductName}
                onKeyDown={keyDownProductName}
              />
              <div className={styles.addBtnWrap} onClick={handleAddProductName}>
                추가하기
              </div>
            </div>
            <div className={styles.searchWrap}>
              {productNameList &&
                productNameList.map((item, idx) => (
                  <div key={idx} className={styles.searchFrame}>
                    <span>{item}</span>
                    <img
                      src={cancel}
                      alt="cancel"
                      onClick={() => handleRemoveName(item)}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.companyProductWrap}>
            {companyProduct &&
              companyProduct.map((product, idx) => (
                <div key={product.id} className={styles.productFrame}>
                  <img
                    onClick={() => {
                      setProductOptionOpen(true);
                      setProductData(product);
                    }}
                    className={styles.edit}
                    src={edit}
                    alt="edit"
                  />
                  <div className={styles.productImgWrap}>
                    {product.img && <img src={product.img} alt="product" />}
                  </div>
                  <div className={styles.productContentWrap}>
                    <p>{product.name}</p>
                    <div className={styles.productInfo}>
                      {/* <p>{product.material}</p>
                       */}
                      <p>{product.product_code}</p>
                      <p>
                        {product.standard_is_custom === 0
                          ? `${formatNumber(
                              product.standard_width,
                            )}mm*${formatNumber(product.standard_height)}mm*
                        ${formatNumber(product.standard_length)}mm`
                          : '주문제작'}
                      </p>
                      <p>
                        {formatNumber(product.volume)}
                        {product.volume_unit}
                      </p>
                    </div>
                    {product.material !== '' ? (
                      <div className={styles.tagWrap}>
                        <span>{product.material}</span>
                      </div>
                    ) : (
                      <div className={styles.tagWrap}>
                        <span>주문제작</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {companyProduct.length > 0 && (
            <div className={styles.productPaginationWrap}>
              <img
                style={{ transform: productPage === 1 ? '' : 'rotate(180deg)' }}
                src={productPage === 1 ? unablePrev : ableNext}
                alt="prev"
                onClick={handlePaginationDown}
              />
              <ul>
                {productPageList &&
                  productPageList.map((page) => (
                    <li
                      key={page}
                      onClick={() => {
                        setProductPage(page);
                      }}
                      className={page == productPage ? styles.hit : ''}
                    >
                      <p>{page}</p>
                    </li>
                  ))}
              </ul>
              <img
                style={{ transform: isLastPage ? 'rotate(180deg)' : '' }}
                src={isLastPage ? unablePrev : ableNext}
                alt="next"
                onClick={handlePaginationUp}
              />
            </div>
          )}
          <div className={styles.scrollWrap}>
            <img src={add} alt="add" onClick={() => setProductAddOpen(true)} />
          </div>
        </div>
      </div>
      {productAddOpen && <ProductAddModal closer={setProductAddOpen} />}
      {productOptionOpen && (
        <ProductOptionModal
          closer={setProductOptionOpen}
          productEdit={productData}
        />
      )}
      <Footer />
    </>
  );
}

export default CompanyProduct;
