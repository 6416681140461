import { useEffect, useRef, useState } from 'react';
import styles from './FindAccount.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import drop from '../../assets/join/drop.png';
import axiosClient from '../../libs/axiosClient';
import Header from '../../components/Header/Header';
import { isDesktop } from 'react-device-detect';

interface IIdType {
  login_id: string;
  login_method: string;
}

function FindAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const [emailDirectly, setEmailDirectly] = useState(false);
  const [isId, setIsId] = useState(false);
  const [isPw, setIsPw] = useState(false);
  const [idResult, setIdResult] = useState<IIdType>();
  const [name, setName] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [phone3, setPhone3] = useState('');
  const [id, setId] = useState('');
  const [name2, setName2] = useState('');
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [selectText, setSelectText] = useState('선택해주세요.');

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) navigate(-1);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('tab') === '1') setTab(1);
  }, []);

  const movingBarRef = useRef<HTMLDivElement>(null);
  const tabMenuRef = useRef<HTMLSpanElement>(null);
  const tabMenuRef2 = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (tab !== 0) return;
    if (!tabMenuRef.current || !movingBarRef.current) return;
    movingBarRef.current.style.left = tabMenuRef.current.offsetLeft + 'px';
    movingBarRef.current.style.width = tabMenuRef.current.offsetWidth + 'px';
  }, [tabMenuRef.current, movingBarRef.current]);

  useEffect(() => {
    if (tab !== 1) return;
    if (!tabMenuRef2.current || !movingBarRef.current) return;
    movingBarRef.current.style.left = tabMenuRef2.current.offsetLeft + 'px';
    movingBarRef.current.style.width = tabMenuRef2.current.offsetWidth + 'px';
  }, [tabMenuRef2.current, movingBarRef.current]);

  function handleNavigationBarMove(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) {
    if (!movingBarRef.current) return;
    movingBarRef.current.style.left = e.currentTarget.offsetLeft + 'px';
    movingBarRef.current.style.width = e.currentTarget.offsetWidth + 'px';
  }

  const handleId = () => {
    if (!name) return alert('이름을 입력해 주세요.');
    if (!phone1) return alert('연락처 첫번째 자리를 입력해 주세요.');
    if (!phone2) return alert('연락처 두번째 자리를 입력해 주세요.');
    if (!phone3) return alert('연락처 세번째 자리를 입력해 주세요.');

    const phone = phone1 + '-' + phone2 + '-' + phone3;
    axiosClient
      .get(`/auth/find-id?name=${name}&hp=${phone}`)
      .then((res) => {
        console.log(res.data);
        setIdResult(res.data);
        setIsId(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) alert(err.response.data.msg);
      });
  };

  const handlePw = () => {
    if (!id) return alert('아이디를 입력해 주세요.');
    if (!name2) return alert('이름을 입력해 주세요.');
    if (!email1) return alert('이메일 첫번째 자리를 입력해 주세요.');
    if (!email2) return alert('이메일 두번째 자리를 입력해 주세요.');

    const email = email1 + '@' + email2;
    axiosClient
      .get(`/auth/find-pw?id=${id}&name=${name2}&email=${email}`)
      .then((res) => {
        alert(res.data.msg);
        setIsPw(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) alert(err.response.data.msg);
      });
  };

  return (
    <>
      {isDesktop && <Header />}
      <div
        className={`${styles.container} ${isDesktop ? '' : styles.isMobile}`}
      >
        <div className={styles.contentWrap}>
          <div className={styles.logoWrap}>PARTNER</div>
          <div
            className={`${styles.findForm} ${
              tab === 1 ? styles.findPwForm : ''
            }`}
          >
            <p>아이디 / 비밀번호 찾기</p>
            <div className={styles.tabMenuWrap}>
              <span
                ref={tabMenuRef}
                className={tab == 0 ? styles.hit : ''}
                onClick={(e: any) => {
                  handleNavigationBarMove(e);
                  setTab(0);
                }}
              >
                아이디 찾기
              </span>
              <span
                ref={tabMenuRef2}
                className={tab == 1 ? styles.hit : ''}
                onClick={(e: any) => {
                  handleNavigationBarMove(e);
                  setTab(1);
                }}
              >
                비밀번호 찾기
              </span>
              <div ref={movingBarRef} className={styles.movingBar}></div>
            </div>
            {tab === 0 && (
              <>
                <div className={styles.findInputWrap}>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>이름</span>
                    </div>
                    <input
                      type="text"
                      placeholder="이름을 입력해 주세요."
                      value={name || ''}
                      onChange={(e) => {
                        setName(e.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>연락처</span>
                    </div>
                    <div className={styles.phoneInputWrap}>
                      <input
                        type="text"
                        placeholder="연락처 입력"
                        maxLength={3}
                        value={phone1 || ''}
                        onChange={(e) => {
                          setPhone1(e.currentTarget.value);
                        }}
                      />
                      <span>-</span>
                      <input
                        type="text"
                        value={phone2 || ''}
                        maxLength={4}
                        onChange={(e) => {
                          setPhone2(e.currentTarget.value);
                        }}
                      />
                      <span>-</span>
                      <input
                        type="text"
                        value={phone3 || ''}
                        maxLength={4}
                        onChange={(e) => {
                          setPhone3(e.currentTarget.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.findBtnWrap} onClick={handleId}>
                    확인
                  </div>
                </div>
                {isId && (
                  <div className={styles.resultMsgWrap}>
                    <span>조회된 아이디</span>
                    <br />
                    <span>
                      {idResult?.login_id}({idResult?.login_method})
                    </span>
                  </div>
                )}
              </>
            )}
            {tab === 1 && (
              <>
                <div className={styles.findInputWrap}>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>아이디</span>
                    </div>
                    <input
                      type="text"
                      placeholder="아이디를 입력해 주세요."
                      value={id || ''}
                      onChange={(e) => setId(e.currentTarget.value)}
                    />
                  </div>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>이름</span>
                    </div>
                    <input
                      type="text"
                      placeholder="이름을 입력해 주세요."
                      value={name2 || ''}
                      onChange={(e) => setName2(e.currentTarget.value)}
                    />
                  </div>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>이메일</span>
                    </div>
                    <div className={styles.emailInputWrap}>
                      <div className={styles.left}>
                        <input
                          type="text"
                          placeholder="이메일을 입력해 주세요."
                          value={email1 || ''}
                          onChange={(e) => setEmail1(e.currentTarget.value)}
                        />
                        <span>@</span>
                        <div className={styles.emailSelectWrap}>
                          <div className={styles.customSelect}>
                            <input
                              type="text"
                              placeholder="직접입력"
                              value={email2 || ''}
                              onChange={(e) => {
                                setEmail2(e.currentTarget.value);
                              }}
                            />
                            <select
                              defaultValue="default"
                              name=""
                              id=""
                              onChange={(e) => {
                                setSelectText(e.currentTarget.value);
                                if (e.currentTarget.value == 'directly') {
                                  setEmailDirectly(true);
                                  setEmail2('');
                                } else setEmail2(e.currentTarget.value);
                              }}
                              style={{ display: emailDirectly ? 'none' : '' }}
                              className={`${styles.selectText} ${
                                selectText === '선택해주세요.' ? '' : styles.hit
                              }`}
                            >
                              <option disabled value="default">
                                선택해주세요.
                              </option>
                              <option value="naver.com">naver.com</option>
                              <option value="hanmail.net">hanmail.net</option>
                              <option value="daum.net">daum.net</option>
                              <option value="gmail.com">gmail.com</option>
                              <option value="nate.com">nate.com</option>
                              <option value="hotmail.com">hotmail.com</option>
                              <option value="outlook.com">outlook.com</option>
                              <option value="icloud.com">icloud.com</option>
                              <option value="directly">직접입력</option>
                            </select>
                            <img
                              src={drop}
                              alt="selectOpen"
                              style={{
                                display: emailDirectly ? 'none' : '',
                                pointerEvents: 'none',
                              }}
                            />
                            <img
                              src={drop}
                              alt="inputCancel"
                              style={{ display: !emailDirectly ? 'none' : '' }}
                              onClick={() => {
                                setEmailDirectly(false);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.findBtnWrap} onClick={handlePw}>
                    확인
                  </div>
                </div>
                {isPw && (
                  <div className={styles.resultMsgWrap}>
                    <span>입력하신 이메일 주소로 비밀번호를</span>
                    <br />
                    <span>전송했습니다.</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FindAccount;
