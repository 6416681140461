import { useNavigate } from 'react-router-dom';
import styles from './Navigation.module.scss';
import { useEffect, useState } from 'react';
import { loginConfirmRurl } from '../../common/loginConfirm';
import home from '../../assets/navigation/home.png';
import partner from '../../assets/navigation/partner.png';
import quote from '../../assets/navigation/quote.png';
import notice from '../../assets/navigation/notice.png';
import mypage from '../../assets/navigation/mypage.png';

function Navigation() {
  const navigate = useNavigate();
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  return (
    <div className={styles.container}>
      <ul>
        <li onClick={() => navigate('/')}>
          <img src={home} alt="home" />
          <span>홈</span>
        </li>
        <li
          onClick={() => {
            navigate('/partner');
            window.location.reload();
          }}
        >
          <img src={partner} alt="partner" />
          <span>파트너찾기</span>
        </li>
        {userType !== 'member_manufacture' && (
          <li
            onClick={() => {
              if (!loginConfirmRurl('/request-quote')) return;
              navigate('/request-quote');
            }}
          >
            <img src={quote} alt="quote" />
            <span>견적요청</span>
          </li>
        )}
        <li onClick={() => navigate('/notice')}>
          <img src={notice} alt="notice" />
          <span>공지사항</span>
        </li>
        <li
          onClick={() => {
            if (!loginConfirmRurl('/mypage')) return;
            navigate('/mypage');
          }}
        >
          <img src={mypage} alt="mypage" />
          <span>마이페이지</span>
        </li>
      </ul>
      <div className={styles.safeArea}></div>
    </div>
  );
}

export default Navigation;
