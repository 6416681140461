import styles from './PartnerPreviewInfo.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import heart from '../../assets/partnerList/heart.png';
import heartblack from '../../assets/partnerList/heartblack.png';
import share from '../../assets/partnerList/share.png';
import copy from '../../assets/partnerDetail/copy.png';
import { useEffect, useState } from 'react';
import { ICompanyDetailType } from '../../pages/PartnerDetail/PartnerDetail';
import { loginConfirm } from '../../common/loginConfirm';
import axiosClient from '../../libs/axiosClient';
import { useLocation, useParams } from 'react-router-dom';
import { isDesktop, isMobile } from 'react-device-detect';

function PartnerPreviewInfo(props: any) {
  const [companyDetail, setCompanyDetail] = useState<ICompanyDetailType>();
  const [preview, setPreview] = useState<any>();

  useEffect(() => {
    setCompanyDetail(props.companyDetail);
    setPreview(props.preview);
  }, []);

  return (
    <>
      <div
        className={`${styles.rightWrap} ${isDesktop ? '' : styles.isMobile}`}
      >
        <div className={styles.wishWrap}>
          <img src={heartblack} alt="heart" />
          <span>{companyDetail?.total_bookmark.toLocaleString()}</span>
        </div>
        <div className={styles.titleWrap}>
          <p>
            {preview?.page === '정보' ? preview?.name : companyDetail?.name}
          </p>
          <div className={styles.imgBtns}>
            <img src={share} alt="share" />
            <img
              src={companyDetail?.is_bookmark === 1 ? heart : heartblack}
              alt="heart"
            />
          </div>
        </div>
        <div className={styles.companyInfoWrap}>
          <div>
            <div className={styles.infoTitle}>
              <span>대표자</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보'
                ? preview?.ceo_name
                : companyDetail?.ceo_name}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>설립연도</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보'
                ? preview?.since_date &&
                  preview?.since_date.slice(0, 4) +
                    '년 ' +
                    preview?.since_date.slice(5, 7) +
                    '월'
                : companyDetail?.since_date &&
                  companyDetail?.since_date.slice(0, 4) +
                    '년 ' +
                    companyDetail?.since_date.slice(5, 7) +
                    '월'}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>직원인원</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보'
                ? preview?.member_quantity && preview?.member_quantity + '명'
                : companyDetail?.member_quantity &&
                  companyDetail?.member_quantity + '명'}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>관련업종</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보'
                ? preview?.category_name
                : companyDetail?.category_name}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>주요거래처</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보'
                ? preview?.business_partner
                : companyDetail?.business_partner}
            </span>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>회사주소</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보' ? preview?.addr : companyDetail?.addr}
            </span>
            {(preview?.addr || companyDetail?.addr) && (
              <span>
                {preview?.page === '정보'
                  ? preview?.addr && <img src={copy} alt="copy" />
                  : companyDetail?.addr && <img src={copy} alt="copy" />}
              </span>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>홈페이지</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보'
                ? preview?.homepage
                : companyDetail?.homepage}
            </span>
            {(preview?.homepage || companyDetail?.homepage) && (
              <span>
                {preview?.page === '정보'
                  ? preview?.homepage && <img src={copy} alt="copy" />
                  : companyDetail?.homepage && <img src={copy} alt="copy" />}
              </span>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>대표전화</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보' ? preview?.hp : companyDetail?.hp}
            </span>
            {(preview?.hp || companyDetail?.hp) && (
              <span>
                {preview?.page === '정보'
                  ? preview?.hp && <img src={copy} alt="copy" />
                  : companyDetail?.hp && <img src={copy} alt="copy" />}
              </span>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>팩스</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보' ? preview?.fax : companyDetail?.fax}
            </span>
            {(preview?.fax || companyDetail?.fax) && (
              <span>
                {preview?.page === '정보'
                  ? preview?.fax && <img src={copy} alt="copy" />
                  : companyDetail?.fax && <img src={copy} alt="copy" />}
              </span>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>담당자</span>
            </div>
            <div>
              <p className={styles.infoContent}>
                {preview?.page === '정보'
                  ? preview?.manager_name
                  : companyDetail?.manager_name}
              </p>
              <span className={styles.infoContent}>
                {preview?.page === '정보'
                  ? preview?.manager_hp
                  : companyDetail?.manager_hp}
              </span>
              {(preview?.manager_hp || companyDetail?.manager_hp) && (
                <span>
                  {preview?.page === '정보'
                    ? preview?.manager_hp && <img src={copy} alt="copy" />
                    : companyDetail?.manager_hp && (
                        <img src={copy} alt="copy" />
                      )}
                </span>
              )}
            </div>
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>담당자 이메일</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보'
                ? (preview?.email1 || preview?.email2) &&
                  preview?.email1 + '@' + preview?.email2
                : companyDetail?.email}
            </span>
            {(preview?.email1 || preview?.email2 || companyDetail?.email) && (
              <span>
                {preview?.page === '정보'
                  ? (preview?.email1 || preview?.email2) && (
                      <img src={copy} alt="copy" />
                    )
                  : companyDetail?.email && <img src={copy} alt="copy" />}
              </span>
            )}
          </div>
          <div>
            <div className={styles.infoTitle}>
              <span>메모</span>
            </div>
            <span className={styles.infoContent}>
              {preview?.page === '정보' ? preview?.memo : companyDetail?.memo}
            </span>
          </div>
        </div>
        {/* <div className={styles.companyBtns}>
            <div className={styles.requestBtn}>
              <span>1:1 문의하기</span>
            </div>
          </div> */}
      </div>
    </>
  );
}

export default PartnerPreviewInfo;
