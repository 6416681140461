import { useEffect, useRef, useState } from 'react';
import styles from './ChatFileModal.module.scss';
import axiosClient from '../../libs/axiosClient';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import file1 from '../../assets/chat/file1.png';
import file2 from '../../assets/chat/file2.png';
import image from '../../assets/chat/image.png';
import close from '../../assets/productEdit/close.png';

function ChatFileModal({ closer, handleFileSelect }: any) {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const [imgType, setImgType] = useState('');
  const [fileType, setFileType] = useState('');
  const [imageSrc, setImageSrc] = useState<any[]>([]);
  const [imageBinary, setImageBinary] = useState<any[]>([]);
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  useEffect(() => {
    if (
      imageBinary.length < 1 ||
      imageSrc.length < 1 ||
      fileType === '' ||
      imgType === ''
    )
      return;

    handleFileSelect(imageBinary, imageSrc, fileType, imgType);
    closer(false);
  }, [imageBinary, imageSrc, imgType]);

  const handleChange = (e: any) => {
    let maxSize = 15 * 1024 * 1024;
    let files = e.currentTarget.files;
    const file = files[0];
    if (file.size > maxSize)
      return alert('파일첨부 사이즈는 15MB 이내로 가능합니다.');
    let readers = [];
    if (!files!.length) return;
    let binaryList: File[] = [];
    for (let i = 0; i < 1 - imageBinary.length; i++) {
      if (!files![i]) break;
      binaryList.push(files![i]);
    }
    setImageBinary([...imageBinary, ...binaryList]);
    for (let i = 0; i < files!.length; i++) {
      readers.push(encodeFileToBase64(files![i]));
    }
    Promise.all(readers).then((values) => {
      let list = [];
      console.log(file.type.split('/')[0]);
      setImgType(file.type.split('/')[0]);
      if (values.length + imageSrc.length > 1) {
        alert('최대 1장까지만 첨부 가능합니다.');
      }
      for (let i = 0; i < 1 - imageSrc.length; i++) {
        if (!values[i]) break;
        list.push(values[i]);
      }
      setImageSrc([...imageSrc, ...list]);
    });
  };

  function encodeFileToBase64(file: any): any {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsDataURL(file);
    });
  }

  const handleOutClick = (event: any) => {
    if (!modalRef.current) return;
    if (modalRef && !modalRef.current.contains(event.target)) closer(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutClick);
    return () => {
      document.removeEventListener('mousedown', handleOutClick);
    };
  }, [modalRef]);

  return (
    <div
      className={`${styles.productOptionModal} ${
        isDesktop ? '' : styles.isMobile
      }`}
    >
      <div ref={modalRef} className={styles.productOptionContent}>
        <div className={styles.closeWrap} onClick={() => closer(false)}>
          <img src={close} alt="close" />
        </div>
        {userType === 'member_manufacture' && (
          <>
            <div className={styles.imgAddFrame}>
              <input
                key={imageSrc.length}
                type="file"
                accept=".png, .jpg, .jpeg, .pdf"
                // multiple
                onChange={(e) => {
                  setFileType('견적서');
                  handleChange(e);
                }}
              />
              <div className={styles.imgBtn}>
                <p>
                  <img src={file1} alt="image" />
                  견적서 첨부
                </p>
              </div>
            </div>
            <div className={styles.imgAddFrame}>
              <input
                key={imageSrc.length}
                type="file"
                accept=".png, .jpg, .jpeg, .pdf"
                // multiple
                onChange={(e) => {
                  setFileType('계약서');
                  handleChange(e);
                }}
              />
              <div className={styles.imgBtn}>
                <p>
                  <img src={file2} alt="image" />
                  계약서 첨부
                </p>
              </div>
            </div>
          </>
        )}
        <div className={styles.imgAddFrame}>
          <input
            key={imageSrc.length}
            type="file"
            // accept=".png, .jpg, .jpeg, .pdf"
            // multiple
            onChange={(e) => {
              setFileType('이미지');
              handleChange(e);
            }}
          />
          <div className={styles.imgBtn}>
            <p>
              <img src={image} alt="image" />
              이미지 첨부
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChatFileModal;
