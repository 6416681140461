import styles from './Mypage.module.scss';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../../libs/axiosClient';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MypageSideMenu, {
  IMypageType,
} from '../../components/MypageSideMenu/MypageSideMenu';
import company from '../../assets/partnerDetail/company.png';
import cancel from '../../assets/mypage/cancel.png';
import drop from '../../assets/join/drop.png';
import kakao from '../../assets/login/kakao.png';
import naver from '../../assets/login/naver.png';
import check from '../../assets/mypage/check.png';
import nocheck from '../../assets/mypage/nocheck.png';
import { isDesktop } from 'react-device-detect';
import { loginCheck, logout } from '../../auth/auth';
import { KAKAO_AUTH_URL } from '../../auth/Kakao';
import { NAVER_AUTH_URL } from '../../auth/Naver';

function Mypage() {
  let updateList: string[] = [];
  const location = useLocation();
  let redirectURL = new URLSearchParams(location.search).get('rurl');
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,30}$/;
  const nameRegex = /^[가-힣a-zA-Z ]+$/;
  const emailRegex =
    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState<string[]>([]);
  const [imageBinary, setImageBinary] = useState<any[]>([]);
  const [mypage, setMypage] = useState<IMypageType>();
  const [userType, setUserType] = useState('');
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [pwCheck, setPwCheck] = useState('');
  const [name, setName] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [phone3, setPhone3] = useState('');
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [emailCheck, setEmailCheck] = useState(false);
  const [memo, setMemo] = useState('');
  const [businessNum, setBusinessNum] = useState('');
  const [businessFile, setBusinessFile] = useState<any>('');
  const [emailDirectly, setEmailDirectly] = useState(true);
  const [kakaoLogin, setKakaoLogin] = useState(false);
  const [naverLogin, setNaverLogin] = useState(false);
  const [selectText, setSelectText] = useState('선택해주세요.');

  useEffect(() => {
    if (!loginCheck()) return navigate('/login');
    const type = localStorage.getItem('type');
    if (type == null || type == 'null') return;
    setUserType(type);
  }, []);

  useEffect(() => {
    if (userType === '') return;
    if (userType === 'member_manufacture') getCompanyMypage();
    else getClientMypage();
  }, [userType]);

  useEffect(() => {
    if (mypage === undefined || mypage === null) return;
    if (userType === 'member_manufacture') {
      setId(mypage.login_id);
      setBusinessNum(mypage.company_num);
      if (mypage.company_num_img) setBusinessFile(mypage.company_num_img);
    } else {
      setId(mypage.login_id);
      setName(mypage.name);
      setPhone1(mypage.hp.split('-')[0]);
      setPhone2(mypage.hp.split('-')[1]);
      setPhone3(mypage.hp.split('-')[2]);
      setEmail1(mypage.email.split('@')[0]);
      setEmail2(mypage.email.split('@')[1]);
      setMemo(mypage.memo);
    }
  }, [mypage]);

  const getCompanyMypage = () => {
    axiosClient
      .get('/auth/member-manufacture/mypage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setMypage(res.data);
        setImageBinary([]);
        setImageSrc([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClientMypage = () => {
    axiosClient
      .get('/auth/member-client/mypage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setMypage(res.data);
        let loginMethod = '';
        if (res.data.login_method) loginMethod = res.data.login_method;
        if (loginMethod.includes('naver')) setNaverLogin(true);
        if (loginMethod.includes('kakao')) setKakaoLogin(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function encodeFileToBase64(file: any): any {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsDataURL(file);
    });
  }

  const handleWithdraw = () => {
    let confirm = window.confirm('정말 탈퇴하시겠습니까?');
    if (!confirm) return;
    axiosClient
      .post(`/auth/quit`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        logout();
        alert('회원 탈퇴가 정상 처리되었습니다.');
        navigate('/');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEmailCheck = () => {
    if (!emailRegex.test(email1 + '@' + email2)) {
      alert('올바르지 않은 이메일 형식입니다.');
      return;
    }

    axiosClient
      .post(`/auth/check/email`, {
        email: email1 + '@' + email2,
      })
      .then((res) => {
        if (res.status === 200) {
          setEmailCheck(true);
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) alert(err.response.data.email);
      });
  };

  const onCancel = () => {
    if (!mypage) return;
    setPw('');
    setPwCheck('');
    setName(mypage.name);
    setPhone1(mypage.hp.split('-')[0]);
    setPhone2(mypage.hp.split('-')[1]);
    setPhone3(mypage.hp.split('-')[2]);
    setEmail1(mypage.email.split('@')[0]);
    setEmail2(mypage.email.split('@')[1]);
    setEmailDirectly(true);
    setMemo(mypage.memo);
  };

  const onCancelCompany = () => {
    if (!mypage) return;
    setPw('');
    setPwCheck('');
    setBusinessNum(mypage.company_num);
    if (businessFile || typeof imageBinary[0] === 'object') {
      setBusinessFile(mypage.company_num_img);
      setImageBinary([]);
    }
  };

  const onSubmitCompany = () => {
    let formData = new FormData();
    let imageList = [...imageBinary];

    if (pw.length !== 0 || pwCheck.length !== 0) {
      if (pw !== pwCheck) {
        return alert('비밀번호가 일치하지 않습니다.');
      }
      if (!passwordRegex.test(pw))
        return alert(
          '비밀번호는 영문, 숫자, 특수기호(필수x)를 사용하여 8자 이상 30자 이내만 가능합니다.',
        );
      updateList.push('password');
    }
    if (!businessNum.length) return alert('사업자번호를 입력해 주세요.');
    if (businessNum !== mypage?.company_num) updateList.push('businessNum');
    if (typeof imageList[0] !== 'object' && !businessFile)
      return alert('사업자등록증을 등록해 주세요.');
    if (
      typeof imageList[0] === 'object' &&
      imageList[0] !== mypage?.company_num_img
    )
      updateList.push('businessFile');
    if (businessFile !== mypage?.company_num_img)
      updateList.push('businessFile');
    if (updateList.length === 0) return alert('수정된 정보가 없습니다.');

    if (pw.length) {
      formData.append('login_pw', pw);
      formData.append('login_pw_confirm', pwCheck);
    }
    formData.append('company_num', businessNum);
    if (typeof imageList[0] === 'object')
      formData.append('company_num_img', imageList[0]);
    axiosClient
      .post(`/auth/member-manufacture/mypage/account`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        alert(res.data.msg);
        getCompanyMypage();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = () => {
    if (pw.length !== 0 || pwCheck.length !== 0) {
      if (pw !== pwCheck) {
        return alert('비밀번호가 일치하지 않습니다.');
      }
      if (!passwordRegex.test(pw))
        return alert(
          '비밀번호는 영문, 숫자, 특수기호(필수x)를 사용하여 8자 이상 30자 이내만 가능합니다.',
        );
      updateList.push('password');
    }
    if (!nameRegex.test(name))
      return alert('이름은 한글, 영어, 공백만 사용 가능합니다.');
    if (name !== mypage?.name) updateList.push('name');
    if (!phone1.length || !phone2.length || !phone3.length)
      return alert('연락처를 입력해주세요.');
    if (phone1 + '-' + phone2 + '-' + phone3 !== mypage?.hp)
      updateList.push('phone');
    if (!email1.length || !email2.length)
      return alert('이메일을 입력해주세요.');
    if (mypage?.email !== email1 + '@' + email2) {
      updateList.push('email');
      if (!emailCheck) return alert('이메일 중복확인을 해주세요.');
    }
    if (memo !== mypage?.memo) updateList.push('memo');
    if (updateList.length === 0) return alert('수정된 정보가 없습니다.');

    let formData = new FormData();

    formData.append('login_id', id);
    if (pw.length !== 0) {
      formData.append('login_pw', pw);
      formData.append('login_pw_confirm', pwCheck);
    }
    formData.append('name', name);
    formData.append('memo', memo);
    formData.append('hp', phone1 + '-' + phone2 + '-' + phone3);
    formData.append('email', email1 + '@' + email2);

    axiosClient
      .post(`/auth/member-client/mypage`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        alert(res.data.msg);
        getClientMypage();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function kakaoConfirm() {
    let login = window.confirm('카카오와 연동하시겠습니까?');
    if (login) {
      localStorage.setItem('rurl', redirectURL || '/');
      window.location.href = KAKAO_AUTH_URL;
      return;
    }
  }

  function naverConfirm() {
    let login = window.confirm('네이버와 연동하시겠습니까?');
    if (login) {
      localStorage.setItem('rurl', redirectURL || '/');
      window.location.href = NAVER_AUTH_URL;
      return;
    }
  }

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <MypageSideMenu />
        <div className={styles.mainContentWrap}>
          <p>계정 정보</p>
          <div className={styles.contentWrap}>
            <form>
              <div className={styles.inputFrame}>
                <div className={styles.title}>
                  <span>아이디</span>
                  <span>*</span>
                </div>
                <input
                  type="text"
                  placeholder="영문, 숫자 4자 이상 아이디를 입력해 주세요."
                  defaultValue={id || ''}
                  disabled
                />
              </div>
              <div className={styles.inputFrame}>
                <div className={styles.title}>
                  <span>비밀번호 변경</span>
                </div>
                <input
                  type="password"
                  placeholder="비밀번호를 입력해 주세요."
                  autoComplete="off"
                  value={pw || ''}
                  onChange={(e) => {
                    setPw(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={styles.inputFrame}>
                <div className={styles.title}>
                  <span>비밀번호 변경 확인</span>
                </div>
                <input
                  type="password"
                  placeholder="비밀번호를 재입력해 주세요."
                  autoComplete="off"
                  value={pwCheck || ''}
                  onChange={(e) => setPwCheck(e.currentTarget.value)}
                />
              </div>
              {userType === 'member_manufacture' && (
                <>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>사업자번호</span>
                      <span>*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="사업자번호를 입력해 주세요."
                      disabled
                      value={businessNum || ''}
                      onChange={(e) => {
                        setBusinessNum(e.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>사업자등록증</span>
                      <span>*</span>
                    </div>
                    <div className={styles.businessNumberFileWrap}>
                      {imageBinary
                        .filter((src) => src !== 'deleted')
                        .map((image, idx) => (
                          <input
                            key={idx}
                            type="text"
                            disabled
                            value={image.name || ''}
                            placeholder="파일을 선택해 주세요."
                          />
                        ))}
                      {businessFile && !imageBinary.length && (
                        <a href={businessFile}>
                          {businessFile.split('/').pop()}
                        </a>
                      )}
                      {!businessFile && !imageBinary.length && (
                        <input
                          type="text"
                          disabled
                          placeholder="파일을 선택해 주세요."
                        />
                      )}
                      <div className={styles.fileBtnWrap}>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .pdf"
                          onChange={(e) => {
                            let maxSize = 20 * 1024 * 1024;
                            let files: any = e.currentTarget.files;
                            if (!files!.length) return;
                            const file = files[0];
                            if (file.size > maxSize)
                              return alert(
                                '파일첨부 사이즈는 20MB 이내로 가능합니다.',
                              );
                            encodeFileToBase64(file)
                              .then((value: any) => {
                                setImageBinary([file]);
                                setImageSrc([value]);
                              })
                              .catch((error: any) => {
                                console.error('Error encoding file:', error);
                              });
                          }}
                        />
                        <div className={styles.fileBtn}>
                          <span>파일선택</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {userType === 'member_client' && (
                <>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>이름</span>
                      <span>*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="이름을 입력해 주세요."
                      value={name || ''}
                      onChange={(e) => {
                        setName(e.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>연락처</span>
                      <span>*</span>
                    </div>
                    <div className={styles.phoneInputWrap}>
                      <input
                        type="text"
                        maxLength={3}
                        placeholder="연락처를 입력해 주세요."
                        value={phone1 || ''}
                        onChange={(e) => {
                          setPhone1(e.currentTarget.value);
                        }}
                      />
                      <span>-</span>
                      <input
                        type="text"
                        maxLength={4}
                        value={phone2 || ''}
                        onChange={(e) => {
                          setPhone2(e.currentTarget.value);
                        }}
                      />
                      <span>-</span>
                      <input
                        type="text"
                        maxLength={4}
                        value={phone3 || ''}
                        onChange={(e) => {
                          setPhone3(e.currentTarget.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>이메일</span>
                      <span>*</span>
                    </div>
                    <div className={styles.emailInputWrap}>
                      <div className={styles.left}>
                        <input
                          type="text"
                          placeholder="이메일을 입력해 주세요."
                          value={email1 || ''}
                          onChange={(e) => {
                            setEmail1(e.currentTarget.value);
                            setEmailCheck(false);
                          }}
                        />
                        <span>@</span>
                        <div className={styles.emailSelectWrap}>
                          <div className={styles.customSelect}>
                            <input
                              type="text"
                              placeholder="직접입력"
                              value={email2 || ''}
                              onChange={(e) => {
                                setEmail2(e.currentTarget.value);
                                setEmailCheck(false);
                              }}
                            />
                            <select
                              defaultValue="default"
                              name=""
                              id=""
                              onChange={(e) => {
                                setSelectText(e.currentTarget.value);
                                if (e.currentTarget.value == 'directly') {
                                  setEmailDirectly(true);
                                  setEmail2('');
                                } else setEmail2(e.currentTarget.value);
                                setEmailCheck(false);
                              }}
                              style={{ display: emailDirectly ? 'none' : '' }}
                              className={`${styles.selectText} ${
                                selectText === '선택해주세요.' ? '' : styles.hit
                              }`}
                            >
                              <option disabled value="default">
                                선택해주세요.
                              </option>
                              <option value="naver.com">naver.com</option>
                              <option value="hanmail.net">hanmail.net</option>
                              <option value="daum.net">daum.net</option>
                              <option value="gmail.com">gmail.com</option>
                              <option value="nate.com">nate.com</option>
                              <option value="hotmail.com">hotmail.com</option>
                              <option value="outlook.com">outlook.com</option>
                              <option value="icloud.com">icloud.com</option>
                              <option value="directly">직접입력</option>
                            </select>
                            <img
                              src={drop}
                              alt="selectOpen"
                              style={{
                                display: emailDirectly ? 'none' : '',
                                pointerEvents: 'none',
                              }}
                            />
                            <img
                              src={cancel}
                              alt="cancel"
                              style={{ display: !emailDirectly ? 'none' : '' }}
                              onClick={() => {
                                setEmailDirectly(false);
                                setEmailCheck(false);
                                setEmail2('');
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.duplicateBtnWrap}
                        onClick={handleEmailCheck}
                      >
                        중복확인
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>메모(선택)</span>
                    </div>
                    <input
                      type="text"
                      placeholder="메모를 입력해 주세요."
                      value={memo || ''}
                      onChange={(e) => {
                        setMemo(e.currentTarget.value);
                      }}
                    />
                  </div>

                  <div className={styles.inputFrame}>
                    <div className={styles.title}>
                      <span>SNS 계정 연동</span>
                    </div>
                    <div className={styles.snsConfirmWrap}>
                      <div className={styles.snsLoginBtnWrap}>
                        <div
                          className={styles.kakaoBtnWrap}
                          onClick={() => {
                            if (kakaoLogin) return;
                            else kakaoConfirm();
                          }}
                        >
                          <img src={kakao} alt="kakao" />
                          <span>카카오로 로그인</span>
                        </div>
                        <div className={styles.checkboxWrap}>
                          <img src={kakaoLogin ? check : nocheck} alt="check" />
                          <div>
                            <span>카카오 연동</span>
                            {kakaoLogin && <span>완료</span>}
                          </div>
                        </div>
                      </div>
                      <div className={styles.snsLoginBtnWrap}>
                        <div
                          className={styles.naverBtnWrap}
                          onClick={() => {
                            if (naverLogin) return;
                            else naverConfirm();
                          }}
                        >
                          <img src={naver} alt="naver" />
                          <span>네이버로 로그인</span>
                        </div>
                        <div className={styles.checkboxWrap}>
                          <img src={naverLogin ? check : nocheck} alt="check" />
                          <div>
                            <span>네이버 연동</span>
                            {naverLogin && <span>완료</span>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div
                className={styles.withdrawBtnWrap}
                onClick={() => handleWithdraw()}
              >
                파트너 탈퇴하기
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`${styles.bottomBtnWrap} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <div className={styles.btns}>
          {userType === 'member_manufacture' ? (
            <div className={styles.saveBtn} onClick={onSubmitCompany}>
              저장하기
            </div>
          ) : (
            <div className={styles.saveBtn} onClick={onSubmit}>
              저장하기
            </div>
          )}
          {userType === 'member_manufacture' ? (
            <div className={styles.cancelBtn} onClick={onCancelCompany}>
              취소
            </div>
          ) : (
            <div className={styles.cancelBtn} onClick={onCancel}>
              취소
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Mypage;
