import styles from './SideFilter.module.scss';
import cancel from '../../assets/notice/cancel.png';
import dropdown from '../../assets/sidemenu/dropdown.png';
import {
  CheckboxOption,
  areaOption,
  serviceOption,
  storageOption,
} from '../../pages/Partner/Partner';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SideFilter({
  closer,
  onFilter,
  searchList,
  certList,
  serviceList,
  areaList,
  storageList,
}: any) {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const [isCert, setIsCert] = useState<any>('');
  const [certOpen, setCertOpen] = useState(true);
  const [serviceOpen, setServiceOpen] = useState(true);
  const [areaOpen, setAreaOpen] = useState(true);
  const [storageOpen, setStorageOpen] = useState(true);
  const [partnerPage, setPartnerPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState<any[]>([]);
  const [selectServiceList, setSelectServiceList] = useState<CheckboxOption[]>(
    [],
  );
  const [selectAreaList, setSelectAreaList] = useState<CheckboxOption[]>([]);
  const [selectStorageList, setSelectStorageList] = useState<CheckboxOption[]>(
    [],
  );
  const [mSearchUrl, setMSearchUrl] = useState('');

  useEffect(() => {
    setSearchKeyword(searchList);
    setIsCert(certList);
    setSelectServiceList(serviceList);
    setSelectAreaList(areaList);
    setSelectStorageList(storageList);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleQuery = (params: { [key: string]: string[] }) => {
    let queryString = '';
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        queryString += `&${key}=${params[key].join(',')}`;
      }
    }
    return queryString.slice(1);
  };

  useEffect(() => {
    const params: { [key: string]: string[] } = {};
    let encodeQuery = '';
    searchKeyword.forEach((keyword: { option: string | number; id: any }) => {
      if (!params[keyword.option]) {
        params[keyword.option] = [keyword.id];
      } else {
        params[keyword.option].push(keyword.id);
      }
      const queryString = handleQuery(params);
      encodeQuery = encodeURIComponent(queryString)
        .replaceAll('%20', '+')
        .replaceAll('%3D', '=')
        .replaceAll('%26', '&');
    });
    setMSearchUrl(encodeQuery);
    // navigate(`/partner?${encodeQuery}`);
  }, [searchKeyword]);

  const handleCertChange = (cert: string) => {
    const update = [...searchKeyword];
    const pageIdx = update.findIndex((item) => item.option === 'page');

    if (pageIdx !== -1) {
      update[pageIdx].name = '1';
      update[pageIdx].id = '1';
    }
    if (cert === isCert || (cert === '1' && isCert === '0')) {
      setIsCert(undefined);
      setSearchKeyword((prev) => prev.filter((item) => item.option !== 'cert'));
    } else {
      setIsCert(cert);
      setSearchKeyword((prev) => {
        const updated = prev.map((item) =>
          item.option === 'cert' ? { ...item, name: cert, id: cert } : item,
        );

        const isExist = prev.some((item) => item.option === 'cert');
        if (isExist) {
          return updated;
        } else {
          return [
            ...prev,
            {
              name: cert,
              id: cert,
              type: 'check',
              option: 'cert',
            },
          ];
        }
      });
    }
  };

  const handleCheckboxChange = (option: any) => {
    const update = [...searchKeyword];
    const pageIdx = update.findIndex((item) => item.option === 'page');

    if (pageIdx !== -1) {
      update[pageIdx].name = '1';
      update[pageIdx].id = '1';
    }

    if (option === '제조서비스전체') {
      if (selectServiceList.length === serviceOption.length) {
        setSelectServiceList([]);
        setSearchKeyword(
          searchKeyword.filter((item) => item.option !== 'service'),
        );
      } else {
        setSelectServiceList(serviceOption.map((option) => option));
        setSearchKeyword((prev) => {
          const exit = prev.filter(
            (item) =>
              item.option === 'word' ||
              item.option === 'area' ||
              item.option === 'storage',
          );
          return [...exit, ...serviceOption];
        });
      }
    } else if (option === '지역전체') {
      if (selectAreaList.length === areaOption.length) {
        setSelectAreaList([]);
        setSearchKeyword(
          searchKeyword.filter((item) => item.option !== 'area'),
        );
      } else {
        setSelectAreaList(areaOption.map((option) => option));
        setSearchKeyword((prev) => {
          const exit = prev.filter(
            (item) =>
              item.option === 'word' ||
              item.option === 'service' ||
              item.option === 'storage',
          );
          return [...exit, ...areaOption];
        });
      }
    } else if (option === '보관방식전체') {
      if (selectStorageList.length === storageOption.length) {
        setSelectStorageList([]);
        setSearchKeyword(
          searchKeyword.filter((item) => item.option !== 'storage'),
        );
      } else {
        setSelectStorageList(storageOption.map((option) => option));
        setSearchKeyword((prev) => {
          const exit = prev.filter(
            (item) =>
              item.option === 'word' ||
              item.option === 'service' ||
              item.option === 'area',
          );
          return [...exit, ...storageOption];
        });
      }
    } else {
      if (option.option === 'area') {
        setSelectAreaList((prevKeyword) => {
          const exists = prevKeyword.some((prev) => prev.name === option.name);
          if (exists) {
            setSearchKeyword((prev) =>
              prev.filter((item) => item.name !== option.name),
            );
            return prevKeyword.filter((prev) => prev.name !== option.name);
          } else {
            setSearchKeyword((prev) => [...prev, option]);
            return [...prevKeyword, option];
          }
        });
      } else if (option.option === 'service') {
        setSelectServiceList((prevKeyword) => {
          const exists = prevKeyword.some((prev) => prev.name === option.name);
          if (exists) {
            setSearchKeyword((prev) =>
              prev.filter((item) => item.name !== option.name),
            );
            return prevKeyword.filter((prev) => prev.name !== option.name);
          } else {
            setSearchKeyword((prev) => [...prev, option]);
            return [...prevKeyword, option];
          }
        });
      } else if (option.option === 'storage') {
        setSelectStorageList((prevKeyword) => {
          const exists = prevKeyword.some((prev) => prev.name === option.name);
          if (exists) {
            setSearchKeyword((prev) =>
              prev.filter((item) => item.name !== option.name),
            );
            return prevKeyword.filter((prev) => prev.name !== option.name);
          } else {
            setSearchKeyword((prev) => [...prev, option]);
            return [...prevKeyword, option];
          }
        });
      }
    }
  };

  const handleCancel = () => {
    setSearchKeyword([]);
    setIsCert(undefined);
    setSelectServiceList([]);
    setSelectAreaList([]);
    setSelectStorageList([]);
    closer(false);
  };

  const handleSubmit = () => {
    onFilter(
      mSearchUrl,
      searchKeyword,
      isCert,
      selectServiceList,
      selectAreaList,
      selectStorageList,
    );
    closer(false);
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.mContentWrap}>
        <div className={styles.topWrap}>
          <span>필터 적용</span>
          <img
            className={styles.closeBtnWrap}
            src={cancel}
            alt="cancel"
            onClick={() => closer(false)}
          />
        </div>
        <div className={styles.mainWrap}>
          <div className={styles.sideWrap}>
            <div className={styles.menuWrap}>
              <div
                className={styles.menuTitleWrap}
                onClick={() => {
                  setCertOpen((prev) => !prev);
                }}
              >
                <span>파트너 찾기</span>
                <img
                  src={dropdown}
                  alt="dropdown"
                  style={{
                    transform: certOpen ? 'unset' : 'rotate(180deg)',
                  }}
                />
              </div>
              <div
                className={`${
                  certOpen ? styles.certListWrap : styles.closeWrap
                }`}
              >
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id="파트너찾기전체"
                    checked={isCert === '0'}
                    onChange={() => handleCertChange('0')}
                  />
                  <label htmlFor="파트너찾기전체">
                    <span>모두선택</span>
                  </label>
                </div>
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id={`cert-1`}
                    checked={isCert === '0' || isCert === '1'}
                    onChange={() => handleCertChange('1')}
                  />
                  <label htmlFor="cert-1">
                    <span>빠른문의 파트너</span>
                  </label>
                </div>
              </div>
              <div className={styles.border}></div>
              <div
                className={styles.menuTitleWrap}
                onClick={() => {
                  setServiceOpen((prev) => !prev);
                }}
              >
                <span>제조서비스</span>
                <img
                  src={dropdown}
                  alt="dropdown"
                  style={{
                    transform: serviceOpen ? 'unset' : 'rotate(180deg)',
                  }}
                />
              </div>
              <div
                className={`${
                  serviceOpen ? styles.serviceListWrap : styles.closeWrap
                }`}
              >
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id="제조서비스전체"
                    checked={selectServiceList.length === serviceOption.length}
                    onChange={() => handleCheckboxChange('제조서비스전체')}
                  />
                  <label htmlFor="제조서비스전체">
                    <span>모두선택</span>
                  </label>
                </div>
                {serviceOption &&
                  serviceOption.map((option: any, idx: any) => (
                    <div key={option.name} className={styles.customCheckbox}>
                      <input
                        checked={selectServiceList.some(
                          (opt) => opt.id === option.id,
                        )}
                        onChange={() => handleCheckboxChange(option)}
                        type="checkbox"
                        id={`service-${idx}`}
                      />
                      <label htmlFor={`service-${idx}`}>
                        <span>{option.name}</span>
                      </label>
                    </div>
                  ))}
              </div>
              <div className={styles.border}></div>
              <div
                className={styles.menuTitleWrap}
                onClick={() => {
                  setAreaOpen((prev) => !prev);
                }}
              >
                <span>지역</span>
                <img
                  src={dropdown}
                  alt="dropdown"
                  style={{
                    transform: areaOpen ? 'unset' : 'rotate(180deg)',
                  }}
                />
              </div>
              <div
                className={`${
                  areaOpen ? styles.areaListWrap : styles.closeWrap
                }`}
              >
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id="지역전체"
                    checked={selectAreaList.length === areaOption.length}
                    onChange={() => handleCheckboxChange('지역전체')}
                  />
                  <label htmlFor="지역전체">
                    <span>모두선택</span>
                  </label>
                </div>
                {areaOption &&
                  areaOption.map((option: any, idx: any) => (
                    <div key={option.name} className={styles.customCheckbox}>
                      <input
                        checked={selectAreaList.some(
                          (opt) => opt.id === option.id,
                        )}
                        onChange={() => handleCheckboxChange(option)}
                        type="checkbox"
                        id={`area-${idx}`}
                      />
                      <label htmlFor={`area-${idx}`}>
                        <span>{option.name}</span>
                      </label>
                    </div>
                  ))}
              </div>
              <div className={styles.border}></div>
              <div
                className={styles.menuTitleWrap}
                onClick={() => {
                  setStorageOpen((prev) => !prev);
                }}
              >
                <span>보관방식</span>
                <img
                  src={dropdown}
                  alt="dropdown"
                  style={{
                    transform: storageOpen ? 'unset' : 'rotate(180deg)',
                  }}
                />
              </div>
              <div
                className={`${
                  storageOpen ? styles.storageListWrap : styles.closeWrap
                }`}
              >
                <div className={styles.customCheckbox}>
                  <input
                    type="checkbox"
                    id="보관방식전체"
                    checked={selectStorageList.length === storageOption.length}
                    onChange={() => handleCheckboxChange('보관방식전체')}
                  />
                  <label htmlFor="보관방식전체">
                    <span>모두선택</span>
                  </label>
                </div>
                {storageOption &&
                  storageOption.map((option: any, idx: any) => (
                    <div key={option.name} className={styles.customCheckbox}>
                      <input
                        checked={selectStorageList.some(
                          (opt) => opt.id === option.id,
                        )}
                        // checked={selectAreaList.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                        type="checkbox"
                        id={`storage-${idx}`}
                      />
                      <label htmlFor={`storage-${idx}`}>
                        <span>{option.name}</span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={styles.btnWrap}>
            <div onClick={handleCancel}>취소</div>
            <div onClick={handleSubmit}>적용하기</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideFilter;
