import styles from './ScrollUp.module.scss';
import up from '../../assets/scrollUp/up.png';
import { useEffect, useState } from 'react';

function ScrollUp() {
  const [scrollBtn, setScrollBtn] = useState(false);
  const scrollUp = () => {
    // window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  function onScroll() {
    if (window.scrollY > 100) setScrollBtn(true);
    else setScrollBtn(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <>
      {scrollBtn && (
        <div className={styles.scrollWrap}>
          <img onClick={scrollUp} src={up} alt="up" />
        </div>
      )}
    </>
  );
}

export default ScrollUp;
