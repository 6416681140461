import styles from './Terms.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { isDesktop } from 'react-device-detect';

function Terms() {
  return (
    <>
      <Header />
      <div
        className={`${styles.container} ${isDesktop ? '' : styles.isMobile}`}
      >
        <h3>이용약관</h3> <br />
      </div>
      <Footer />
    </>
  );
}
export default Terms;
