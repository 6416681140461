import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import './index.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; //PWA
import { Provider } from 'react-redux';
import store from './store';

serviceWorkerRegistration.unregister();
if (!window.location.hostname.includes('localhost'))
  Sentry.init({
    dsn: 'https://8ef212b5838cefa1400c5e8536648639@o4504410609287168.ingest.us.sentry.io/4507286388670464',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
);
