import styles from './Privacy.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { isDesktop } from 'react-device-detect';

function Privacy() {
  return (
    <>
      <Header />
      <div
        className={`${styles.container} ${isDesktop ? '' : styles.isMobile}`}
      >
        <h3>개인정보처리방침</h3> <br />
        <h4>제 1조 (수집하는 개인정보의 항목 및 수집방법)</h4>
      </div>
      <Footer />
    </>
  );
}
export default Privacy;
