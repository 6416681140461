import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './NoticeDetail.module.scss';
import prev from '../../assets/notice/prev.png';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { INoticeType } from '../Notice/Notice';
import { isDesktop } from 'react-device-detect';

function NoticeDetail() {
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const [noticeDetail, setNoticeDetail] = useState<INoticeType>();

  useEffect(() => {
    axiosClient.get(`/board/notice/${noticeId}`).then((res) => {
      setNoticeDetail(res.data);
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className={`${styles.contentContainer} ${
          isDesktop ? '' : styles.isMobile
        }`}
      >
        <div className={styles.topWrap}>
          <span>홈{'>'}</span>
          <span>공지사항</span>
        </div>
        <div className={styles.titleWrap}>{noticeDetail?.title}</div>
        <div className={styles.dateWrap}>
          {noticeDetail?.created_at.slice(0, 10).replaceAll('-', '.')}
        </div>
        <div
          className={styles.contentWrap}
          //  dangerouslySetInnerHTML={{
          //   __html: event.b_content.replace(/\\/g, ''),
          // }}
        >
          <img src={noticeDetail?.img.data[0]} alt="detail" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NoticeDetail;
