import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleRedirectUrlCheck } from '../common/redirectUrlCheck';
import axiosClient from '../libs/axiosClient';
import { setToken, STORAGE_ITEMS } from './auth';
const KAKAO_REST_API_KEY = 'b69c33d31aa60a2a6a06aa12a4c95baa';
const KAKAO_REDIRECT_URI = `${window.location.origin}/auth/kakao/callback`;
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&state=loginTest`;

function Kakao() {
  const navigate = useNavigate();
  useEffect(() => {
    let params = new URL(document.URL).searchParams;
    let code = params.get('code');
    let state = params.get('state');

    axiosClient
      .get(
        `/login/kakao?code=${code}&state=${state}&redirect_uri=${KAKAO_REDIRECT_URI}`,
        {
          headers: {
            'Access-Control-Allow-Origin': 'https://admin.shorton.kr/api',
            'ngrok-skip-browser-warning': true,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          withCredentials: true,
        },
      )
      .then((res) => {
        setToken(res.data);
        let rurl = localStorage.getItem('rurl');
        if (handleRedirectUrlCheck(rurl || '/')) navigate(rurl || '/');
        else navigate('/');
      })
      .catch((err) => {
        if (err.response.data.msg === '이미 연동된 계정입니다.') {
          alert(err.response.data.msg);
          navigate('/');
        } else navigate('/login');
      });
  }, []);
  return <div></div>;
}
export default Kakao;
