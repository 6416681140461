import { useEffect, useRef, useState } from 'react';
import styles from './ChatFileDetailModal.module.scss';
import { useLocation } from 'react-router-dom';
import { IChatType } from '../../pages/Chat/Chat';
import close from '../../assets/productEdit/close.png';
import { isDesktop, isMobile } from 'react-device-detect';

function ChatFileDetailModal({ closer, selectFileId, client, chatList }: any) {
  const modalRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [imgList, setImgList] = useState<IChatType[]>([]);

  useEffect(() => {
    if (chatList.length === 0) return;
    const imgInfo = chatList.filter((item: any) => item.id === selectFileId);
    if (imgInfo) setImgList(imgInfo);
  }, []);

  const handleOutClick = (event: any) => {
    if (!modalRef.current) return;
    if (modalRef && !modalRef.current.contains(event.target)) closer(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutClick);
    return () => {
      document.removeEventListener('mousedown', handleOutClick);
    };
  }, [modalRef]);

  return (
    <div className={`${styles.modal} ${isDesktop ? '' : styles.isMobile}`}>
      <div ref={modalRef} className={styles.contentWrap}>
        <div className={styles.closeWrap} onClick={() => closer(false)}>
          <img src={close} alt="close" />
        </div>
        <div className={styles.imgFrame}>
          {imgList &&
            imgList.map((item: any, idx: any) => (
              <div className={styles.imgWrap} key={idx}>
                {item.fileUrl && <img src={item.fileUrl} alt="null" />}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ChatFileDetailModal;
